import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from './Breadcrumbs';
import arrowLeft from '../../assets/icons/arrow-white-left.svg';

const NotFound = () => {
    return (
        <div className="not-found container">
            <Breadcrumbs link="/" />
            <div className="not-found-wrap w-100 d-flex flex-column align-items-center">
                <div className="not-found-404">404</div>
                <div className="not-found-error">Помилка</div>
                <div className="not-found-text">
                    Упс...<br />щось пішло не так
                </div>
                <Link to="/" className="not-found-back d-flex justify-content-center align-items-center">
                    <img className="btn-icon-arrow" src={arrowLeft} alt="" />
                    <span>Повернутись назад</span>
                </Link>
            </div>
        </div>
    )
}

export default NotFound;
