import './styles.scss'

const TermsOfService = () => {
	return (
		<div className="custom-page container">
		<h1>Умови використання</h1>

		<h2>Прийняття умов</h2>
		<p>1.1. Ці Умови надання послуг ("Умови") регулюють використання веб-сайту «eventworkzone.com» для допомоги організаторів івентів, декораторів, орендарів та ін. (далі - "Сайт"). Клієнти, отримавши доступ або використовуючи Сайт, згідно з цими Умовами погоджуються їх дотримуватися.</p>

		<h2>Реєстрація користувача та розміщення оголошень про оренду</h2>
		<p>2.1. Клієнти повинні зареєструватися на Сайті для розміщення речей для оренди.</p>
		<p>2.2. Клієнти несуть відповідальність за достовірність та законність наданої інформації в їхніх оголошеннях про оренду.</p>

		<h2>Фінансова відповідальність</h2>
		<p>3.1. Сайт не несе відповідальності за фінансові транзакції між клієнтами, пов'язані з орендою речей.</p>
		<p>3.2. Єдина оплата на Сайті - це плата за використання його функцій у формі підписки.</p>

		<h2>Поведінка користувача</h2>
		<p>4.1. Користувачі повинні дотримуватися етичних та законних норм під час використання Сайту.</p>
		<p>4.2. Будь-яке зловживання, порушення чи невідповідність цим Умовам може призвести до призупинення або припинення облікового запису користувача.</p>

		<h2>Інтелектуальна власність</h2>
		<p>5.1. Вміст на Сайті, включаючи, але не обмежуючись текст, зображення та логотипи, захищений законами про інтелектуальну власність. Користувачі не можуть використовувати, відтворювати чи розповсюджувати такий вміст без авторизації.</p>

		<h2>Обмеження відповідальності</h2>
		<p>6.1. Сайт не несе відповідальності за будь-які збитки, втрати чи спори, що виникають з транзакцій між клієнтами.</p>

		<h2>Платіж за підписку</h2>
		<p>7.1. Користувачі повинні сплачувати плату за підписку для використання функцій Сайту. Цей платіж є окремим від оплати за оренду предметів користувачами.</p>
		<p>7.2. Користувачі можуть керувати та скасувати свою підписку в будь-який момент через налаштування свого облікового запису.</p>
		</div>
	)
}

export default TermsOfService
