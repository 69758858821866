import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { useLocation } from 'react-router-dom';
import filter from '../../assets/icons/filter.svg';
import arrow from '../../assets/icons/chevron-down.svg';

const CatalogSort = ({ className, onSortOptionSelected }) => {
    const location = useLocation();

    const [sortText, setSortText] = useState('Сортувати за');

    const sortOptions = [
        { menuText: 'Популярністю', fieldText: 'За популярністю', param: 'createdAt', desc: false },
        { menuText: 'Ціною (спадання)', fieldText: 'За ціною (↑)', param: 'rentalPrice', desc: true },
        { menuText: 'Ціною (зростання)', fieldText: 'За ціною (↓)', param: 'rentalPrice', desc: false },
    ];

    const [sortMenuOpen, setSortMenuOpen] = useState(false);
    const sortMenuRef = useRef();

    useEffect(() => {
        setSortMenuOpen(false);
        selectSortOption(null)
    }, [location.pathname]);

    const handleClickOutsideMenu = (event) => {
        if (sortMenuRef.current && !sortMenuRef.current.contains(event.target)) {
            setSortMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideMenu);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideMenu);
        }
    }, []);

    const selectSortOption = (option) => {
        if (option) {
            const { fieldText, param, desc } = option;
            setSortText(fieldText)
            onSortOptionSelected({ param, desc });
        } else {
            setSortText('Сортувати за')
        }
    }

    return (
        <div className={`${className || ''} catalog-sort d-flex justify-content-center align-items-center`}>
            <img src={filter} className="catalog-sort-icon" alt="" />
            <div className="catalog-sort-dropdown d-flex align-items-center relative" onClick={() => setSortMenuOpen(!sortMenuOpen)}>
                <span className="catalog-sort-dropdown-text">{sortText}</span>
                <img src={arrow} className="catalog-sort-dropdown-icon" alt="" />
                {sortMenuOpen && <div ref={sortMenuRef} className="catalog-sort-dropdown-menu d-flex flex-column absolute">
                    {sortOptions.map((option, index) => {
                        return (
                            <div
                                className="catalog-sort-dropdown-menu-item d-flex justify-content-center align-items-center"
                                key={index}
                                onClick={() => selectSortOption(option)}
                            >{option.menuText}</div>
                        )
                    })}
                </div>}
            </div>
        </div>
    )
}

export default CatalogSort;
