import * as yup from 'yup';
import * as C from '../constants/auth';
import schema from './schema';

export const firstnameSchema = schema({
  firstname: { type: 'string', label: C.FIRSTNAME, rules: { required: true } }
})
export const lastnameSchema = schema({
  lastname: { type: 'string', label: C.LASTNAME, rules: { required: true } }
})
export const companySchema = schema({
  company: { type: 'string', label: C.COMPANY, rules: { required: true } }
})
export const locationSchema = schema({
  location: { type: 'string', label: C.LOCATION, rules: { required: true } }
})
export const workingHoursSchema = schema({
  workingHours: { type: 'string', label: C.WORKING_HOURS, rules: { required: true } }
})
export const viberSchema = schema({
  viber: { type: 'string', label: C.VIBER, rules: { required: true } }
})
export const whatsappSchema = schema({
  whatsapp: { type: 'string', label: C.WHATSAPP, rules: { required: true } }
})
export const telegramSchema = schema({
  telegram: { type: 'string', label: C.TELEGRAM, rules: { required: true } }
})
export const instagramSchema = schema({
  instagram: { type: 'string', label: C.INSTAGRAM, rules: { required: true } }
})
