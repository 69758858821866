import {
  GET_EASYPAY_APP_ID_SUCCESS,
  GET_EASYPAY_APP_ID_FAILURE,
  GET_EASYPAY_PAGE_ID_SUCCESS,
  GET_EASYPAY_PAGE_ID_FAILURE,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAILURE,
} from '../actions/payment';

const initialState = {
  easypayAppId: null,
  easypayPageId: null,
  error: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EASYPAY_APP_ID_SUCCESS:
      return {
        ...state,
        easypayAppId: action.payload,
        error: null
      };
    case GET_EASYPAY_APP_ID_FAILURE:
      return {
        ...state,
        easypayAppId: null,
        error: action.payload
      };
    case GET_EASYPAY_PAGE_ID_SUCCESS:
      return {
        ...state,
        easypayPageId: action.payload,
        error: null
      };
    case GET_EASYPAY_PAGE_ID_FAILURE:
      return {
        ...state,
        easypayPageId: null,
        error: action.payload
      };
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        error: null
      };
    case CREATE_PAYMENT_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default paymentReducer;
