import technics from '../assets/images/technics.png';
import decor from '../assets/images/decor.png';

export const mainCategories = [
    { title: 'Оренда техніки', bg: technics, link: 'technics' },
    { title: 'Оренда декору', bg: decor, link: 'decor' },
]

export const catalog = {
	technics: {
		title: 'Оренда техніки',
		categories:	[
			{ title: 'Для монтажу', link: 'dlja-montazhu', bg: 'Для_монтажу.png', cols: 3, mcols: 2 },
			{ title: 'Освітлення', link: 'osvitlennja', bg: 'Освітлення.png', cols: 3, mcols: 2 },
			{ title: 'Звук', link: 'zvuk', bg: 'Звук.png', cols: 3, mcols: 1 },
			{ title: 'Генератори', link: 'generatory', bg: 'Генератори.png', cols: 2, mcols: 2 },
			{ title: 'Електросамокати', link: 'elektrosamokaty', bg: 'Електросамокати.png', cols: 2, mcols: 2 },		
		]
	},
	decor: {
		title: 'Оренда декору',
		categories: [
			{ title: 'Фотозони', link: 'fotozony', bg: 'Фотозони.png', cols: 2, mcols: 2 },
			{ title: 'Застілля', link: 'zastillja', bg: 'Застілля.png', cols: 2, mcols: 2 },
			{ title: 'Лаунж зони', link: 'launzh-zony', bg: 'Лаунж_зони.png', cols: 3, mcols: 1 },
			{ title: 'Флористика', link: 'florystyka', bg: 'Флористика.png', cols: 3, mcols: 2 },
			{ title: 'Кенді бар', link: 'candy-bar', bg: 'Кенді_бар.png', cols: 3, mcols: 2 },
			{ title: 'Вказівники та плани розсадки', link: 'vkazivnyky-ta-plany-rozsadky', bg: 'Вказівники_та_плани_розсадки.png', cols: 1, mcols: 1 },
		]
	}
}

export const categoriesForRegistered = [
	{ slug: 'fotozony', cols: 3 },
	{ slug: 'florystyka', cols: 3 },
	{ slug: 'zastillja', cols: 3 },
	{ slug: 'dlja-montazhu', cols: 3 },
	{ slug: 'osvitlennja', cols: 3 },
	{ slug: 'zvuk', cols: 3 },
	{ slug: 'launzh-zony', cols: 3 },
	{ slug: 'candy-bar', cols: 3 },
	{ slug: 'vkazivnyky-ta-plany-rozsadky', cols: 3 },
	{ slug: 'generatory', cols: 2 },
	{ slug: 'elektrosamokaty', cols: 2 },
]
