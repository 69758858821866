import React, { useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCategoriesForRegistered } from '../../actions/catalog';
import CatalogActions from '../Catalog/CatalogActions';

const CatalogRegistered = ({ categories, getCategoriesForRegistered }) => {
    useEffect(() => {
        getCategoriesForRegistered();
    }, [getCategoriesForRegistered]);

    return (
        <div className="catalog-registered">
            <CatalogActions sort={false} />
            <div className="catalog-registered-wrap">
                <div className="catalog-registered-title w-100 d-flex justify-content-center align-items-center">
                    Категорії товарів
                </div>
                {categories.length && <div className={`catalog-registered-categories container d-flex flex-wrap`}>
                    {categories.map((category, index) => {
                        const { title, link, bg, cols } = category;
                        const image = require(`../../assets/images/${bg}`);
                        const catStyle = { background: `url(${image})` };
                        return (
                            <Link
                                to={link}
                                style={catStyle}
                                className={`catalog-registered-category catalog-part-category-cols-${cols} d-flex justify-content-center align-items-center`}
                                key={index}
                            >
                                {title}
                            </Link>
                        )
                    })}                    
                </div>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
  categories: state.catalog.data,
  loading: state.catalog.loading,
  error: state.catalog.error,
});

export default connect(mapStateToProps, { getCategoriesForRegistered })(CatalogRegistered);
