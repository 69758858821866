import AuthService from '../services/auth.service';
import ProductService from '../services/product.service';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const GOOGLE_AUTH_FAILURE = 'GOOGLE_AUTH_FAILURE';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAILURE = 'VERIFY_USER_FAILURE';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCESS';
export const GET_STORE_FAILURE = 'GET_STORE_FAILURE';
export const CREATE_FAVORITES_LIST_SUCCESS = 'CREATE_FAVORITES_LIST_SUCCESS';
export const CREATE_FAVORITES_LIST_FAILURE = 'CREATE_FAVORITES_LIST_FAILURE';
export const GET_FAVORITES_LISTS_SUCCESS = 'GET_FAVORITES_LISTS_SUCCESS';
export const GET_FAVORITES_LISTS_FAILURE = 'GET_FAVORITES_LISTS_FAILURE';
export const DELETE_FAVORITES_LIST_SUCCESS = 'DELETE_FAVORITES_LIST_SUCCESS';
export const DELETE_FAVORITES_LIST_FAILURE = 'DELETE_FAVORITES_LIST_FAILURE';
export const ADD_FAVORITE_SUCCESS = 'ADD_FAVORITE_SUCCESS';
export const ADD_FAVORITE_FAILURE = 'ADD_FAVORITE_FAILURE';
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';
export const GET_FAVORITES_FAILURE = 'GET_FAVORITES_FAILURE';
export const DELETE_FAVORITE_SUCCESS = 'DELETE_FAVORITE_SUCCESS';
export const DELETE_FAVORITE_FAILURE = 'DELETE_FAVORITE_FAILURE';
export const LOGOUT = 'LOGOUT';

export const registerSuccess = (data) => ({ type: REGISTER_SUCCESS, payload: data });
export const registerFailure = (error) => ({ type: REGISTER_FAILURE, payload: error });
export const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, payload: data });
export const loginFailure = (error) => ({ type: LOGIN_FAILURE, payload: error });
export const googleAuthFailure = (error) => ({ type: GOOGLE_AUTH_FAILURE, payload: error });
export const verifyUserSuccess = () => ({ type: VERIFY_USER_SUCCESS });
export const verifyUserFailure = (error) => ({ type: VERIFY_USER_FAILURE, payload: error });
export const forgotPasswordSuccess = () => ({ type: FORGOT_PASSWORD_SUCCESS });
export const forgotPasswordFailure = (error) => ({ type: FORGOT_PASSWORD_FAILURE, payload: error });
export const getUserRequest = () => ({ type: GET_USER_REQUEST });
export const getUserSuccess = (data) => ({ type: GET_USER_SUCCESS, payload: data });
export const getUserFailure = (error) => ({ type: GET_USER_FAILURE, payload: error });
export const getStoreSuccess = (data) => ({ type: GET_STORE_SUCCESS, payload: data });
export const getStoreFailure = (error) => ({ type: GET_STORE_FAILURE, payload: error });
export const createFavoritesListSuccess = (data) => ({ type: CREATE_FAVORITES_LIST_SUCCESS, payload: data });
export const createFavoritesListFailure = (error) => ({ type: CREATE_FAVORITES_LIST_FAILURE, payload: error });
export const getFavoritesListsSuccess = (data) => ({ type: GET_FAVORITES_LISTS_SUCCESS, payload: data });
export const getFavoritesListsFailure = (error) => ({ type: GET_FAVORITES_LISTS_FAILURE, payload: error });
export const deleteFavoritesListSuccess = () => ({ type: DELETE_FAVORITES_LIST_SUCCESS });
export const deleteFavoritesListFailure = (error) => ({ type: DELETE_FAVORITES_LIST_FAILURE, payload: error });
export const addFavoriteSuccess = () => ({ type: ADD_FAVORITE_SUCCESS });
export const addFavoriteFailure = (error) => ({ type: ADD_FAVORITE_FAILURE, payload: error });
export const getFavoritesSuccess = (data) => ({ type: GET_FAVORITES_SUCCESS, payload: data });
export const getFavoritesFailure = (error) => ({ type: GET_FAVORITES_FAILURE, payload: error });
export const deleteFavoriteSuccess = () => ({ type: DELETE_FAVORITE_SUCCESS });
export const deleteFavoriteFailure = (error) => ({ type: DELETE_FAVORITE_FAILURE, payload: error });
export const logoutSuccess = () => ({ type: LOGOUT });

export const register = (payload) => async (dispatch) => {
  try {
    const result = await AuthService.register(payload);
    if (result.success) {
      // localStorage.setItem('token', result.token);
      dispatch(registerSuccess(result));
      return true;
    }
    else {
      dispatch(registerFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(registerFailure(e.toString()));
    return false;
  }
}

export const login = (payload) => async (dispatch) => {
  try {
    const result = await AuthService.login(payload);
    console.log(result)
    if (result.success) {
      localStorage.setItem('token', result.token);
      dispatch(loginSuccess(result));
    }
    else {
      dispatch(loginFailure(result.message));
    }
  } catch (e) {
    dispatch(loginFailure(e.toString()));
  }
}

export const googleAuth = (payload) => async (dispatch) => {
  try {
    const result = await AuthService.googleAuth(payload);
    console.log(result)
    if (result.success) {
      if (result.token) {
        localStorage.setItem('token', result.token);
        dispatch(loginSuccess(result));
      } else {
        dispatch(registerSuccess(result));
      }
    }
    else {
      dispatch(googleAuthFailure(result.message));
    }
  } catch (e) {
    dispatch(googleAuthFailure(e.toString()));
  }
}

export const verifyUser = (verifyToken) => async (dispatch) => {
  try {
    if (!verifyToken) {
      dispatch(verifyUserFailure(null));
      return;
    }
    const result = await AuthService.verifyUser({ verifyToken });
    console.log(result)
    if (result.success) {
      dispatch(verifyUserSuccess());
    }
    else {
      dispatch(verifyUserFailure(result.message));
    }
  } catch (e) {
    dispatch(verifyUserFailure(e.toString()));
  }
}

export const forgotPassword = (email) => async (dispatch) => {
  try {
    if (!email) {
      dispatch(forgotPasswordFailure(null));
      return;
    }
    const result = await AuthService.forgotPassword({ email });
    console.log(result)
    if (result.success) {
      dispatch(forgotPasswordSuccess());
    }
    else {
      dispatch(forgotPasswordFailure(result.message));
    }
  } catch (e) {
    dispatch(forgotPasswordFailure(e.toString()));
  }
}

export const getUser = () => async (dispatch) => {
  try {
    dispatch(getUserRequest());
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(getUserFailure(null));
      return;
    }
    const result = await AuthService.getUser(token);
    console.log(result)
    if (result.success) {
      dispatch(getUserSuccess(result.user));
    }
    else {
      dispatch(getUserFailure(result.message));
    }
  } catch (e) {
    dispatch(getUserFailure(e.toString()));
  }
}

export const getUserStore = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.getUserStore(token);
    console.log(result)
    if (result.success) {
      dispatch(getStoreSuccess(result.store));
      return result.store;
    }
    else {
      dispatch(getStoreFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(getStoreFailure(e.toString()));
    return false;
  }
}

export const createFavoritesList = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.createFavoritesList(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(createFavoritesListSuccess(result.lists));
      return result.list;
    }
    else {
      dispatch(createFavoritesListFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(createFavoritesListFailure(e.toString()));
    return false;
  }
}

export const getFavoritesLists = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.getFavoritesLists(token);
    console.log(result)
    if (result.success) {
      dispatch(getFavoritesListsSuccess(result.lists));
      return result.lists;
    }
    else {
      dispatch(getFavoritesListsFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(getFavoritesListsFailure(e.toString()));
    return false;
  }
}

export const deleteFavoritesList = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.deleteFavoritesList(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(deleteFavoritesListSuccess());
      return true;
    }
    else {
      dispatch(deleteFavoritesListFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(deleteFavoritesListFailure(e.toString()));
    return false;
  }
}

export const addFavorite = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.addFavorite(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(addFavoriteSuccess());
      return true;
    }
    else {
      dispatch(addFavoriteFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(addFavoriteSuccess(e.toString()));
    return false;
  }
}

export const getFavorites = (payload = {}) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.getFavorites(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(getFavoritesSuccess(result.favorites));
      return result.favorites;
    }
    else {
      dispatch(getFavoritesFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(getFavoritesFailure(e.toString()));
    return false;
  }
}

export const searchFavorites = (payload = {}) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.searchFavorites(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(getFavoritesSuccess(result.favorites));
      return result.favorites;
    }
    else {
      dispatch(getFavoritesFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(getFavoritesFailure(e.toString()));
    return false;
  }
}

export const deleteFavorite = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.deleteFavorite(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(deleteFavoriteSuccess());
      return true;
    }
    else {
      dispatch(deleteFavoriteFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(deleteFavoriteSuccess(e.toString()));
    return false;
  }
}

export const logout = () => async (dispatch) => {
  localStorage.removeItem('token');
  dispatch(logoutSuccess());
}
