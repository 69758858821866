import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoritesLists } from '../../actions/auth';
import Popup from './Popup';
import CreateFavoritesList from './CreateFavoritesList';
import plusWhite from '../../assets/icons/plus-white.svg';

const AddFavorite = ({ isOpen, onConfirm, onCancel }) => {
    const dispatch = useDispatch();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const favoritesLists = useSelector((state) => state.auth.favoritesLists);

    useEffect(() => {
        const fetchData = async () => {
	    	if (isAuthenticated) {
	    		await dispatch(getFavoritesLists())
	    	} else if (!userLoading) {
	    		// navigate('/');
	    	}
        }

        fetchData();
    }, [userLoading, isAuthenticated, authUser]);

    const [createPopupOpen, setCreatePopupOpen] = useState(false);

    const handleCreateClick = () => {
        setCreatePopupOpen(true);
    }

    const handleConfirmCreate = (email) => {
        setCreatePopupOpen(false);
    }

    const handleCancelCreate = () => {
        setCreatePopupOpen(false);
    }

    const onListSelected = (id) => {
    	onConfirm(id)
    }

    const popupList = (
    	<div className="add-favorite-lists d-flex flex-column align-items-center">
    		{favoritesLists.map((list, index) => {
    			return (
    				<div
    					className="add-favorite-lists-item w-100 d-flex justify-content-center align-items-center"
    					onClick={() => onListSelected(list.id)}
    				>
    					{list.title}
    				</div>
    			)
    		})}
    	</div>
    );

    return (
    	<>
	        <Popup
	            className="add-favorite"
	            isOpen={isOpen}
	            onConfirm={handleCreateClick}
	            onCancel={onCancel}
	            popupTitle="Оберіть у який список зберегти цей товар:"
	            popupList={popupList}
	            buttonClass="btn-small"
	            buttonIcon={plusWhite}
	            buttonText="Створити список"
	        />
	        <CreateFavoritesList
	            isOpen={createPopupOpen}
	            onConfirm={handleConfirmCreate}
	            onCancel={handleCancelCreate}
	        />
        </>
    )
}

export default AddFavorite;
