import * as yup from 'yup';
import * as C from '../constants/auth';

const loginSchema = yup.object().shape({
  phone: yup.string().nullable()
    .test('phone', C.ERROR_FIELD_INCORRECT.replace(':field', C.PHONE), (value, { parent }) => {
      if (parent.email || !value) {
        return true;
      }
      const unfilledChars = value.split('_').length - 1;
      return unfilledChars === 0;
    }),
  email: yup.string().nullable()
    .email(C.ERROR_FIELD_INCORRECT.replace(':field', C.EMAIL)),
  password: yup.string()
    .required(C.ERROR_FIELD_REQUIRED.replace(':field', C.PASSWORD)),
})
.test('phoneOrEmail', C.ERROR_PHONE_OR_EMAIL_REQUIRED, function({ phone, email }) {
  return phone || email;
});

export default loginSchema;
