import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateSubscription, deleteSubscription } from '../../actions/subscription';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';
import checkboxActive from '../../assets/icons/checkbox-active.svg';
import checkboxInactive from '../../assets/icons/checkbox-inactive.svg';

const SubscriptionsTable = ({ subscriptions, periods, onDelete }) => {
	const dispatch = useDispatch();

	const [data, setData] = useState([]);

	const params = [
		{ name: 'plan', label: 'План підписки' },
		{ name: 'period', label: 'Період' },
		{ name: 'status', label: 'Статус' },
		{ name: 'paidDate', label: 'Дата оплати' },
		{ name: 'expiredDate', label: 'Дата закінчення строку дії' },
		{ name: 'autorenew', label: 'Автоподовження' },
		{ name: 'actions', label: '' },
	];

	const statuses = {
		new: 'Нова',
		active: 'Активна',
		finished: 'Завершена',
	};

	useEffect(() => {
		if (subscriptions && subscriptions.length) {
			setData(subscriptions.map(
				subscription => {					
            		const { id, plan, pricing, status, paidDate, expiredDate, autorenew } = subscription;
            		const period = periods.find(item => item.value == pricing.period);
					return {
						id,
						plan: plan.name,
						period: period.label,
						status,
						statusName: statuses[status],
						paidDate,
						expiredDate,
						autorenew,
					}
				}
			))
		}
	}, [subscriptions])

	const changeAutorenew = (e, id) => {
		const autorenew = e.target.checked;
		const updatedData = data.map(subscription => 
	        subscription.id === id 
	        ? { ...subscription, autorenew } 
	        : subscription
	    );
	    setData(updatedData);
	    dispatch(updateSubscription({ id, autorenew }));
	}

	const cancelSubscription = async (id) => {
		await dispatch(deleteSubscription({ id }));
		onDelete();
	}

	return (
		<Table className="subscriptions-table w-100">
	        <TableHead>
	            <TableRow className="subscriptions-table-row">
	            	{params.map((param, pi) => {
	            		const { name, label } = param;
	            		return (
	            			<TableCell
	            				className={`subscriptions-table-cell subscriptions-table-cell-${name}`}
	            				key={pi}
	            			>{label}</TableCell>
	            		)
	            	})}
	            </TableRow>
	        </TableHead>
            <TableBody>
            	{data.map((subscription, si) => {
            		const { id, plan, period, status, statusName, paidDate, expiredDate, autorenew } = subscription;
		            return (
		            	<TableRow key={si}>
                            <TableCell className="subscriptions-table-cell">{plan}</TableCell>
                            <TableCell className="subscriptions-table-cell">{period}</TableCell>
                            <TableCell className="subscriptions-table-cell">{statusName}</TableCell>
                            <TableCell className="subscriptions-table-cell">
                            	{paidDate ? new Intl.DateTimeFormat('uk-UA', {
				                    day: "numeric",
				                    month: "numeric",
				                    year: "numeric",
				                    hour: "numeric",
				                    minute: "numeric",
				                    timeZone: 'Europe/Kyiv',
				                }).format(new Date(paidDate)) : ''}
                           	</TableCell>
                            <TableCell className="subscriptions-table-cell">
                            	{expiredDate ? new Intl.DateTimeFormat('uk-UA', {
				                    day: "numeric",
				                    month: "numeric",
				                    year: "numeric",
				                    hour: "numeric",
				                    minute: "numeric",
				                    timeZone: 'Europe/Kyiv',
				                }).format(new Date(expiredDate)) : ''}
                           	</TableCell>
                            <TableCell className="subscriptions-table-cell">
                            	<label htmlFor={`autorenew-subscription-${id}`}>
	                            	<input
	                            		id={`autorenew-subscription-${id}`}
	                                    style={{ display: 'none' }}
	                                    type="checkbox"
	                                    checked={autorenew}
	                                    onChange={(e) => changeAutorenew(e, id)}
	                                />
	                                <img src={autorenew ? checkboxActive : checkboxInactive} alt="" />
	                            </label>
                            </TableCell>
                            <TableCell className="subscriptions-table-cell">
                            	{status === 'new' && <button
                            		className="btn-small btn-black w-100"
                            		onClick={() => cancelSubscription(id)}
                            	>
			                        <span>Скасувати</span>
			                    </button>}
                            </TableCell>
			            </TableRow>
			        )
            	})}
	        </TableBody>
        </Table>
	)
}

export default SubscriptionsTable;
