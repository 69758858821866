export const TITLE = 'Назва';
export const BRAND = 'Компанія';
export const RENTAL_PRICE = 'Ціна оренди';
export const RESTORE_PRICE = 'Ціна для відновлення';
export const REFUND_PRICE = 'Ціна для відшкодування';
export const COST = 'Собівартість';
export const CATEGORY = 'Категорія';
export const TOTAL_AMOUNT = 'Загальна кількість на складі';
export const FREE_AMOUNT = 'Вільно на дату проекту';
export const COLOR = 'Колір';
export const LENGTH = 'Довжина';
export const WIDTH = 'Ширина';
export const HEIGHT = 'Висота';
export const WEIGHT = 'Вага';
export const PACKAGING = 'Упакування';
export const MIN_PERSONS = 'Кількість персоналу для монтажу (мін)';

export const defaultProduct = {
    id: null,
    title: 'Ліхтар “класний”',
    brand: '“Україна”',
    rentalPrice: 1000,
    totalAmount: 178
}
