import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { register, googleAuth } from '../../actions/auth';
import registerSchema from '../../validations/register';
import { PHONE_MASK } from '../../constants/auth';
import InputMask from 'react-input-mask';
import Breadcrumbs from '../Common/Breadcrumbs';
import GoogleLoginWrap from './GoogleLoginWrap';
import ConfirmRegister from '../Popup/ConfirmRegister';
import radio_active from '../../assets/icons/radio-active.svg';
import radio_inactive from '../../assets/icons/radio-inactive.svg';
import chevronDown from '../../assets/icons/chevron-down.svg';

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const authError = useSelector((state) => state.auth.error);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        gender: 'n',
        phone: '',
        email: '',
        password: '',
        password_confirmation: '',
        accept: false,
    });

    const [confirmPopupOpen, setConfirmPopupOpen] = useState(false);

    const [errors, setErrors] = useState({
        firstname: false,
        lastname: false,
        phone: false,
        email: false,
        password: false,
        accept: false,
    });

    const [validationError, setValidationError] = useState('');

    const genders = [
        { title: 'Чоловіча', value: 'm' },
        { title: 'Жіноча', value: 'f' },
        { title: 'Не хочу вказувати', value: 'n' },
    ];

    const [genderMenuOpen, setGenderMenuOpen] = useState(false);

    const [selectedGenderTitle, setSelectedGenderTitle] = useState(null);

    const handleGenderDropdownClick = () => {
        setGenderMenuOpen(!genderMenuOpen);
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        if (name === 'gender') {
            const selectedGender = genders.find(gender => gender.value === value);
            if (selectedGender) {
                setSelectedGenderTitle(selectedGender.title);
                setGenderMenuOpen(false);
            }
        }

        setFormData({
            ...formData,
            [name]: newValue,
        });

        setErrors({
            ...errors,
            [name]: false,
        });
        setValidationError('');
    }

    const setError = (name, message) => {
        setErrors({
            ...errors,
            [name]: true,
        });
        setValidationError(message);
        return false;
    }

    const validateForm = async (formData) => {
      try {
        await registerSchema.validate(formData, { abortEarly: false });
        return true;
      } catch (errors) {
        let error = errors.inner[0];
        return setError(error.params.path, error.message);
      }
    }

    const handleRegistration = async () => {
        const formValid = await validateForm(formData);
        if (formValid === true) {
            setValidationError('');
            const regSuccess = await dispatch(register(formData));
            if (regSuccess) {
                setConfirmPopupOpen(true);
            }
        }
    }

    const handleConfirmRegister = () => {
        setConfirmPopupOpen(false);
        navigate('/');
    }

    const handleCancelRegister = () => {
        setConfirmPopupOpen(false);
        navigate('/');
    }

    const handleGoogleLoginSuccess = (access_token) => {
        dispatch(googleAuth({ access_token }));
    }

    const handleGoogleLoginFailure = () => {
        console.error('Помилка входу через Google');
    }

    // console.log('isAuthenticated', isAuthenticated)
    useEffect(() => {
        if (isAuthenticated) {
          navigate('/');
        }
    }, [isAuthenticated, navigate]);

    return (
        <div className="register container">
            <Breadcrumbs link="/" />
            <div className="register-form auth-form d-flex flex-column align-items-center">
                <GoogleLoginWrap
                    className="btn-large btn-black auth-google-button"
                    buttonText="Реєстрація з Google"
                    onLoginSuccess={handleGoogleLoginSuccess}
                    onLoginFailure={handleGoogleLoginFailure}
                />
                <div className="register-fields w-100 d-flex flex-column">
                    <div className="register-fields-custom register-fields-person d-flex justify-content-between">
                        <div className={`auth-field ${errors.firstname ? 'auth-field-error' : ''}`}>
                            <input className="w-100 h-100 text-center" type="text" name="firstname" placeholder="Ім’я" onChange={handleInputChange} />
                        </div>
                        <div className={`auth-field ${errors.lastname ? 'auth-field-error' : ''}`}>
                            <input className="w-100 h-100 text-center" type="text" name="lastname" placeholder="Прізвище" onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="register-fields-gender d-flex flex-column relative">
                        <div className="register-fields-gender-title">Стать</div>
                        <div
                            className="register-fields-gender-dropdown d-flex justify-content-center align-items-center auth-field"
                            onClick={handleGenderDropdownClick}
                        >
                            <span>{selectedGenderTitle || 'Стать'}</span>
                            <img src={chevronDown} alt="" />
                        </div>
                        <div className={`register-fields-gender-radio ${genderMenuOpen && 'register-fields-gender-radio-opened'} d-flex justify-content-center align-items-center`}>                            
                            {genders.map((item, index) => {
                                const { title, value } = item;
                                return (
                                    <div className="register-fields-gender-radio-item d-flex justify-content-center align-items-center" key={index}>
                                        <label className="d-flex justify-content-center align-items-end" htmlFor={`gender-${value}`}>
                                            <input
                                                id={`gender-${value}`}
                                                type="radio"
                                                name="gender"
                                                value={value}
                                                checked={formData.gender === value}
                                                onChange={handleInputChange}
                                            />
                                            {/*{formData.gender === value ? <img src={radio_active} alt="" /> : <img src={radio_inactive} alt="" />}*/}
                                            {<img src={formData.gender === value ? radio_active : radio_inactive} alt="" />}
                                            <span>{title}</span>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="register-fields-custom d-flex justify-content-between">
                        <div className={`auth-field ${errors.phone ? 'auth-field-error' : ''}`}>
                            <InputMask
                                className="w-100 h-100 text-center"
                                type="text"
                                name="phone"
                                mask={PHONE_MASK}
                                placeholder="Номер телефону"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className={`auth-field ${errors.email ? 'auth-field-error' : ''}`}>
                            <input className="w-100 h-100 text-center" type="email" name="email" placeholder="Email" onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="register-fields-custom d-flex justify-content-between">
                        <div className={`auth-field ${errors.password ? 'auth-field-error' : ''}`}>
                            <input className="w-100 h-100 text-center" type="password" name="password" placeholder="Пароль" onChange={handleInputChange} />
                        </div>
                        <div className={`auth-field ${errors.password_confirmation ? 'auth-field-error' : ''}`}>
                            <input className="w-100 h-100 text-center" type="password" name="password_confirmation" placeholder="Підтвердити пароль" onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
                <div className="register-fields-accept d-flex justify-content-between align-items-center">
                    <label className="register-fields-accept-text" htmlFor="accept">
                        <span className={errors.accept ? 'text-red' : ''}>Я приймаю</span><br />
                        Політика конфіденційності (Privacy Policy)<br />
                        Політика використання файлів куки (Cookie Policy)<br />
                        Умови використання (Terms of Service або Terms and Conditions)<br />
                        Правила використання вмісту (Content Usage Policy)
                    </label>
                    <input className="register-fields-accept-checkbox" id="accept" type="checkbox" name="accept" onChange={handleInputChange} />
                </div>
                <div className="auth-message text-center text-red">
                    {authError || validationError}
                </div>
                <button className="btn-large btn-black" onClick={handleRegistration}>
                    <span>Зарєеструватись</span>
                </button>
                <div className="auth-message register-info text-center">Ви завжди зможете змінити свої дані в<br />налаштуваннях акаунту</div>
            </div>
            <ConfirmRegister
                isOpen={confirmPopupOpen}
                onConfirm={handleConfirmRegister}
                onCancel={handleCancelRegister}
            />
        </div>
    )
}

export default Register;
