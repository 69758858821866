import React from 'react';
import { Link } from 'react-router-dom';
import TopPromotion from './TopPromotion';

const BannerMain = () => {
    const rightItems = [
        { value: '+200', text: 'орендарів по всій Україні вже на платформі' },
        { value: '+5000', text: 'товарів на платформі' },
        { value: '+600', text: 'декораторів вже бронюють на наступний сезон' },
    ];

    return (
        <div className="banner-main w-100 relative">
            <TopPromotion />
            <div className="banner-main-wrap container d-flex justify-content-between align-items-start">
                <div className="banner-main-left">
                    <div className="banner-main-left-title">
                        Керувати івентами - стало легше!
                    </div>
                    <div className="banner-main-left-subtitle">
                        Унікальна платформа в Україні яка допоможе вам керувати івентами НАЙШВИДШИМ можливим шляхом у світі! Навіть не виходячи з дому!
                    </div>
                    <div className="banner-main-left-buttons d-flex align-items-center">
                        <Link to="/register" className="btn btn-small btn-white">
                            Зареєструватись
                        </Link>
                        <a href="#" className="banner-main-left-more-link">
                            Дізнатись більше
                        </a>
                    </div>
                </div>
                <div className="banner-main-right d-flex flex-column">
                    {rightItems.map((item, index) => {
                        const { value, text } = item;
                        return (
                            <div className="banner-main-right-item d-flex" key={index}>
                                <div className="banner-main-right-item-value d-flex justify-content-end align-items-end">{value}</div>
                                <div className="banner-main-right-item-text d-flex align-items-center">{text}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default BannerMain;
