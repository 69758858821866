import * as yup from 'yup';
import * as R from './rules';
import * as C from '../constants/errors';

export default (params) => {
	const schema = {}
	for (const param in params) {
		const { type, label, rules } = params[param];
		if (type == 'string') {
			schema[param] = yup.string();
		}
		if (type == 'number') {
			schema[param] = yup.number().nullable().typeError(C.ERROR_NOT_NUMBER.replace(':field', label));
		}
		for (const rule in rules) {
			// const message = rules[rule];
			schema[param] = R[rule](schema[param], label);
		}
	}
	return yup.object().shape(schema);
}
