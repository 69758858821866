import React from 'react';
import Popup from './Popup';

const DeleteImage = ({ isOpen, onConfirm, onCancel }) => {
    return (
        <Popup
            className="delete-account"
            isOpen={isOpen}
            onConfirm={onConfirm}
            onCancel={onCancel}
            popupText="Видалити зображення?"
            buttonClass="btn-small"
            buttonText="Так"
        />
    )
}

export default DeleteImage;
