export const getDatetimeFormat = (datetime) => {
  const timeZoneDatetime = new Intl.DateTimeFormat('uk-UA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Kyiv'
  }).format(new Date(datetime));
  return timeZoneDatetime;
}

export const formatChatDate = (date) => {
  const chatDate = new Date(date);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  chatDate.setHours(0, 0, 0, 0);

  const formattedDate = new Intl.DateTimeFormat('uk-UA', {
    month: 'long',
    day: 'numeric',
    timeZone: 'Europe/Kyiv'
  }).format(chatDate);

  if (chatDate.getTime() === today.getTime()) {
    return `Сьогодні, ${formattedDate}`;
  } else if (chatDate.getTime() === yesterday.getTime()) {
    return `Вчора, ${formattedDate}`;
  } else {
    return formattedDate;
  }
}
