import React from 'react';
import './styles.scss';
import popupClose from '../../assets/icons/popup-close.svg';

const Popup = ({
    className = '',
    isOpen,
    onConfirm,
    onCancel,
    popupTitle,
    popupText,
    popupForm,
    popupList,
    buttonClass,
    buttonIcon,
    buttonText
}) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="popup-shadow d-flex justify-content-center align-items-center">
            <div className={`popup d-flex flex-column justify-content-center align-items-center relative ${className}`}>
                <img className="popup-close absolute" src={popupClose} onClick={onCancel} alt="" />
                <div className="popup-body d-flex flex-column justify-content-center align-items-center">
                    {popupTitle && <div className="popup-title text-center">{popupTitle}</div>}
                    {popupForm}
                    {popupList}
                    {popupText && <div className="popup-text text-center">{popupText}</div>}
                </div>
                <button
                    className={`popup-button btn-black ${buttonClass}`}
                    onClick={onConfirm}
                >
                    {buttonIcon && <img src={buttonIcon} alt="" />}
                    <span>{buttonText}</span>
                </button>
            </div>
        </div>
    )
}

export default Popup;
