import './styles.scss'

const ContentUsagePolicy = () => {
	return (
		<div className="custom-page container">
		<h1>Правила використання вмісту</h1>

		<h2>Загальна інформація</h2>
		<p>1.1. Ця Політика визначає умови використання та публікації вмісту на веб-сайті для оренди декору (далі - "Сайт").</p>
		<p>1.2. Клієнти, розміщаючи речі для оренди на Сайті, погоджуються з умовами цієї Політики.</p>

		<h2>Власність та відповідальність за вміст</h2>
		<p>2.1. Клієнти несуть повну відповідальність за розміщений ними вміст на Сайті.</p>
		<p>2.2. Сайт не відповідає за достовірність, законність та якість речей, розміщених на Сайті.</p>

		<h2>Авторські права</h2>
		<p>3.1. Клієнти зберігають авторські права на свій вміст, розміщений на Сайті.</p>
		<p>3.2. Розміщуючи вміст на Сайті, клієнти надають Сайту необхідні ліцензії для використання цього вмісту в рамках функціонування Сайту.</p>

		<h2>Заборонений вміст</h2>
		<p>4.1. Заборонено розміщення вмісту, який порушує закони, правила етики, або може завдати шкоди іншим користувачам.</p>
		<p>4.2. Сайт залишає за собою право видаляти або блокувати доступ до будь-якого вмісту, який суперечить цій Політиці.</p>

		<h2>Інші вимоги</h2>
		<p>5.1. Клієнти повинні дотримуватися правил інтелектуальної власності, використовуючи вміст на Сайті.</p>
		<p>5.2. Сайт не несе відповідальності за можливі спірні ситуації, пов'язані з вмістом, розміщеним на Сайті.</p>

		<h2>Зміни у Політиці використання вмісту</h2>
		<p>6.1. Сайт може періодично оновлювати цю Політику. Клієнти повинні переглядати її для ознайомлення з останніми змінами. Ця Політика використання вмісту є частиною Умов користування Сайтом.</p>

		<h2>Зміна Політики конфіденційності</h2>
		<p>8.1. Сайт залишає за собою право змінювати політику конфіденційності шляхом внесення змін до цього документа. У верхній частині документа вказана дата останнього оновлення.</p>
		</div>
	)
}

export default ContentUsagePolicy
