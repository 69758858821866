import api from './api';

class ChatService
{
	async getGeoData() {
		try {
			const response = await api.get(`/chat/geo`);
			return response.data;
		} catch (e) {
			console.log('[ChatService][getGeoData][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getCountries() {
		try {
			const response = await api.get(`/chat/countries`);
			return response.data;
		} catch (e) {
			console.log('[ChatService][getCountries][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getRegions(country) {
		try {
			const response = await api.get(`/chat/regions/${country}`);
			return response.data;
		} catch (e) {
			console.log('[ChatService][getRegions][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getMessages(room, token) {
		try {
			const response = await api.get(`/chat/messages/get/${room}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ChatService][getMessages][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new ChatService();
