import React, { useState, useRef } from 'react';
import InputEmoji from "react-input-emoji";
import attachment from '../../assets/icons/attachment.svg';
import close from '../../assets/icons/popup-close.svg';
import send from '../../assets/icons/send.svg';

const ChatForm = ({ messageSent }) => {
	const [text, setText] = useState('');
	const [attachments, setAttachments] = useState([]);

	const emojiRef = useRef(null);
	const fileInputRef = useRef(null);

	const handleUploadClick = () => {
		fileInputRef.current.click();
	}

	const handleFileChange = (e) => {
		const files = [];
		for (const file of e.target.files) {
			const existingAttachment = attachments.find(a => {
				return a.name === file.name && a.size === file.size && a.type === file.type
			});
			if (!existingAttachment) {
				files.push(file);
			}
		}
		setAttachments((prevAttachments) => [ ...prevAttachments, ...files ]);
	}

	const handleRemoveAttachment = (index) => {
		setAttachments((prevAttachments) => prevAttachments.filter((_, i) => i !== index));
	}

	const handleSendMessage = () => {
		const message = new FormData();

		if (text) {
	    	message.append('text', text);
		}

		if (attachments.length !== 0) {
	    	Array.from(attachments).forEach((file, index) => {
		      message.append(`file_${index}`, file);
		    });
		}
		
		if (message.has('text') || attachments.length !== 0) {
			messageSent(message);
			setText('');
			setAttachments([]);
		}
	}

	return (		
    	<div className="chat-form d-flex flex-column align-items-center">
    		<div className="w-100 d-flex justify-content-between align-items-center">
    			<div className="chat-form-input d-flex align-items-center">
	    			<InputEmoji
						language="uk"
						value={text}
						placeholder="Повідомлення..."
						onChange={setText}
						onEnter={handleSendMessage}
						buttonRef={emojiRef}
						keepOpened
						cleanOnEnter
						shouldReturn
				    />
    			</div>
    			<div className="chat-form-action chat-form-emoji relative" ref={emojiRef}></div>
    			<div className="chat-form-action" onClick={handleUploadClick}>
    				<img className="w-100 h-100" src={attachment} alt="" />
    			</div>
    			<input
					type="file"
					multiple
					ref={fileInputRef}
					style={{ display: 'none' }}
					onChange={handleFileChange}
			    />
    		</div>
    		{attachments.length !== 0 && 
	    		<div className="chat-form-attachments w-100 d-flex flex-wrap align-items-center">
	    			<div className="chat-form-attachments-icon">
	    				<img className="w-100 h-100" src={attachment} alt="" />
	    			</div>
	    			{attachments.map((item, index) => {
	    				return (
	    					<div className="chat-form-attachments-item d-flex align-items-center" key={index}>
	    						<span>{item.name}</span>
	    						<img src={close} alt="" onClick={() => handleRemoveAttachment(index)} />
	    					</div>
	    				)
	    			})}
	    		</div>
	    	}
    		<button className="btn-small btn-black" onClick={handleSendMessage}>
    			<img src={send} alt="" />
    			<span className="w-100 flex-1">Відправити</span>
    		</button>
    	</div>
	)
}

export default ChatForm;
