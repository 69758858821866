export const CLIENT = 'Замовник';
export const EVENT_TYPE = 'Тип івенту';
export const DESCRIPTION = 'Коротка інформація';
export const BUDGET = 'Бюджет';
export const GUESTS = 'Кількість гостей';
export const START_DATETIME = 'Дата та час початку івенту';
export const RECEIVE_DATE = 'Дата отримання перед проектом';
export const RETURN_DATE = 'Дата повернення після проекту';
export const END_DATETIME = 'Дата та Час здачі майданчику';
export const LOCATION = 'Локація';
export const STATUS = 'Статус';
