import api from './api';

class ProjectService
{
	async getMyProjects(token) {
		try {
			const response = await api.get(`/project/my`, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProjectService][getMyProjects][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async createProject(payload, token) {
		try {
			const response = await api.post('/project/create', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProjectService][createProject][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getProjectBySlug(slug) {
		try {
			const response = await api.get(`/project/get/${slug}`);
			return response.data;
		} catch (e) {
			console.log('[ProjectService][getProjectBySlug][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async updateProject(payload, token) {
		try {
			const response = await api.post('/project/update', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProjectService][updateProject][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async setProjectSelected(payload, token) {
		try {
			const response = await api.post('/project/select', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProjectService][setProjectSelected][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async deleteProject(payload, token) {
		try {
			const response = await api.post('/project/delete', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProjectService][deleteProject][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new ProjectService();
