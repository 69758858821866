import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-header.png';
import Infobar from './Infobar';
import Navbar from './Navbar';

const Header = () => {    
    return (
        <div className="header container d-flex flex-column">
            <div className="header-top w-100 d-flex justify-content-between align-items-start">
                <Link to="/" className="header-logo"><img className="w-100 h-100" src={logo} alt="" /></Link>
                <Infobar />
            </div>
            <Navbar />
        </div>
    )
}

export default Header;
