import {
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  GET_PROJECT_REQUEST,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILURE,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  SELECT_PROJECT_SUCCESS,
  SELECT_PROJECT_FAILURE,
  DESELECT_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
} from '../actions/project';

const initialState = {
  projectLoading: true,
  projects: null,
  project: null,
  selectedProject: null,
  error: null,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
        error: null
      };
    case GET_PROJECTS_FAILURE:
      return {
        ...state,
        projects: null,
        error: action.payload
      };
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        selectedProject: action.payload,
        error: null
      };
    case CREATE_PROJECT_FAILURE:
      return {
        ...state,
        selectedProject: null,
        error: action.payload
      };
    case GET_PROJECT_REQUEST:
      return {
        ...state,
        projectLoading: true
      };
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        projectLoading: false,
        project: action.payload,
        error: null
      };
    case GET_PROJECT_FAILURE:
      return {
        ...state,
        projectLoading: false,
        project: null,
        error: action.payload
      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        error: null
      };
    case UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case SELECT_PROJECT_SUCCESS:
      return {
        ...state,
        selectedProject: action.payload,
        error: null
      };
    case SELECT_PROJECT_FAILURE:
      return {
        ...state,
        selectedProject: null,
        error: action.payload
      };
    case DESELECT_PROJECT:
      return {
        ...state,
        selectedProject: null,
        error: null
      };
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        project: null,
        error: null
      };
    case DELETE_PROJECT_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default projectReducer;
