import React, { useState, useRef } from 'react';
import Popup from './Popup';
import { ITEM_TITLE, ITEM_AMOUNT, ITEM_PRICE } from '../../constants/order';
import {
    titleSchema,
    amountSchema,
    priceSchema,
} from '../../validations/order';

const AddOrderItem = ({ isOpen, onConfirm, onCancel }) => {
    const [itemData, setItemData] = useState({
        title: '',
        amount: '',
        price: '',
    });

    const [editingParam, setEditingParam] = useState(null);

    const params = {
        title: {
            field: { label: ITEM_TITLE, name: 'title', value: itemData.title, ref: useRef(null) },
            validationSchema: titleSchema
        },
        amount: {
            field: { label: ITEM_AMOUNT, name: 'amount', value: itemData.amount, ref: useRef(null) },
            validationSchema: amountSchema
        },
        price: {
            field: { label: ITEM_PRICE, name: 'price', value: itemData.price, ref: useRef(null) },
            validationSchema: priceSchema
        }
    }

    const [validationErrors, setValidationErrors] = useState({
    	title: null,
		amount: null,
		price: null,
    });

    const setError = (name, message) => {
        setValidationErrors({
            ...validationErrors,
            [name]: message,
        });

        return false;
    }

    const handleInputChange = (e, param) => {
        const { name, value } = e.target;

        setItemData({
            ...itemData,
            [name]: name == 'amount' || name == 'price' ? Number(value) : value
        });

        setValidationErrors({
            ...validationErrors,
            [name]: null,
        });
    }

    const validateForm = async () => {
      	let validatingParam = null;
      	try {
	        for (const param in params) {
	        	validatingParam = param
	        	await params[param].validationSchema.validate({ [param]: itemData[param] }, { abortEarly: false });
	        }
	        return true;
      	} catch (errors) {
	        let error = errors.inner[1] || errors.inner[0];
	        return setError(validatingParam, error.message);
      	}
    }

    const handleConfirmClick = async () => {
        const formValid = await validateForm();
        if (formValid === true) {
        	onConfirm({ ...itemData, lostAmount: 0, restoreAmount: 0 });
	    }
    }

    const popupForm = (
    	<div className="add-order-item-form">
			<div className="add-order-item-form d-flex flex-wrap">
	            {Object.keys(params).map((param, index) => {
	                const item = params[param];
	                const { field } = item;
	                const { label, name, value, ref } = field;
	                return (
				        <div className={`add-order-item-form-field add-order-item-form-field-${param} ${validationErrors[param] ? 'add-order-item-form-field-error' : ''}`} key={index}>
				            <input className="w-100 h-100 text-black" type="text" name={name} value={value} ref={ref} placeholder={label} onChange={(e) => handleInputChange(e, param)} />
				        </div>
		            )
		        })}
			</div>
        </div>
    );

    return (
        <Popup
            className="add-order-item"
            isOpen={isOpen}
            onConfirm={handleConfirmClick}
            onCancel={onCancel}
            popupTitle="Додати до замовлення"
            popupForm={popupForm}
            buttonClass="btn-small"
            buttonText="Зберегти"
        />
    )
}

export default AddOrderItem;
