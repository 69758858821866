import * as yup from 'yup';
import * as C from '../constants/errors';

export const required = (schema, message) => {
  return schema.required(C.ERROR_FIELD_REQUIRED.replace(':field', message));
}

export const email = (schema, message) => {
  return schema.email(C.ERROR_FIELD_INCORRECT.replace(':field', message));
}
