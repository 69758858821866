import React, { useState } from 'react';
import './styles.scss';
import CatalogMenuItem from './CatalogMenuItem';
import chevronRight from '../../assets/icons/chevron-right.svg';

const CatalogTree = ({ tree, onSelected }) => {
    const [openStates, setOpenStates] = useState([]);

    const [decorOpen, setDecorOpen] = useState(false);
    const [technicsOpen, setTechnicsOpen] = useState(false);

    const openDecorTree = () => {
        setDecorOpen(true);
        setTechnicsOpen(false);
    }

    const openTechnicsTree = () => {
        setTechnicsOpen(true);
        setDecorOpen(false);
    }

    return (
        <div className="catalog-filters-wrap catalog-tree d-flex flex-column">
            <div className={`catalog-filters ${decorOpen && 'catalog-filters-opened'} d-flex flex-wrap catalog-filters-level-0`}>
                <div className="d-flex align-items-center" onClick={openDecorTree}>
                    <span>Оренда декору</span>
                    <img className={`catalog-filters-chevron ${decorOpen ? 'catalog-filters-chevron-opened' : ''}`} src={chevronRight} alt="" />
                </div>
                {tree.decor && <ul className={`${decorOpen ? 'd-flex' : 'd-none'} flex-column`}>
                    {tree.decor.map((child, index) => (
                        <li key={index}>
                            <CatalogMenuItem
                                category={child}
                                path={`decor/${child.slug}`}
                                level={1}
                                openStates={openStates}
                                setOpenStates={setOpenStates}
                                onSelected={onSelected}
                                maxLevel={2}
                            />
                        </li>
                    ))}
                </ul>}
            </div>
            <div className={`catalog-filters ${technicsOpen && 'catalog-filters-opened'} d-flex flex-wrap catalog-filters-level-0`}>
                <div className="d-flex align-items-center" onClick={openTechnicsTree}>
                    <span>Оренда техніки</span>
                    <img className={`catalog-filters-chevron ${technicsOpen ? 'catalog-filters-chevron-opened' : ''}`} src={chevronRight} alt="" />
                </div>
                {tree.technics && <ul className={`${technicsOpen ? 'd-flex' : 'd-none'} flex-column`}>
                    {tree.technics.map((child, index) => (
                        <li key={index}>
                            <CatalogMenuItem
                                category={child}
                                path={`technics/${child.slug}`}
                                level={1}
                                openStates={openStates}
                                setOpenStates={setOpenStates}
                                onSelected={onSelected}
                                maxLevel={2}
                            />
                        </li>
                    ))}
                </ul>}
            </div>
        </div>
    )
}

export default CatalogTree;
