import api from './api';

class SubscriptionService
{
	async getPlans() {
		try {
			const response = await api.get(`/subscription/plans`);
			return response.data;
		} catch (e) {
			console.log('[SubscriptionService][getPlans][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async createSubscription(payload, token) {
		try {
			const response = await api.post(`/subscription/create`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[SubscriptionService][createSubscription][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getSubscriptions(token) {
		try {
			const response = await api.get(`/subscription/get`, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[SubscriptionService][getSubscriptions][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async updateSubscription(payload, token) {
		try {
			const response = await api.post(`/subscription/update`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[SubscriptionService][updateSubscription][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async deleteSubscription(payload, token) {
		try {
			const response = await api.post(`/subscription/delete`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[SubscriptionService][deleteSubscription][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new SubscriptionService();
