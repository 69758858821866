import OrderService from '../services/order.service';
import { deselectProject } from './project';

export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';
export const GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';
export const SELECT_ORDER_INFO_SUCCESS = 'SELECT_ORDER_INFO_SUCCESS';
export const SELECT_ORDER_INFO_FAILURE = 'SELECT_ORDER_INFO_FAILURE';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';
export const DESELECT_ORDER_INFO = 'DESELECT_ORDER_INFO';

export const getOrdersSuccess = (data) => ({ type: GET_ORDERS_SUCCESS, payload: data });
export const getOrdersFailure = (error) => ({ type: GET_ORDERS_FAILURE, payload: error });
export const createOrderSuccess = (data) => ({ type: CREATE_ORDER_SUCCESS, payload: data });
export const createOrderFailure = (error) => ({ type: CREATE_ORDER_FAILURE, payload: error });
export const getOrderRequest = () => ({ type: GET_ORDER_REQUEST });
export const getOrderSuccess = (data) => ({ type: GET_ORDER_SUCCESS, payload: data });
export const getOrderFailure = (error) => ({ type: GET_ORDER_FAILURE, payload: error });
export const addProductSuccess = () => ({ type: ADD_PRODUCT_SUCCESS });
export const addProductFailure = (error) => ({ type: ADD_PRODUCT_FAILURE, payload: error });
export const updateProductSuccess = () => ({ type: UPDATE_PRODUCT_SUCCESS });
export const updateProductFailure = (error) => ({ type: UPDATE_PRODUCT_FAILURE, payload: error });
export const updateOrderSuccess = (data) => ({ type: UPDATE_ORDER_SUCCESS, payload: data });
export const updateOrderFailure = (error) => ({ type: UPDATE_ORDER_FAILURE, payload: error });
export const selectOrderInfoSuccess = (data) => ({ type: SELECT_ORDER_INFO_SUCCESS, payload: data });
export const selectOrderInfoFailure = (error) => ({ type: SELECT_ORDER_INFO_FAILURE, payload: error });
export const deleteOrderSuccess = () => ({ type: DELETE_ORDER_SUCCESS });
export const deleteOrderFailure = (error) => ({ type: DELETE_ORDER_FAILURE, payload: error });
export const deselectOrderInfo = () => ({ type: DESELECT_ORDER_INFO });

export const getMyOrders = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await OrderService.getMyOrders(token);
    console.log(result)
    if (result.success) {
      dispatch(getOrdersSuccess(result.orders));
      return result.orders;
    }
    else {
      dispatch(getOrdersFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(getOrdersFailure(e.toString()));
    return false;
  }
}

export const createOrder = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await OrderService.createOrder(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(createOrderSuccess(result.order));
      return result.order;
    }
    else {
      dispatch(createOrderFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(createOrderFailure(e.toString()));
    return null;
  }
}

export const getOrder = (customerId, ownerId) => async (dispatch) => {
  try {
    dispatch(getOrderRequest());
    const result = await OrderService.getOrder({ customerId, ownerId });
    console.log(result)
    if (result.success) {
      dispatch(getOrderSuccess(result.order));
      return result.order;
    }
    else {
      dispatch(getOrderFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(getOrderFailure(e.toString()));
    return null;
  }
}

export const getOrderBySlug = (slug) => async (dispatch) => {
  try {
    dispatch(getOrderRequest());
    const result = await OrderService.getOrderBySlug(slug);
    console.log(result)
    if (result.success) {
      dispatch(getOrderSuccess(result.order));
      return result.order;
    }
    else {
      dispatch(getOrderFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(getOrderFailure(e.toString()));
    return null;
  }
}

export const addOrderProduct = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await OrderService.addOrderProduct(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(addProductSuccess());
    }
    else {
      dispatch(addProductFailure(result.message));
    }
  } catch (e) {
    dispatch(addProductFailure(e.toString()));
  }
}

export const addOrderItem = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await OrderService.addOrderItem(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(addProductSuccess());
    }
    else {
      dispatch(addProductFailure(result.message));
    }
  } catch (e) {
    dispatch(addProductFailure(e.toString()));
  }
}

export const updateOrderItem = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await OrderService.updateOrderItem(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(updateProductSuccess());
    }
    else {
      dispatch(updateProductFailure(result.message));
    }
  } catch (e) {
    dispatch(updateProductFailure(e.toString()));
  }
}

export const updateOrder = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await OrderService.updateOrder(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(updateOrderSuccess(result.order));
      return result.order;
    }
    else {
      dispatch(updateOrderFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(updateOrderFailure(e.toString()));
    return null;
  }
}

export const updateOrderInfo = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await OrderService.updateOrderInfo(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(updateOrderSuccess(result.order));
      return result.order;
    }
    else {
      dispatch(updateOrderFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(updateOrderFailure(e.toString()));
    return null;
  }
}

export const setOrderInfoSelected = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await OrderService.setOrderInfoSelected({ id }, token);
    console.log(result)
    if (result.success) {
      dispatch(deselectProject());
      dispatch(selectOrderInfoSuccess(result.orderInfo));
      return result.orderInfo;
    }
    else {
      dispatch(selectOrderInfoFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(selectOrderInfoFailure(e.toString()));
    return null;
  }
}

export const deleteOrder = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await OrderService.deleteOrder(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(deleteOrderSuccess());
      return true;
    }
    else {
      dispatch(deleteOrderFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(deleteOrderFailure(e.toString()));
    return false;
  }
}
