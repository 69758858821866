import React, { useEffect } from 'react';
import './styles.scss';
import ProductCard from './ProductCard';

const ProductsPopular = ({ products = [] }) => {
    const productCards = Array.from({ length: 5 }, (_, index) => <ProductCard key={index} selectAmount={false} addButton={false} favIcon={false} />);

    return (
        <div className="products-popular d-flex flex-column align-items-center">
            <div className="products-popular-title">Найпопулярніші товари</div>
            <div className="products-popular-items d-flex justify-content-center">
                {products.length !== 0 && products.map((product, index) => {
                    return <ProductCard product={product} key={index} selectAmount={false} addButton={false} favIcon={false} />
                })}
            </div>
        </div>
    )
}

export default ProductsPopular;
