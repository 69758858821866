import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	createProject,
	getProjectBySlug,
	updateProject,
    setProjectSelected,
    deleteProject
} from '../../actions/project';
import * as C from '../../constants/project';
import {
    clientSchema,
    eventTypeSchema,
    descriptionSchema,
    budgetSchema,
    guestsSchema,
    startDatetimeSchema,
    endDatetimeSchema,
    locationSchema,
} from '../../validations/project';
import { updateOrder, deleteOrder } from '../../actions/order';
import { orderStatuses } from '../../constants/order';
import { createDialog } from '../../actions/dialog';
import Breadcrumbs from '../Common/Breadcrumbs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from  "react-datepicker";
import { uk } from 'date-fns/locale/uk';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';
import DeleteProject from '../Popup/DeleteProject';
import pencilWhite from '../../assets/icons/pencil-white.svg';
import trash from '../../assets/icons/trash.svg';
import plus from '../../assets/icons/plus-white.svg';
import arrowRight from '../../assets/icons/arrow-white-right.svg';

const Project = () => {
	registerLocale('uk', uk)

    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const slug = pathArray.length === 3 ? pathArray[2] : null;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const project = useSelector((state) => state.project.project);
    const projectLoading = useSelector((state) => state.project.projectLoading);

    const [isOwn, setIsOwn] = useState(true);
    const [mode, setMode] = useState(null);

    const initialProjectData = {
        title: '',
        brand: '',
        rentalPrice: '',
        restorePrice: '',
        refundPrice: '',
        cost: '',
        categories: [],
        totalAmount: '',
        freeAmount: '',
        color: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        packaging: '',
        minPersons: '',
    }

    const [projectData, setProjectData] = useState(initialProjectData);
    useEffect(() => {
        const fetchProjectData = async () => {
	    	if (isAuthenticated) {
	    		if (slug) {
	    			if (slug == 'create') {
                        setProjectData(initialProjectData);
			    		setMode('create');
	    			} else {
	            		const resultProject = await dispatch(getProjectBySlug(slug));
	            		if (resultProject) {
	            			if (resultProject.customerId === authUser.id) {
					    		setIsOwn(true);
					    		setMode('edit');
	            			} else {
					    		setIsOwn(false);
					    		setMode('show');
	            			}
	            		} else {
	                		navigate('/404');            			
	            		}
	    			}
	    		} else {
		    		navigate('/');
		    	}
	    	} else if (!userLoading) {
	    		navigate('/');
	    	}
        }

        fetchProjectData();
    }, [slug, userLoading, isAuthenticated, authUser]);

    const [editingParam, setEditingParam] = useState(null);

    const params = {
        client: {
            field: { label: C.CLIENT, name: 'client', value: projectData.client, ref: useRef(null) },
            validationSchema: clientSchema,
            editable: isOwn,
            isEditing: false
        },
        eventType: {
            field: { label: C.EVENT_TYPE, name: 'eventType', value: projectData.eventType, ref: useRef(null) },
            validationSchema: eventTypeSchema,
            editable: isOwn,
            isEditing: false
        },
        description: {
            field: { label: C.DESCRIPTION, name: 'description', value: projectData.description, ref: useRef(null) },
            validationSchema: descriptionSchema,
            editable: isOwn,
            isEditing: false
        },
        budget: {
            field: { label: C.BUDGET, name: 'budget', value: projectData.budget, ref: useRef(null) },
            validationSchema: budgetSchema,
            editable: isOwn,
            isEditing: false
        },
        guests: {
            field: { label: C.GUESTS, name: 'guests', value: projectData.guests, ref: useRef(null) },
            validationSchema: guestsSchema,
            editable: isOwn,
            isEditing: false
        },
        startDatetime: {
            field: { label: C.START_DATETIME, name: 'startDatetime', value: projectData.startDatetime, ref: useRef(null) },
            validationSchema: startDatetimeSchema,
            editable: isOwn,
            isEditing: false
        },
        endDatetime: {
            field: { label: C.END_DATETIME, name: 'endDatetime', value: projectData.endDatetime, ref: useRef(null) },
            validationSchema: endDatetimeSchema,
            editable: isOwn,
            isEditing: false
        },
        location: {
            field: { label: C.LOCATION, name: 'location', value: projectData.location, ref: useRef(null) },
            validationSchema: locationSchema,
            editable: isOwn,
            isEditing: false
        },
    }

    useEffect(() => {
        if (project && slug !== 'create') {
            setProjectData({     
		        client: project.client || '',
		        eventType: project.eventType || '',
		        description: project.description || '',
		        budget: project.budget || '',
		        guests: project.guests || '',
		        startDatetime: new Date(project.startDatetime) || null,
		        endDatetime: new Date(project.endDatetime) || null,
		        location: project.location || '',
            });
        }
    }, [project/*, slug*/]);

    const [validationErrors, setValidationErrors] = useState({
    	client: null,
		eventType: null,
		description: null,
		budget: null,
		guests: null,
		startDatetime: null,
		endDatetime: null,
		location: null,
    });

    const [validationError, setValidationError] = useState(null);

    const setError = (name, message) => {
        setValidationErrors({
            ...validationErrors,
            [name]: message,
        });
        setValidationError(message);
        return false;
    }

    const handleStartDatetimeChange = (selectedDatetime) => {
    	// console.log(selectedDatetime)
    	setProjectData({
            ...projectData,
            startDatetime: selectedDatetime,
        });
  	}

    const handleEndDatetimeChange = (selectedDatetime) => {
    	// console.log(selectedDatetime)
    	setProjectData({
            ...projectData,
            endDatetime: selectedDatetime,
        });
  	}

    const handleInputChange = (e, param) => {
        const { name, value } = e.target;

        setProjectData({
            ...projectData,
            [name]: value
        });

        setValidationErrors({
            ...validationErrors,
            [name]: null,
        });

        setValidationError(null);
    }

    const validateForm = async () => {
      	let validatingParam = null;
      	try {
	        for (const param in params) {
	        	validatingParam = param
	        	await params[param].validationSchema.validate({ [param]: projectData[param] }, { abortEarly: false });
	        }
	        return true;
      	} catch (errors) {
	        let error = errors.inner[1] || errors.inner[0];
	        return setError(validatingParam, error.message);
      	}
    }

    const saveProject = async () => {
        const formValid = await validateForm();
        if (formValid === true) {
	    	if (mode == 'create') {
	    		const createdProject = await dispatch(createProject(projectData));
	    		if (createProject) {
                    navigate(`/project/${createdProject.slug}`);
                }
	    	} else if (mode == 'edit') {
	    		await dispatch(updateProject(project.id, projectData));
                await dispatch(getProjectBySlug(slug));
	    	}
	    }
    }

    const [deletePopupOpen, setDeletePopupOpen] = useState(false);

    const handleDeleteClick = () => {
        setDeletePopupOpen(true);
    }

    const handleConfirmDelete = async () => {
        await dispatch(deleteProject(project.id));
        setDeletePopupOpen(false);
        navigate('/projects');
    }

    const handleCancelDelete = () => {
        setDeletePopupOpen(false);
    }

    const handleDeleteOrder = async (orderId) => {
        await dispatch(deleteOrder({ id: orderId }));
        await dispatch(getProjectBySlug(slug));
    }

    const goToCatalog = async (url = '/catalog') => {
        if (project && mode == 'edit') {
            await dispatch(setProjectSelected(project.id));
            navigate(url);
        }
    }

    const goToDialog = async (order) => {
        const { id, projectId, customerId, ownerId, status } = order;
        let dialog;
        // if (!order.dialog) {
        if (status == 'new') {
            await dispatch(updateOrder({ id, status: 'pending' }));
            dialog = await dispatch(createDialog({ orderId: id, projectId, customerId, ownerId }));
            // dialog = order.dialog;
        }
        else {
            dialog = order.dialog;
        }
        navigate(`/dialog/${dialog.id}`);
    }

    return (
        <div className="projects container">
            <Breadcrumbs link="/projects" />
            <div className="project-body d-flex flex-column">
	            <div className="project-data w-100 d-flex flex-column relative">
	                {(validationError) && <div className="project-error-message auth-message w-100 absolute text-center text-red">{validationError}</div>}
	                <div className="project-data-fields w-100 d-flex flex-column">
	                    {Object.keys(params).map((param, index) => {
	                        const item = params[param];
	                        const { field } = item;
	                        const { label, name, value, ref } = field;
	                        return (
	                            <div className={`project-data-field project-data-field-${name} d-flex`} key={index}>
	                                <div className="project-data-field-label d-flex justify-content-center align-items-center text-black">{label}</div>
	                                <div className="project-data-field-input d-flex justify-content-between align-items-center">
	                                	{name == 'startDatetime' && 
	                                		<DatePicker
	                                			className="w-100"
	                            				locale="uk"
	                            				dateFormat="dd.MM.yyyy HH:mm"
												selected={projectData.startDatetime}
												onChange={handleStartDatetimeChange}
												minDate={new Date()}
												showTimeSelect
											/>
										}
	                        			{name == 'endDatetime' && 
		                        			<DatePicker
	                                			className="w-100"
		                        				locale="uk"
	                            				dateFormat="dd.MM.yyyy HH:mm"
												selected={projectData.endDatetime}
												onChange={handleEndDatetimeChange}
												minDate={new Date()}
												showTimeSelect
											/>
										}
	                                	{!name.includes('Datetime') && 
	                                		<input className="w-100 h-100 text-black" type="text" name={name} value={value} ref={ref} placeholder={label} onChange={(e) => handleInputChange(e, param)} />
	                                	}
	                                </div>
	                            </div>
	                        )
	                    })}
	                </div>
	                <div className="project-data-buttons w-100 d-flex justify-content-end align-items-center">
                        {mode == 'create' && <button className="btn-small btn-black" onClick={saveProject}>
                            <span>Зберегти</span>
                        </button>}
                        {mode == 'edit' && <>
                        	<button className="btn-small btn-black" onClick={saveProject}>
                                <img className="btn-icon-pencil" src={pencilWhite} alt="" />
                            	<span className="w-100 flex-1">Змінити</span>
                        	</button>
                            <button className="btn-small btn-black" onClick={handleDeleteClick}>
                            	<img className="btn-icon-trash" src={trash} alt="" />
                                <span className="w-100 flex-1">Видалити</span>
                            </button>
                        </>}
	                </div>
	            </div>
	            {project && mode == 'edit' && <div className="project-orders d-flex flex-column align-items-center">
	            	<div className="project-orders-title">Декорації</div>
	            	{!!project.orders.length && 
                        <div className="project-orders-list w-100 d-flex flex-column align-items-center">
	            		    {project.orders.map((order, index) => {                               
                                const { firstname, lastname } = order.owner.profile;
                                return (
                                    <div className="project-order w-100 d-flex flex-column align-items-center" key={index}>
                                        <div className="project-order-info w-100 d-flex flex-column align-items-center">
                                            <div className="project-order-info-title">Інформація про декор</div>
                                            <div className="project-order-info-body w-100 d-flex flex-column align-items-end">
                                                <div className="project-order-info-fields w-100 d-flex flex-column">
                                                    <div className="project-order-info-field w-100 d-flex">
                                                        <div className="project-order-info-field-label d-flex justify-content-center align-items-center">
                                                            Орендар
                                                        </div>
                                                        <div className="project-order-info-field-value d-flex justify-content-center align-items-center">
                                                            {`${firstname} ${lastname}`}
                                                        </div>
                                                    </div>
                                                    <div className="project-order-info-field w-100 d-flex">
                                                        <div className="project-order-info-field-label d-flex justify-content-center align-items-center">
                                                            Статус замовлення
                                                        </div>
                                                        <div className="project-order-info-field-value d-flex justify-content-center align-items-center">
                                                            {orderStatuses[order.status]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="project-order-info-buttons w-100 d-flex justify-content-end align-items-center">
                                                    <button className="btn-medium btn-black" onClick={() => handleDeleteOrder(order.id)}>
                                                        <img className="btn-icon-trash" src={trash} alt="" />
                                                        <span>Видалити зі списку</span>
                                                    </button>
                                                    <button className="btn-medium btn-black" onClick={() => goToDialog(order)}>
                                                        <span>Запит підтвердження</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="project-order-items w-100 d-flex flex-column align-items-end">
                                            <Table className="project-order-items-table w-100">
                                                <TableHead>
                                                    <TableRow className="project-order-items-table-row">
                                                        <TableCell className="project-order-items-table-cell project-order-items-table-cell-num">№</TableCell>
                                                        <TableCell className="project-order-items-table-cell project-order-items-table-cell-title">Назва</TableCell>
                                                        <TableCell className="project-order-items-table-cell project-order-items-table-cell-amount">Кількість</TableCell>
                                                        <TableCell className="project-order-items-table-cell project-order-items-table-cell-price">Ціна, грн</TableCell>
                                                        <TableCell className="project-order-items-table-cell project-order-items-table-cell-sum">Сума, грн</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {order.items.map((item, index) => {
                                                        return (
                                                            <TableRow className="project-order-items-table-row" key={index}>
                                                                <TableCell className="project-order-items-table-cell project-order-items-table-cell-num">{index + 1}</TableCell>
                                                                <TableCell className="project-order-items-table-cell project-order-items-table-cell-title">
                                                                    {item.product ? <Link to={`/product/${item.product.slug}`}>{item.title}</Link> : <span>{item.title}</span>}
                                                                </TableCell>
                                                                <TableCell className="project-order-items-table-cell project-order-items-table-cell-amount">{item.amount}</TableCell>
                                                                <TableCell className="project-order-items-table-cell project-order-items-table-cell-price">{item.price}</TableCell>
                                                                <TableCell className="project-order-items-table-cell project-order-items-table-cell-sum">{item.amount * item.price}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                    <TableRow className="project-order-items-table-row">
                                                        <TableCell className="project-order-items-table-cell"></TableCell>
                                                        <TableCell className="project-order-items-table-cell">Всього, грн</TableCell>
                                                        <TableCell className="project-order-items-table-cell"></TableCell>
                                                        <TableCell className="project-order-items-table-cell"></TableCell>
                                                        <TableCell className="project-order-items-table-cell">{
                                                            order.items.reduce((sum, item) => sum + (item.amount * item.price), 0)
                                                        }</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <div className="project-order-items-actions w-100 d-flex justify-content-end align-items-center">
                                                <button className="btn-medium btn-black" onClick={() => goToCatalog(`/catalog/owner/${order.owner.slug}`)}>
                                                    <img className="btn-icon-plus" src={plus} alt="" />
                                                    <span>Додати декор з каталогу</span>
                                                </button>
                                                <button className="btn-medium btn-black" onClick={() => goToDialog(order)}>
                                                    <span>Перейти до діалогу</span>
                                                    <img className="btn-icon-arrow" src={arrowRight} alt="" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })} 
    	            	</div>
                    }
                    {!project.orders.length &&
                        <div className="project-orders-none d-flex flex-column align-items-center">
                            <div className="project-orders-none-text">У проекті поки що немає декорацій</div>
                            <button className="btn-medium btn-black" onClick={() => goToCatalog()}>
                                <span>Перейти в каталог</span>
                            	<img className="btn-icon-trash" src={arrowRight} alt="" />
                            </button>
                        </div>
                    }
	            </div>}
            </div>
            <DeleteProject
                isOpen={deletePopupOpen}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        </div>
    )
}

export default Project;
