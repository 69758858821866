import React, { useState, useEffect, useRef } from 'react';
import ChatMessage from './ChatMessage';
import { formatChatDate } from '../../utils/datetime';

const ChatMessages = ({ messages, authUser }) => {
	const containerRef = useRef(null);

	const [dateMessages, setDateMessages] = useState([]);

	const getDate = (message) => {
		return message.createdAt.split('T')[0];
	}

	useEffect(() => {
		const dateMessages = messages.reduce((acc, message) => {
			(acc[getDate(message)] = acc[getDate(message)] || []).push(message);
			return acc;
		}, {});
		setDateMessages(dateMessages)
	}, [messages]);

	useEffect(() => {
	    const scrollToBottom = () => {
	      if (containerRef.current) {
	        containerRef.current.scrollTo({
	          top: containerRef.current.scrollHeight,
	          behavior: 'smooth'
	        });
	      }
	    }

	    const timeoutId = setTimeout(scrollToBottom, 200);

	    return () => clearTimeout(timeoutId);
	}, [dateMessages])

	return (
		<div className="chat-messages w-100" ref={containerRef}>
			{Object.keys(dateMessages).map((date, index) => {
				return (
					<React.Fragment key={index}>
						<div className="chat-messages-date d-flex justify-content-center">
							{formatChatDate(date)}
						</div>
						<div className="chat-messages-wrap d-flex flex-column">
							{dateMessages[date].map(message => {
								const { id, senderId } = message;
								const isOwn = authUser.id === senderId;
								return <ChatMessage message={message} isOwn={isOwn} key={id} />
							})}
						</div>
					</React.Fragment>
				)

			})}
		</div>
	)
}

export default ChatMessages;
