import {
  GET_PROFILE_USER_SUCCESS,
  GET_PROFILE_USER_FAILURE,
  CHANGE_PROFILE_SUCCESS,
  CHANGE_PROFILE_FAILURE,
  SAVE_AVATAR_SUCCESS,
  SAVE_AVATAR_FAILURE,
  DELETE_AVATAR_SUCCESS,
  DELETE_AVATAR_FAILURE,
} from '../actions/profile';

const initialState = {
  userLoading: true,
  user: null,
  error: null,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        user: action.payload,
        error: null
      };
    case GET_PROFILE_USER_FAILURE:
      return {
        ...state,
        userLoading: false,
        user: null,
        // error: action.payload
      };
    case CHANGE_PROFILE_SUCCESS:
      return {
        ...state,
        error: null
      };
    case CHANGE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case SAVE_AVATAR_SUCCESS:
      return {
        ...state,
        error: null
      };
    case SAVE_AVATAR_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case DELETE_AVATAR_SUCCESS:
      return {
        ...state,
        error: null
      };
    case DELETE_AVATAR_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default profileReducer;
