import * as yup from 'yup';
import * as C from '../constants/product';
import schema from './schema';

export const titleSchema = schema({
  title: { type: 'string', label: C.TITLE, rules: { required: true } }
})
export const brandSchema = schema({
  brand: { type: 'string', label: C.BRAND, rules: { required: true } }
})
export const rentalPriceSchema = schema({
  rentalPrice: { type: 'number', label: C.RENTAL_PRICE, rules: { required: true } }
})
export const restorePriceSchema = schema({
  restorePrice: { type: 'number', label: C.RESTORE_PRICE, rules: { required: true } }
})
export const refundPriceSchema = schema({
  refundPrice: { type: 'number', label: C.REFUND_PRICE, rules: { required: true } }
})
export const costSchema = schema({
  cost: { type: 'number', label: C.COST, rules: { required: true } }
})
export const totalAmountSchema = schema({
  totalAmount: { type: 'number', label: C.TOTAL_AMOUNT, rules: { required: true } }
})
export const freeAmountSchema = schema({
  freeAmount: { type: 'number', label: C.FREE_AMOUNT, rules: { required: true } }
})
export const colorSchema = schema({
  color: { type: 'string', label: C.COLOR, rules: { required: true } }
})
export const lengthSchema = schema({
  length: { type: 'string', label: C.LENGTH, rules: { required: true } }
})
export const widthSchema = schema({
  width: { type: 'string', label: C.WIDTH, rules: { required: true } }
})
export const heightSchema = schema({
  height: { type: 'string', label: C.HEIGHT, rules: { required: true } }
})
export const weightSchema = schema({
  weight: { type: 'string', label: C.WEIGHT, rules: { required: true } }
})
export const packagingSchema = schema({
  packaging: { type: 'string', label: C.PACKAGING, rules: { required: true } }
})
export const minPersonsSchema = schema({
  minPersons: { type: 'string', label: C.MIN_PERSONS, rules: { required: true } }
})