import React, { useState } from 'react';
import './styles.scss';
import CatalogMenuItem from './CatalogMenuItem';
import { hasChildren } from '../../utils/catalog';

const CatalogFilters = ({ category, path, onSelected }) => {
    const [openStates, setOpenStates] = useState([]);

    if (hasChildren(category)) {
        return (
            <div className="catalog-filters-wrap d-flex flex-column">
                {category.children.map((subcategory, index) => (
                    <CatalogMenuItem
                        category={subcategory}
                        path={`${path}/${subcategory.slug}`}
                        key={index}
                        level={1}
                        openStates={openStates}
                        setOpenStates={setOpenStates}
                        onSelected={onSelected}
                    />
                ))}
            </div>
        )
    }

    return (
        <div className="d-flex flex-column">
            <div className="catalog-filters catalog-filters-level-1">{category.title}</div>
        </div>
    )
}

export default CatalogFilters;
