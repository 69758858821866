import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserBySlug } from '../../actions/profile';
import { getUserProducts } from '../../actions/product';
import { parsePath } from '../../utils/catalog';
import { getCategory, getCatalogTree } from '../../actions/catalog';
import CatalogTop from './CatalogTop';
import CatalogSort from './CatalogSort';
import CatalogTree from './CatalogTree';
import CatalogFilters from './CatalogFilters';
import ProductsGrid from '../Product/ProductsGrid';
import heading from '../../assets/images/catalog-owner-heading.png';
import { Pagination } from '@mui/material';

const CatalogOwner = () => {
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const slug = pathArray.length >= 4 ? pathArray[3] : null;
    const { path } = parsePath(location.pathname, null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const owner = useSelector((state) => state.profile.user);
    const userProducts = useSelector((state) => state.product.products);
    const catalogTree = useSelector((state) => state.catalog.tree);
    
    useEffect(() => {
        const fetchData = async () => {
            if (slug) {
                const result = await dispatch(getUserBySlug(slug));
                if (!result) {
                    navigate('/404');
                }
            } else {
                navigate('/');
            }
        }

        fetchData();
    }, [slug]);

    const [products, setProducts] = useState([]);
    const [tree, setTree] = useState(null);
    const [categoryPath, setCategoryPath] = useState(null);
    const [category, setCategory] = useState(null);
    const [sort, setSort] = useState(null);

    useEffect(() => {
        (async () => {
            if (owner) {
                if (path === slug) {
                    await dispatch(getUserProducts(owner.id));
                    await dispatch(getCatalogTree());
                } else {
                    const categoryPath = path.split('/').slice(1).join('/');
                    setCategoryPath(categoryPath);
                    const category = await dispatch(getCategory(categoryPath));
                    if (category) {
                        const categoryUserProducts = category.products.filter(product => product.ownerId === owner.id);
                        setTree(null);
                        setProducts(categoryUserProducts);
                        setCategory(category);                        
                    }
                    else {
                        setTree(catalogTree);
                        setProducts([]);
                        setCategory(null);
                    }
                }
            }
        })();
    }, [owner, path]);

    useEffect(() => {
        setTree(catalogTree);
        setProducts(userProducts);
        setCategory(null);
        setPage(1);
    }, [userProducts, catalogTree]);

    useEffect(() => {
        if (sort && products.length > 0) {
            const { param, desc } = sort;
            const sortedProducts = [...products].sort((a, b) => {
                if (a.features[param] === b.features[param]) return 0;
                if (desc) {
                    return a.features[param] > b.features[param] ? -1 : 1;
                } else {
                    return a.features[param] < b.features[param] ? -1 : 1;
                }
            });
            setProducts(sortedProducts);
            setPage(1);
        }
    }, [sort]);

    const bgStyle = { background: `url(${heading})` };

    const onFilterSelected = (path) => {
        navigate(path);
    }

    const [page, setPage] = useState(1);
    const productsPerPage = 8;
    const topRef = useRef(null);

    const handleChangePage = (event, value) => {
        setPage(value);
        scrollToTop();
    }

    const scrollToTop = () => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const onSortOptionSelected = (option) => {
        setSort(option);
    }

    return (
        <>
            <CatalogTop back={`/`} sort={true} onSortOptionSelected={onSortOptionSelected} />
            <div className="catalog catalog-category" ref={topRef}>
                <div style={bgStyle} className="catalog-part-title w-100 d-flex justify-content-center align-items-center">
                    {`Каталог “${slug}”`}
                </div>
                {/*<CatalogSort className="catalog-part-mobile-sort" />*/}
                {category && <div className="catalog-category-title container d-flex justify-content-center align-items-center">
                    {category.title}
                </div>}
                {products.length !== 0 && <>
                    <div className="catalog-category-content container d-flex justify-content-between align-items-start">
                        {tree && <CatalogTree tree={tree} onSelected={onFilterSelected} />}
                        {category && <CatalogFilters category={category} path={categoryPath} onSelected={onFilterSelected} />}
                        <div className="catalog-category-products d-flex flex-column">
                            <ProductsGrid products={products.slice((page - 1) * productsPerPage, (page - 1) * productsPerPage + productsPerPage)} />
                            <Pagination
                                className="catalog-category-pagination d-flex justify-content-center"
                                count={Math.ceil(products.length / productsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                                color="primary"
                            />
                        </div>
                    </div>
                </>}
            </div>
        </>
    )
}

export default CatalogOwner;
