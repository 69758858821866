import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verifyUser } from '../../actions/auth';

const RegisterConfirm = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const pathArray = location.pathname.split('/');
    const verifyToken = pathArray[pathArray.length - 1];

    useEffect(() => {
        dispatch(verifyUser(verifyToken));
        navigate('/login');
    }, [verifyToken]);

    return null;
}

export default RegisterConfirm;
