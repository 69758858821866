import api from './api';
import { catalog, categoriesForRegistered } from '../constants/catalog';

class CatalogService
{
	getCatalog(catalogType) {
		return catalog[catalogType];
	}

	getCategoriesForRegistered() {
		return categoriesForRegistered.map(category => {
			for (const catalogType in catalog) {
				const catalogCategory = catalog[catalogType].categories.find(item => item.link === category.slug);
				if (catalogCategory) {
					category.link = `catalog/${catalogType}/${category.slug}`;
					return { ...catalogCategory, ...category }
				}
			}
		})
	}

	async getCategory(path) {
		try {
			const response = await api.get(`/catalog/category/get/${path}`);
			let { success, category } = response.data;
			return {
				success,
				category: {
					...category,
					catalogTitle: catalog[category.catalogType].title
				}
			}
		} catch (e) {
			console.log('[CatalogService][getCategory][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getCatalogTree() {
		try {
			const response = await api.get(`/catalog/tree`);
			return response.data;
		} catch (e) {
			console.log('[CatalogService][getCatalogTree][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new CatalogService();
