import api from './api';

class AuthService
{
	async register(payload) {
		try {
			const response = await api.post('/auth/register', payload);
			return response.data;
		} catch (e) {
			console.log('[AuthService][register][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async login(payload) {
		try {
			const response = await api.post('/auth/login', payload);
			return response.data;
		} catch (e) {
			console.log('[AuthService][login][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async googleAuth(payload) {
		try {
			const response = await api.post('/auth/google', payload);
			return response.data;
		} catch (e) {
			console.log('[AuthService][googleAuth][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async verifyUser(payload) {
		try {
			const response = await api.post('/auth/verify', payload);
			return response.data;
		} catch (e) {
			console.log('[AuthService][verifyUser][error]', e);
			return { success: false, message: e.toString() };
		}
	}


	async forgotPassword(payload) {
		try {
			const response = await api.post('/auth/forgot', payload);
			return response.data;
		} catch (e) {
			console.log('[AuthService][forgotPassword][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getUser(token) {
		try {
			const response = await api.get('/auth/user', {
	            headers: {
	            	Authorization: `Bearer ${token}`,
	            	'Cache-Control': 'no-cache'
	           	},
	        });
			return response.data;
		} catch (e) {
			console.log('[AuthService][getUser][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new AuthService();
