export const FIRSTNAME = 'Ім’я';
export const LASTNAME = 'Прізвище';
export const GENDER = 'Стать';
export const PHONE = 'Номер телефону';
export const EMAIL = 'Email';
export const PASSWORD = 'Пароль';
export const PASSWORD_CONFIRMATION = 'Підтвердження паролю';
// ACCEPT
export const COMPANY = 'Компанія';
export const LOCATION = 'Локація';
export const WORKING_HOURS = 'Робочі години';
export const VIBER = 'Viber';
export const WHATSAPP = 'WhatsApp';
export const TELEGRAM = 'Telegram';
export const INSTAGRAM = 'Instagram';

export const PHONE_MASK = '+38 (099) 999-99-99';
export const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp'];

export const ERROR_FIELD_REQUIRED = 'Потрібно вказати :field';
export const ERROR_FIELD_INCORRECT = 'Неправильний :field, спробуйте ще раз';
export const ERROR_MIN_PASSWORD_LENGTH = 'Вкажіть пароль довжиною від :min символів';
export const ERROR_MAX_PASSWORD_LENGTH = 'Вкажіть пароль довжиною до :max символів';
export const ERROR_PASSWORD_COMPARE = 'Пароль та його підтвердження не співпадають';
export const ERROR_ACCEPT = 'Потрібно прийняти умови';
export const ERROR_PHONE_OR_EMAIL_REQUIRED = 'Потрібно вказати номер телефону або Email';
export const ERROR_UNSUPPORTED_IMAGE = 'Непідтримуваний тип зображення';
