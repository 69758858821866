import React from 'react';
import Popup from './Popup';

const DeleteProject = ({ isOpen, onConfirm, onCancel }) => {
    const popupText = <>Ви впевнені що хочете видалити проект?</>;

    return (
        <Popup
            className="delete-project"
            isOpen={isOpen}
            onConfirm={onConfirm}
            onCancel={onCancel}
            popupText={popupText}
            buttonClass="btn-small"
            buttonText="Так"
        />
    )
}

export default DeleteProject;
