import React, { useState } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import CatalogSort from './CatalogSort';
import search from '../../assets/icons/search.svg';

const CatalogActions = ({ className, sort, onSortOptionSelected, searchIn = 'catalog' }) => {
    const navigate = useNavigate();

    const searchPlaces = {
        catalog: 'у каталозі',
        favorites: 'у збережених',
    }

    const [searchQuery, setSearchQuery] = useState('');

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            goSearch()
        }
    }

    const handleChange = (e) => {
        setSearchQuery(e.target.value);
    }

    const goSearch = () => {
        if (searchQuery) {
            navigate(`/${searchIn}/search?q=${searchQuery}`);
        }
    }

    return (
        <div className={`${className} catalog-top-actions d-flex justify-content-end align-items-center`}>
            {sort && <CatalogSort className="catalog-top-actions-sort" onSortOptionSelected={onSortOptionSelected} />}
            <div className="catalog-top-actions-search">
                <input
                    className="catalog-top-actions-search-field"
                    placeholder={`Пошук ${searchPlaces[searchIn]}`}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                />
                <img src={search} className="catalog-top-actions-search-icon" alt="" onClick={goSearch} />                
            </div>
        </div>
    )
}

export default CatalogActions;
