import subIcon1 from '../../assets/icons/subicon-1.svg';
import subIcon2 from '../../assets/icons/subicon-2.svg';
import subIcon3 from '../../assets/icons/subicon-3.svg';
import subIcon4 from '../../assets/icons/subicon-4.svg';

const SubscriptionsFeatures = () => {
	const features = [
		{
			title: '1. Діалоги та Зв\'язок',
			icon: subIcon1,
			description: [
				'Приватні Повідомлення: Підписники можуть вести конфіденційні розмови з іншими користувачами, обговорюючи деталі замовлень та ідеї для декорацій.',
				'Загальний Чат: Приєднуйтеся до загального чату, де ви можете обговорювати тренди, отримувати поради та спілкуватися з іншими творчими особистостями.'
			]
		},
		{
			title: '2. Контактна Інформація',
			icon: subIcon2,
			description: [
				'Відкритий Доступ: Підписники можуть переглядати контактні дані інших користувачів, щоб легко зв\'язуватися та узгоджувати деталі замовлень.'
			]
		},
		{
			title: '3. Замовлення та Декорації',
			icon: subIcon3,
			description: [
				'Приймання та Виконання Замовлень: Підписники можуть приймати замовлення на свої декорації, встановлювати ціни та відправляти їх клієнтам.',
				'Розміщення Декорацій на Маркетплейсі: Додавайте свої найкращі роботи на наш маркетплейс, де клієнти можуть переглядати та купувати їх.'
			]
		},
		{
			title: '4. Ексклюзивні Пропозиції в Майбутньому',
			icon: subIcon4,
			description: [
				'Спеціальні Знижки: Отримуйте доступ до ексклюзивних знижок на інструменти, які допоможуть вам підняти ваш бізнес на новий рівень.'
			]
		},
	];

	return (
		<div className="subscriptions-features w-100 d-flex flex-column align-items-center">
			{features.map((feature, index) => {
				const { title, icon, description } = feature
				return (
					<div className="subscriptions-features-item w-100 d-flex flex-column align-items-center" key={index}>
						<div className="subscriptions-features-item-head d-flex align-items-center">
							<span className="subscriptions-features-item-title">{title}</span>
							{icon && <img className="subscriptions-features-item-icon" src={icon} alt="" />}
						</div>
						<div className="subscriptions-features-item-body d-flex flex-column align-items-center">
							{description.map((item, di) => <div className="text-center" key={di}>{item}</div>)}
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default SubscriptionsFeatures;
