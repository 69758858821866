import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searchProducts } from '../../actions/product';
import { getCatalogTree, getCategory } from '../../actions/catalog';
import CatalogTop from './CatalogTop';
import CatalogSort from './CatalogSort';
import CatalogTree from './CatalogTree';
import ProductsGrid from '../Product/ProductsGrid';
import technics from '../../assets/images/technics-heading.png';
import decor from '../../assets/images/decor-heading.png';
import heading from '../../assets/images/catalog-owner-heading.png';
import { Pagination } from '@mui/material';

const CatalogSearch = () => {
    const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

  	const queryParams = new URLSearchParams(location.search);
  	const searchQuery = queryParams.get('q');

    const foundProducts = useSelector((state) => state.product.products);
    const catalogTree = useSelector((state) => state.catalog.tree);

    const [products, setProducts] = useState([]);
    const [tree, setTree] = useState(null);
    const [categoryPath, setCategoryPath] = useState(null);
    const [sort, setSort] = useState(null);
    
    useEffect(() => {
        (async () => {
            if (searchQuery) {
                await dispatch(getCatalogTree());
            	await dispatch(searchProducts({ query: searchQuery }));
            } else {
                navigate('/');
            }
        })();
    }, [searchQuery]);

    useEffect(() => {
        setTree(catalogTree);
        setProducts(foundProducts);
        setPage(1);
    }, [foundProducts]);

    useEffect(() => {
    	(async () => {
    		await dispatch(searchProducts({ query: searchQuery, categoryPath, sort }));
    	})();
    }, [categoryPath, sort]);

    const bgStyle = { background: `url(${heading})` };

    const onFilterSelected = async (path) => {
    	setCategoryPath(path);
    }

    const [page, setPage] = useState(1);
    const productsPerPage = 8;
    const topRef = useRef(null);

    const handleChangePage = (event, value) => {
        setPage(value);
        scrollToTop();
    }

    const scrollToTop = () => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const onSortOptionSelected = async (option) => {
    	setSort(option);
    }

    return (
        <>
            <CatalogTop back="/catalog" sort={true} onSortOptionSelected={onSortOptionSelected} />
            <div className="catalog catalog-category" ref={topRef}>
                <div style={bgStyle} className="catalog-part-title w-100 d-flex justify-content-center align-items-center">
                    
                </div>
                <CatalogSort className="catalog-part-mobile-sort" onSortOptionSelected={onSortOptionSelected} />
                {searchQuery && <div className="catalog-category-title container d-flex justify-content-center align-items-center">
                    {`Результати за пошуком “${searchQuery}”`}
                </div>}
                <div className="catalog-category-content container d-flex justify-content-between align-items-start">
                    {tree && <CatalogTree tree={tree} onSelected={onFilterSelected} />}
	                {products.length !== 0 && <>
	                    <div className="catalog-category-products d-flex flex-column">
	                        <ProductsGrid products={products.slice((page - 1) * productsPerPage, (page - 1) * productsPerPage + productsPerPage)} />
	                        <Pagination
	                            className="catalog-category-pagination d-flex justify-content-center"
	                            count={Math.ceil(products.length / productsPerPage)}
	                            page={page}
	                            onChange={handleChangePage}
	                            color="primary"
	                        />
	                    </div>
	                </>}
                </div>
            </div>
        </>
    )
}

export default CatalogSearch;
