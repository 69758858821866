import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addOrderProduct } from '../../actions/order';
import { addFavorite, deleteFavorite } from '../../actions/auth';
import { defaultProduct } from '../../constants/product';
import { API_URL } from '../../constants/api';
import defaultProductImage from '../../assets/images/product.png';
import minusWhite from '../../assets/icons/minus-white.svg';
import plusWhite from '../../assets/icons/plus-white.svg';
import heartFilled from '../../assets/icons/heart-filled.svg';
import heartOutlined from '../../assets/icons/heart-outlined.svg';
import AddFavorite from '../Popup/AddFavorite';
import DeleteFavorite from '../Popup/DeleteFavorite';

const ProductCard = ({ product = null, selectAmount = true, addButton = true, favIcon = true }) => {
    const dispatch = useDispatch();

    const authUser = useSelector((state) => state.auth.user);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const selectedOrderInfo = useSelector((state) => state.order.selectedOrderInfo);

    const [activeProject, setActiveProject] = useState(null);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [activeOrderInfo, setActiveOrderInfo] = useState(null);
    const [addedProduct, setAddedProduct] = useState(null);
    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        if (authUser) {
            const projects = Object.values(authUser.projects);
            const orders = Object.values(authUser.ownerOrders);

            if (selectedProject || selectedOrderInfo) {
                if (selectedProject) {
                    setActiveProject(selectedProject);
                    setActiveOrderInfo(null);
                }
                if (selectedOrderInfo) {
                    setActiveOrderInfo(selectedOrderInfo);
                    setActiveProject(null);
                }
            }
            else {
                if (projects.length !== 0) {
                    setActiveProject(projects.find(project => project.selected));
                }
                if (orders.length !== 0) {
                    setActiveOrderInfo(orders
                                        .filter(order => order.info)
                                        .map(order => order.info)
                                        .find(info => info.selected));
                }
            }
        }
    }, [authUser, selectedProject, selectedOrderInfo]);

    const getImageUrl = (path) => {
        if (path.includes('https://')) return path;
        else return `${API_URL}/${path}`;
    }

    const { id: productId, title, brand, rentalPrice, totalAmount } = product ? product.features : defaultProduct;
    const productImage = product && product.images.length ? getImageUrl(product.images[0].path) : defaultProductImage;
    const productUrl = product ? `/product/${product.slug}` : '#';

    const [amount, setAmount] = useState(1);

    const handleInputChange = (e) => {
        const value = Number(e.target.value);
        if (!isNaN(value) && value >= 1 && value <= totalAmount) {
            setAmount(value);
        }
    }

    useEffect(() => {
        if (product && authUser) {
            if (activeProject) {
                if (product.ownerId !== activeProject.customerId) {
                    setButtonDisabled(false);
                }
                else {
                    setButtonDisabled(true);
                }
            }
            else if (activeOrderInfo) {
                if (product.ownerId === activeOrderInfo.order.ownerId) {
                    setButtonDisabled(false);
                }
                else {
                    setButtonDisabled(true);
                }
            }

            if (product.favorites && favIcon) {
                const isFavorite = product.favorites.filter(item => item.userId === authUser.id).length !== 0;
                setIsFavorite(isFavorite);
            }
        }
        else {
            setButtonDisabled(true);
        }
    }, [product, authUser, activeProject, activeOrderInfo]);

    const addToProject = async () => {
        if (product && authUser) {
            if (activeProject) {
                await dispatch(addOrderProduct({ project: activeProject, product, amount }));
            } else if (activeOrderInfo) {
                await dispatch(addOrderProduct({ order: activeOrderInfo.order, product, amount }));
            }
            setAddedProduct(product.id);
        }
    }

    useEffect(() => {
        if (addedProduct) {
            setTimeout(() => setAddedProduct(null), 3000);
        }
    }, [addedProduct]);

    const [addFavoritePopupOpen, setAddFavoritePopupOpen] = useState(false);
    const [deleteFavoritePopupOpen, setDeleteFavoritePopupOpen] = useState(false);

    const handleFavoriteClick = () => {
        if (!isFavorite) setAddFavoritePopupOpen(true);
        else setDeleteFavoritePopupOpen(true);
    }

    const handleConfirmAddFavorite = async (listId) => {
        await dispatch(addFavorite({ productId: product.id, listId }));
        setIsFavorite(!isFavorite);
        setAddFavoritePopupOpen(false);
    }

    const handleCancelAddFavorite = () => {
        setAddFavoritePopupOpen(false);
    }

    const handleConfirmDeleteFavorite = async () => {
        await dispatch(deleteFavorite({ productId: product.id }));
        setIsFavorite(!isFavorite);
        setDeleteFavoritePopupOpen(false);
    }

    const handleCancelDeleteFavorite = () => {
        setDeleteFavoritePopupOpen(false);
    }

    return (
        <div className="product-card d-flex flex-column justify-content-between">
            <div className="product-card-main w-100 d-flex flex-column justify-content-between">
                <Link to={productUrl} className="product-card-image">
                    <img src={productImage} className="product-card-image w-100" />
                </Link>
                <div className="product-card-info d-flex flex-column">
                    <Link to={productUrl}><div className="product-card-info-name">{title}</div></Link>
                    <div className="product-card-info-brand">{brand}</div>
                    <div className="product-card-info-append d-flex justify-content-between align-items-end">
                        <div className="d-flex flex-column">
                            <div className="product-card-info-price">{rentalPrice} грн</div>
                            <div className="product-card-info-qty">Кількість: {totalAmount} шт</div>
                        </div>
                        {totalAmount > 0 && <div className="d-flex flex-column">
                            {/*<div className="product-card-info-amount-title"></div>*/}
                            {selectAmount && <div className="product-card-info-amount-select d-flex align-items-center">
                                <button className="btn-xs btn-black" disabled={amount <= 1} onClick={() => setAmount(amount - 1)}>
                                    <img className="btn-icon-minus" src={minusWhite} alt="" />
                                </button>
                                <div className="product-card-info-amount-select-input">
                                    <input className="w-100 h-100" value={amount} onChange={handleInputChange} />
                                </div>
                                <button className="btn-xs btn-black" disabled={amount >= totalAmount} onClick={() => setAmount(amount + 1)}>
                                    <img className="btn-icon-plus" src={plusWhite} alt="" />
                                </button>
                            </div>}
                        </div>}
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
                {addButton && 
                    <button
                        className={`product-card-add btn-small ${addedProduct && addedProduct === productId ? 'btn-white' : 'btn-black'}`}
                        disabled={isButtonDisabled} onClick={addToProject}
                    >{addedProduct && addedProduct === productId ? 'Додано!' : 'Додати до проекту'}</button>
                }
                {product && favIcon && 
                    <div
                        className="product-card-favorite d-flex justify-content-center align-items-center"
                        onClick={handleFavoriteClick}
                    >
                        {isFavorite 
                            ?   <img src={heartFilled} alt="" />
                            :   <img src={heartOutlined} alt="" />
                        }
                    </div>
                }
            </div>
            <AddFavorite
                isOpen={addFavoritePopupOpen}
                onConfirm={handleConfirmAddFavorite}
                onCancel={handleCancelAddFavorite}
            />
            <DeleteFavorite
                isOpen={deleteFavoritePopupOpen}
                onConfirm={handleConfirmDeleteFavorite}
                onCancel={handleCancelDeleteFavorite}
            />
        </div>
    )
}

export default ProductCard;
