import React from 'react';
import { Link } from 'react-router-dom';

const BannerRegistered = () => {

    return (
        <div className="banner-registered w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="banner-registered-wrap d-flex flex-column justify-content-center align-items-center">
                <div className="banner-registered-title">Global Event Makers</div>
                <div className="banner-registered-subtitle">
                    Унікальна платформа в Україні яка допоможе вам керувати івентами НАЙШВИДШИМ можливим шляхом у світі! Навіть не виходячи з дому!
                </div>
                <Link to="/catalog" className="btn btn-small btn-white">Перейти до каталогу</Link>
            </div>
        </div>
    )
}

export default BannerRegistered;
