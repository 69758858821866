import api from './api';

class OrderService
{
	async getMyOrders(token) {
		try {
			const response = await api.get(`/order/my`, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[OrderService][getMyOrders][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async createOrder(payload, token) {
		try {
			const response = await api.post('/order/create', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[OrderService][createOrder][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getOrder(payload) {
		try {
			const response = await api.post(`/order/get/`);
			return response.data;
		} catch (e) {
			console.log('[OrderService][getOrder][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getOrderBySlug(slug) {
		try {
			const response = await api.get(`/order/get/${slug}`);
			return response.data;
		} catch (e) {
			console.log('[OrderService][getOrderBySlug][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async addOrderProduct(payload, token) {
		try {
			const response = await api.post(`/order/product/add`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[OrderService][addOrderProduct][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async addOrderItem(payload, token) {
		try {
			const response = await api.post(`/order/item/add`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[OrderService][addOrderItem][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async updateOrderItem(payload, token) {
		try {
			const response = await api.post(`/order/item/update`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[OrderService][updateOrderItem][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async updateOrder(payload, token) {
		try {
			const response = await api.post('/order/update', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[OrderService][updateOrder][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async updateOrderInfo(payload, token) {
		try {
			const response = await api.post('/order/info/update', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[OrderService][updateOrderInfo][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async setOrderInfoSelected(payload, token) {
		try {
			const response = await api.post('/order/info/select', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[OrderService][setOrderInfoSelected][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async deleteOrder(payload, token) {
		try {
			const response = await api.post('/order/delete', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[OrderService][deleteOrder][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new OrderService();
