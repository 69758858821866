import React, { useState } from 'react';
import Counter from './Counter';

const BottomPromotion = () => {
    return (
        <div className="bottom-promotion w-100 d-flex flex-column align-items-center">
            <div className="bottom-promotion-text text-center text-white">
                Акційні пропозиції з <br />найменшими можливими цінами <br />за підписку!
            </div>
            <div className="bottom-promotion-subtext text-center text-white">
                акція закінчиться через
            </div>
            <Counter className="bottom-promotion-counter" caption={true} />
        </div>
    )
}

export default BottomPromotion;
