import ProductService from '../services/product.service';

export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';
export const SET_PRODUCT_VIEW_SUCCESS = 'SET_PRODUCT_VIEW_SUCCESS';
export const SET_PRODUCT_VIEW_FAILURE = 'SET_PRODUCT_VIEW_FAILURE';
export const CHANGE_PRODUCT_SUCCESS = 'CHANGE_PRODUCT_SUCCESS';
export const CHANGE_PRODUCT_FAILURE = 'CHANGE_PRODUCT_FAILURE';
export const SAVE_IMAGE_SUCCESS = 'SAVE_IMAGE_SUCCESS';
export const SAVE_IMAGE_FAILURE = 'SAVE_IMAGE_FAILURE';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_FAILURE = 'DELETE_IMAGE_FAILURE';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';
export const GET_POPULAR_PRODUCTS_SUCCESS = 'GET_POPULAR_PRODUCTS_SUCCESS';
export const GET_POPULAR_PRODUCTS_FAILURE = 'GET_POPULAR_PRODUCTS_FAILURE';
export const GET_LAST_VIEWED_PRODUCTS_SUCCESS = 'GET_LAST_VIEWED_PRODUCTS_SUCCESS';
export const GET_LAST_VIEWED_PRODUCTS_FAILURE = 'GET_LAST_VIEWED_PRODUCTS_FAILURE';
export const GET_USER_POPULAR_PRODUCTS_SUCCESS = 'GET_USER_POPULAR_PRODUCTS_SUCCESS';
export const GET_USER_POPULAR_PRODUCTS_FAILURE = 'GET_USER_POPULAR_PRODUCTS_FAILURE';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';

export const createProductSuccess = () => ({ type: CREATE_PRODUCT_SUCCESS });
export const createProductFailure = (error) => ({ type: CREATE_PRODUCT_FAILURE, payload: error });
export const getProductRequest = () => ({ type: GET_PRODUCT_REQUEST });
export const getProductSuccess = (data) => ({ type: GET_PRODUCT_SUCCESS, payload: data });
export const getProductFailure = (error) => ({ type: GET_PRODUCT_SUCCESS, payload: error });
export const setProductViewSuccess = () => ({ type: SET_PRODUCT_VIEW_SUCCESS });
export const setProductViewFailure = (error) => ({ type: SET_PRODUCT_VIEW_FAILURE, payload: error });
export const changeProductSuccess = () => ({ type: CHANGE_PRODUCT_SUCCESS });
export const changeProductFailure = (error) => ({ type: CHANGE_PRODUCT_FAILURE, payload: error });
export const saveImageSuccess = () => ({ type: SAVE_IMAGE_SUCCESS });
export const saveImageFailure = (error) => ({ type: SAVE_IMAGE_FAILURE, payload: error });
export const deleteImageSuccess = () => ({ type: DELETE_IMAGE_SUCCESS });
export const deleteImageFailure = (error) => ({ type: DELETE_IMAGE_FAILURE, payload: error });
export const addCommentSuccess = () => ({ type: ADD_COMMENT_SUCCESS });
export const addCommentFailure = (error) => ({ type: ADD_COMMENT_FAILURE, payload: error });
export const getProductsSuccess = (data) => ({ type: GET_PRODUCTS_SUCCESS, payload: data });
export const getProductsFailure = (error) => ({ type: GET_PRODUCTS_FAILURE, payload: error });
export const getPopularProductsSuccess = (data) => ({ type: GET_POPULAR_PRODUCTS_SUCCESS, payload: data });
export const getPopularProductsFailure = (error) => ({ type: GET_POPULAR_PRODUCTS_FAILURE, payload: error });
export const getLastViewedProductsSuccess = (data) => ({ type: GET_LAST_VIEWED_PRODUCTS_SUCCESS, payload: data });
export const getLastViewedProductsFailure = (error) => ({ type: GET_LAST_VIEWED_PRODUCTS_FAILURE, payload: error });
export const getUserPopularProductsSuccess = (data) => ({ type: GET_USER_POPULAR_PRODUCTS_SUCCESS, payload: data });
export const getUserPopularProductsFailure = (error) => ({ type: GET_USER_POPULAR_PRODUCTS_FAILURE, payload: error });
export const deleteProductSuccess = () => ({ type: DELETE_PRODUCT_SUCCESS });
export const deleteProductFailure = (error) => ({ type: DELETE_PRODUCT_FAILURE, payload: error });

export const createProduct = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.createProduct(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(createProductSuccess());
      return result.product;
    }
    else {
      dispatch(createProductFailure(result.message));
    }
  } catch (e) {
    dispatch(createProductFailure(e.toString()));
  }
}

export const getProductBySlug = (slug) => async (dispatch) => {
  try {
    dispatch(getProductRequest());
    const result = await ProductService.getProductBySlug(slug);
    console.log(result)
    if (result.success) {
      dispatch(getProductSuccess(result.product));
      return result.product
    }
    else {
      dispatch(getProductFailure(result.message));
      return false
    }
  } catch (e) {
    dispatch(getProductFailure(e.toString()));
    return false
  }
}

export const setProductView = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.setProductView({ id }, token);
    console.log(result)
    if (result.success) {
      dispatch(setProductViewSuccess());
    }
    else {
      // dispatch(setProductViewFailure(result.message));
    }
  } catch (e) {
    // dispatch(setProductViewFailure(e.toString()));
  }
}

export const changeProduct = (id, param, value) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.changeProduct({ id, param, value }, token);
    console.log(result)
    if (result.success) {
      dispatch(changeProductSuccess());
    }
    else {
      dispatch(changeProductFailure(result.message));
    }
  } catch (e) {
    dispatch(changeProductFailure(e.toString()));
  }
}

export const setProductCategories = (id, categories) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.setProductCategories({ id, categories }, token);
    console.log(result)
    if (result.success) {
      dispatch(changeProductSuccess());
    }
    else {
      dispatch(changeProductFailure(result.message));
    }
  } catch (e) {
    dispatch(changeProductFailure(e.toString()));
  }
}

export const saveProductImage = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.saveProductImage(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(saveImageSuccess());
    }
    else {
      dispatch(saveImageFailure(result.message));
    }
  } catch (e) {
    dispatch(saveImageFailure(e.toString()));
  }
}

export const deleteProductImage = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.deleteProductImage(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(deleteImageSuccess());
    }
    else {
      dispatch(deleteImageFailure(result.message));
    }
  } catch (e) {
    dispatch(deleteImageFailure(e.toString()));
  }
}

export const addProductComment = (productId, content) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.addProductComment({ productId, content }, token);
    console.log(result)
    if (result.success) {
      dispatch(addCommentSuccess());
    }
    else {
      dispatch(addCommentFailure(result.message));
    }
  } catch (e) {
    dispatch(addCommentFailure(e.toString()));
  }
}

export const setProductActivation = (id, active) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.setProductActivation({ id, active }, token);
    console.log(result)
    if (result.success) {
      dispatch(changeProductSuccess());
    }
    else {
      dispatch(changeProductFailure(result.message));
    }
  } catch (e) {
    dispatch(changeProductFailure(e.toString()));
  }
}

export const getUserProducts = (ownerId) => async (dispatch) => {
  try {
    const result = await ProductService.getUserProducts(ownerId);
    console.log(result)
    if (result.success) {
      dispatch(getProductsSuccess(result.products));
      return result.products
    }
    else {
      dispatch(getProductsFailure(result.message));
      return false
    }
  } catch (e) {
    dispatch(getProductsFailure(e.toString()));
    return false
  }
}

export const getPopularProducts = () => async (dispatch) => {
  try {
    const result = await ProductService.getPopularProducts();
    console.log(result)
    if (result.success) {
      dispatch(getPopularProductsSuccess(result.products));
      return result.products
    }
    else {
      dispatch(getPopularProductsFailure(result.message));
      return false
    }
  } catch (e) {
    dispatch(getPopularProductsFailure(e.toString()));
    return false
  }
}

export const getUserPopularProducts = (ownerId) => async (dispatch) => {
  try {
    const result = await ProductService.getUserPopularProducts(ownerId);
    console.log(result)
    if (result.success) {
      dispatch(getUserPopularProductsSuccess(result.products));
      return result.products
    }
    else {
      dispatch(getUserPopularProductsFailure(result.message));
      return false
    }
  } catch (e) {
    dispatch(getUserPopularProductsFailure(e.toString()));
    return false
  }
}

export const getLastViewedProducts = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.getLastViewedProducts(token);
    console.log(result)
    if (result.success) {
      dispatch(getLastViewedProductsSuccess(result.products));
      return result.products
    }
    else {
      dispatch(getLastViewedProductsFailure(result.message));
      return false
    }
  } catch (e) {
    dispatch(getLastViewedProductsFailure(e.toString()));
    return false
  }
}

export const searchProducts = (payload) => async (dispatch) => {
  try {
    const result = await ProductService.searchProducts(payload);
    console.log(result)
    if (result.success) {
      dispatch(getProductsSuccess(result.products));
      return result.products
    }
    else {
      dispatch(getProductsFailure(result.message));
      return false
    }
  } catch (e) {
    dispatch(getProductsFailure(e.toString()));
    return false
  }
}

export const deleteProduct = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProductService.deleteProduct({ id }, token);
    console.log(result)
    if (result.success) {
      dispatch(deleteProductSuccess());
    }
    else {
      dispatch(deleteProductFailure(result.message));
    }
  } catch (e) {
    dispatch(deleteProductFailure(e.toString()));
  }
}
