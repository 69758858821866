import React, { useState, useEffect, useRef } from 'react';
import { months, getCalendar } from '../../utils/calendar';
import chevronLeft from '../../assets/icons/chevron-left.svg';
import chevronRight from '../../assets/icons/chevron-right.svg';

const ProductCalendar = ({ product }) => {
	const [month, setMonth] = useState(null);
	const [monthNum, setMontNum] = useState(null);
	const [year, setYear] = useState(null);
	const [calendar, setCalendar] = useState(null);

	useEffect(() => {
		const monthNum = new Date().getMonth();
		setMontNum(monthNum);
		setMonth(months[monthNum]);
		const year = new Date().getFullYear();
		setYear(year);
		showCalendar(monthNum, year);
	}, []);

	const showCalendar = (month, year) => {
		const calendar = getCalendar(month, year);
		for (const r in calendar) {
			for (const i in calendar[r]) {
				if (calendar[r][i].date) {
					calendar[r][i].freeAmount = product.features.totalAmount;
					// console.log('calendar[r][i].date', calendar[r][i].date)
					for (const orderItem of product.orderItems) {
						// console.log('receiveDate', orderItem.order.receiveDate.split('T')[0])
						// console.log('returnDate', orderItem.order.returnDate.split('T')[0])
						// console.log(orderItem.order.receiveDate.split('T')[0] >= calendar[r][i].date)
						// console.log(orderItem.order.returnDate.split('T')[0] <= calendar[r][i].date)
						if (orderItem.order.receiveDate.split('T')[0] <= calendar[r][i].date && orderItem.order.returnDate.split('T')[0] >= calendar[r][i].date) {
							calendar[r][i].freeAmount -= orderItem.amount;
						}
					}
					// const orderItem = product.orderItems.find(item => item.order.receiveDate.split('T')[0] <= calendar[r][i].date && item.order.returnDate.split('T')[0] >= calendar[r][i].date);
					// console.log('orderItem', orderItem)
					/*if (orderItem) {
						calendar[r][i].freeAmount -= orderItem.amount;
					}*/
					// console.log('==============')
				}
			}
		}
		setCalendar(calendar);
	}

	const changeMonth = (direction) => {
		let newMonthNum;
		let newYear = year;
		if (direction == 'prev') {
			newMonthNum = monthNum - 1;
			if (newMonthNum < 0) {
				newMonthNum = 11;
				newYear = year - 1;
				setYear(newYear);
			}
		}
		if (direction == 'next') {
			newMonthNum = monthNum + 1;
			if (newMonthNum > 11) {
				newMonthNum = 0;
				newYear = year + 1;
				setYear(newYear);
			}
		}
		setMontNum(newMonthNum);
		setMonth(months[newMonthNum]);
		showCalendar(newMonthNum, newYear);
	}

	const changeYear = (direction) => {
		let newYear = year;
		if (direction == 'prev') {
			newYear = year - 1;
		}
		if (direction == 'next') {
			newYear = year + 1;
		}
		setYear(newYear);
		showCalendar(monthNum, newYear);
	}

	return (
		<div className="product-calendar w-100 d-flex flex-column align-items-center">
			{year && <div className="product-calendar-year d-flex justify-content-between align-items-center">
				<div className="product-calendar-year-prev" onClick={() => changeYear('prev')}>
					<img className="w-100" src={chevronLeft} alt="" />
				</div>
				<div className="product-calendar-year-current">{year}</div>
				<div className="product-calendar-year-next" onClick={() => changeYear('next')}>
					<img className="w-100" src={chevronRight} alt="" />
				</div>
			</div>}
			{month && <div className="product-calendar-month w-100 d-flex justify-content-between align-items-center">
				<div className="product-calendar-month-prev" onClick={() => changeMonth('prev')}>
					<img className="w-100" src={chevronLeft} alt="" />
				</div>
				<div className="product-calendar-month-current">{month}</div>
				<div className="product-calendar-month-next" onClick={() => changeMonth('next')}>
					<img className="w-100" src={chevronRight} alt="" />
				</div>
			</div>}
			{calendar && <div className="product-calendar-dates w-100 d-flex flex-column">
				{calendar.map((row, ri) => {
					return (
						<div className="product-calendar-dates-row w-100 d-flex justify-content-between align-items-center" key={ri}>
							{row.map((item, i) => {
								const { day, freeAmount } = item;
								return (
									<div className="product-calendar-dates-item d-flex flex-column" key={i}>
										<div className="product-calendar-dates-item-day d-flex flex-column justify-content-center align-items-center">{day}</div>
										<div className="product-calendar-dates-item-marker product-calendar-dates-item-marker-green h-100 flex-1"></div>
										<div className="product-calendar-dates-item-legend d-flex flex-column justify-content-center align-items-center">
											{day && <><div>Вільно</div><div>{freeAmount} шт</div></>}
										</div>
									</div>
								)
							})}
						</div>
					)
				})}
			</div>}
		</div>
	);
}

export default ProductCalendar;
