export const ORDER_CONFIRMED = 'Підтверджено';
export const ORDER_PENDING = 'Очікує підтвердження або відхилення';

export const ITEM_TITLE = 'Назва';
export const ITEM_AMOUNT = 'Кількість';
export const ITEM_PRICE = 'Ціна';

export const preOrderStatuses = {
	new: 'Не замовлено',
	pending: 'Очікує підтвердження',
	canceled: 'Відхилено',
	confirmed: 'Підтверджено',
}

export const mainOrderStatuses = {
	ready: 'Готове до видачі',
	issued: 'Видано',
	received: 'Отримано',
	completed: 'Завершено',
}

export const orderStatuses = { ...preOrderStatuses, ...mainOrderStatuses }

export const orderConfirmStatuses = {
	pending: { name: 'Очікує підтвердження або відхилення', color: 'black' },
	canceled: { name: 'Відхилено', color: 'red' },
	confirmed: { name: 'Підтверджено', color: 'green' },
}

export const orderItemsStatuses = {
	new: 'Не замовлено',
	ready: 'Готове до видачі',
	issued: 'Видано',
	completed: 'Завершено/Оплачено',
}
