import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import googleIcon from '../../assets/icons/google.png';

const GoogleLoginButton = ({ className, buttonText, onLoginSuccess, onLoginFailure }) => {
    const responseGoogle = (response) => {
        // console.log('google response', response);
        if (response && response.access_token) {
            onLoginSuccess(response.access_token);
        } else {
            onLoginFailure();
        }
    }

    const googleLogin = useGoogleLogin({
        onSuccess: (response) => {
            // console.log('Success:', response);
            responseGoogle(response);
        },
        onError: (error) => {
            console.error('Error:', error);
            onLoginFailure();
        }
    })

    return (
        <button className={className} onClick={() => googleLogin()}>
            {buttonText}
            <img src={googleIcon} />
        </button>
    )
}

export default GoogleLoginButton;
