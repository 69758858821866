import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, googleAuth } from '../../actions/auth';
import loginSchema from '../../validations/login';
import { PHONE_MASK } from '../../constants/auth';
import InputMask from 'react-input-mask';
import Breadcrumbs from '../Common/Breadcrumbs';
import GoogleLoginWrap from './GoogleLoginWrap';
import ForgotPassword from '../Popup/ForgotPassword';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const authError = useSelector((state) => state.auth.error);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const [formData, setFormData] = useState({
        phone: null,
        email: null,
        password: '',
    });

    const [forgotPopupOpen, setForgotPopupOpen] = useState(false);

    const [errors, setErrors] = useState({
        phone: false,
        email: false,
        password: false,
    });

    const [validationError, setValidationError] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });

        setErrors({
            ...errors,
            [name]: false,
        });
        setValidationError('');
    }

    const setError = (name, message) => {
        setErrors({
            ...errors,
            [name]: true,
        });
        if (message) setValidationError(message);
        return false;
    }

    const validateForm = async (formData) => {
      try {
        await loginSchema.validate(formData, { abortEarly: false });
        return true;
      } catch (errors) {
        let error = errors.inner[1] || errors.inner[0];
        return setError(error.params.path, error.message);
      }
    }

    const handleLogin = async () => {
        const formValid = await validateForm(formData);
        if (formValid === true) {
            setValidationError('');
            dispatch(login(formData));
        }
    }

    const handleForgotClick = () => {
        setForgotPopupOpen(true);
    }

    const handleConfirmForgot = (email) => {
        setForgotPopupOpen(false);
    }

    const handleCancelForgot = () => {
        setForgotPopupOpen(false);
    }

    const handleGoogleLoginSuccess = (access_token) => {
        dispatch(googleAuth({ access_token }));
    }

    const handleGoogleLoginFailure = () => {
        console.error('Помилка входу через Google');
    }

    // console.log('isAuthenticated', isAuthenticated)
    useEffect(() => {
        if (isAuthenticated) {
          navigate('/');
        }
    }, [isAuthenticated, navigate]);

    return (
        <div className="login container">
            <Breadcrumbs link="/" />
            <div className="login-form auth-form d-flex flex-column align-items-center">
                <GoogleLoginWrap
                    className="btn-large btn-black auth-google-button"
                    buttonText="Авторизація з Google"
                    onLoginSuccess={handleGoogleLoginSuccess}
                    onLoginFailure={handleGoogleLoginFailure}
                />
                <div className="login-form-body d-flex flex-column">
                    <div className="login-fields d-flex flex-column">
                        <div className={`auth-field ${errors.phone ? 'auth-field-error' : ''}`}>
                            <InputMask
                                className="w-100 h-100 text-center"
                                type="text"
                                name="phone"
                                mask={PHONE_MASK}
                                placeholder="Номер телефону"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="login-or text-center">або</div>
                        <div className={`auth-field ${errors.email ? 'auth-field-error' : ''}`}>
                            <input className="w-100 h-100 text-center" type="email" name="email" placeholder="Email" onChange={handleInputChange} />
                        </div>
                        <div className={`auth-field ${errors.password ? 'auth-field-error' : ''}`}>
                            <input className="w-100 h-100 text-center" type="password" name="password" placeholder="Пароль" onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="login-bottom d-flex flex-column">
                        <div
                            className="login-forgot"
                            onClick={handleForgotClick}
                        >
                            Забули пароль?
                        </div>
                        <div className="auth-message text-center text-red">
                            {authError || validationError}
                        </div>
                    </div>
                </div>
                <button className="btn-large btn-black" onClick={handleLogin}>
                    <span>Увійти</span>
                </button>
            </div>
            <ForgotPassword
                isOpen={forgotPopupOpen}
                onConfirm={handleConfirmForgot}
                onCancel={handleCancelForgot}
            />
        </div>
    )
}

export default Login;
