import React from 'react';
import './styles.scss';
import BannerMain from './BannerMain';
import MainBlock1 from './MainBlock1';
import MainBlock2 from './MainBlock2';
import MainBlock3 from './MainBlock3';
import MainBlock4 from './MainBlock4';
import MainBlock5 from './MainBlock5';
import BannerBottom from './BannerBottom';

const HomeMain = () => {
    return (
        <div className="home-main">
            <BannerMain />
            <MainBlock1 />
            <div className="home-delimiter"></div>
            <MainBlock2 />
            <div className="home-delimiter"></div>
            <MainBlock3 />
            <div className="home-delimiter"></div>
            <MainBlock4 />
            <MainBlock5 />
            <BannerBottom />
        </div>
    )
}

export default HomeMain;
