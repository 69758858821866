import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../actions/auth';
import { emailSchema } from '../../validations/account';
import Popup from './Popup';

const ForgotPassword = ({ isOpen, onConfirm, onCancel }) => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError(false);
    }

    const handleConfirmClick = async () => {
        try {
            await emailSchema.validate({ email });
            await dispatch(forgotPassword(email));
            onConfirm(email);
        } catch (errors) {
            setEmailError(true);
        }
    }

    const popupForm = (
        <div className={`forgot-password-field ${emailError ? 'forgot-password-field-error' : ''}`}>
            <input className="w-100 h-100" type="email" name="email" placeholder="Email" onChange={(e) => handleEmailChange(e)} />
        </div>
    );

    return (
        <Popup
            className="forgot-password"
            isOpen={isOpen}
            onConfirm={handleConfirmClick}
            onCancel={onCancel}
            popupTitle="Забули пароль?"
            popupForm={popupForm}
            popupText="Будь ласка, вкажіть вашу електронну адресу для отримання тимчасового паролю для входу в акаунт. Також, після входу, не забудьте змінити пароль у налаштуваннях вашого акаунту."
            buttonClass="btn-small"
            buttonText="Відправити"
        />
    )
}

export default ForgotPassword;
