import {
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PHONE_SUCCESS,
  CHANGE_PHONE_FAILURE,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_FAILURE,
  DISCARD_CHANGED,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE
} from '../actions/account';

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  passwordChanged: false,
  phoneChanged: false,
  emailChanged: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChanged: true,
        error: null
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        passwordChanged: false,
        error: action.payload
      };
    case CHANGE_PHONE_SUCCESS:
      return {
        ...state,
        phoneChanged: true,
        error: null
      };
    case CHANGE_PHONE_FAILURE:
      return {
        ...state,
        phoneChanged: false,
        error: action.payload
      };
    case CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        emailChanged: true,
        error: null
      };
    case CHANGE_EMAIL_FAILURE:
      return {
        ...state,
        emailChanged: false,
        error: action.payload
      };
    case DISCARD_CHANGED:
      return {
        ...state,
        passwordChanged: false,
        phoneChanged: false,
        emailChanged: false
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        error: null
      };
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default authReducer;
