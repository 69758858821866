import api from './api';

class AccountService
{

	async changeData(param, payload, token) {
		try {
			const response = await api.post(`/account/change/${param}`, payload, {
	            headers: { Authorization: `Bearer ${token}` },
	        });
			return response.data;
		} catch (e) {
			console.log(`[AccountService][changeData][${param}][error]`, e);
			return { success: false, message: e.toString() };
		}		
	}

	async changePassword(payload, token) {
		try {
			const response = await api.post('/account/change/password', payload, {
	            headers: { Authorization: `Bearer ${token}` },
	        });
			return response.data;
		} catch (e) {
			console.log('[AccountService][changePassword][error]', e);
			return { success: false, message: e.toString() };
		}		
	}

	async changePhone(payload, token) {
		try {
			const response = await api.post('/account/change/phone', payload, {
	            headers: { Authorization: `Bearer ${token}` },
	        });
			return response.data;
		} catch (e) {
			console.log('[AccountService][changePhone][error]', e);
			return { success: false, message: e.toString() };
		}		
	}

	async changeEmail(payload, token) {
		try {
			const response = await api.post('/account/change/email', payload, {
	            headers: { Authorization: `Bearer ${token}` },
	        });
			return response.data;
		} catch (e) {
			console.log('[AccountService][changeEmail][error]', e);
			return { success: false, message: e.toString() };
		}		
	}

	async deleteAccount(token) {
		try {
			const response = await api.delete('/account/delete', {
	            headers: { Authorization: `Bearer ${token}` },
	        });
			return response.data;
		} catch (e) {
			console.log('[AccountService][deleteAccount][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new AccountService();
