import api from './api';

class ProductService
{
	async createProduct(payload, token) {
		console.log('payload in service', payload)
		try {
			const response = await api.post('/product/create', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][createProduct][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getProductBySlug(slug) {
		try {
			const response = await api.get(`/product/get/${slug}`);
			return response.data;
		} catch (e) {
			console.log('[ProductService][getProductBySlug][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getUserStore(token) {
		try {
			const response = await api.get(`/product/store`, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][getUserStore][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getUserProducts(ownerId) {
		try {
			const response = await api.get(`/product/user/${ownerId}`);
			return response.data;
		} catch (e) {
			console.log('[ProductService][getUserProducts][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getPopularProducts() {
		try {
			const response = await api.get(`/product/popular`);
			return response.data;
		} catch (e) {
			console.log('[ProductService][getPopularProducts][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getLastViewedProducts(token) {
		try {
			const response = await api.get(`/product/viewed`, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][getLastViewedProducts][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getUserPopularProducts(ownerId) {
		try {
			const response = await api.get(`/product/popular/${ownerId}`);
			return response.data;
		} catch (e) {
			console.log('[ProductService][getUserPopularProducts][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async setProductView(payload, token) {
		try {
			const response = await api.post('/product/views/set', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][setProductView][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async changeProduct(payload, token) {
		try {
			const response = await api.post('/product/change', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][changeProduct][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async setProductCategories(payload, token) {
		try {
			const response = await api.post('/product/categories', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][setProductCategories][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async saveProductImage(payload, token) {
		try {
			const response = await api.post('/product/image/save', payload, {
	            headers: { 
	            	Authorization: `Bearer ${token}`,
	            	'Content-Type': 'multipart/form-data'
	            },
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][saveProductImage][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async deleteProductImage(payload, token) {
		try {
			const response = await api.post('/product/image/delete', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][deleteProductImage][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async addProductComment(payload, token) {
		try {
			const response = await api.post('/product/comments/add', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][addProductComment][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async setProductActivation(payload, token) {
		try {
			const response = await api.post('/product/activation', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][setProductActivation][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async createFavoritesList(payload, token) {
		try {
			const response = await api.post('/product/favorites/list/create', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][createFavoritesList][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getFavoritesLists(token) {
		try {
			const response = await api.get('/product/favorites/lists', {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][getFavoritesLists][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async deleteFavoritesList(payload, token) {
		try {
			const response = await api.post('/product/favorites/list/delete', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][deleteFavoritesList][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async addFavorite(payload, token) {
		try {
			const response = await api.post('/product/favorites/add', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][addFavorite][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getFavorites(payload, token) {
		try {
			const response = await api.post('/product/favorites/get', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][getFavorites][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async searchFavorites(payload, token) {
		try {
			const response = await api.post('/product/favorites/search', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][searchFavorites][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async deleteFavorite(payload, token) {
		try {
			const response = await api.post('/product/favorites/delete', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][deleteFavorite][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async searchProducts(payload) {
		try {
			const response = await api.post(`/product/search`, payload);
			return response.data;
		} catch (e) {
			console.log('[ProductService][searchProducts][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async deleteProduct(payload, token) {
		try {
			const response = await api.post('/product/delete', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[ProductService][deleteProduct][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new ProductService();
