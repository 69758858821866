import ProfileService from '../services/profile.service';
import { getUser } from './auth';

export const GET_PROFILE_USER_SUCCESS = 'GET_PROFILE_USER_SUCCESS';
export const GET_PROFILE_USER_FAILURE = 'GET_PROFILE_USER_FAILURE';
export const CHANGE_PROFILE_SUCCESS = 'CHANGE_PROFILE_SUCCESS';
export const CHANGE_PROFILE_FAILURE = 'CHANGE_PROFILE_FAILURE';
export const SAVE_AVATAR_SUCCESS = 'SAVE_AVATAR_SUCCESS';
export const SAVE_AVATAR_FAILURE = 'SAVE_AVATAR_FAILURE';
export const DELETE_AVATAR_SUCCESS = 'DELETE_AVATAR_SUCCESS';
export const DELETE_AVATAR_FAILURE = 'DELETE_AVATAR_FAILURE';

export const getProfileUserSuccess = (data) => ({ type: GET_PROFILE_USER_SUCCESS, payload: data });
export const getProfileUserFailure = (error) => ({ type: GET_PROFILE_USER_FAILURE, payload: error });
export const changeProfileSuccess = () => ({ type: CHANGE_PROFILE_SUCCESS });
export const changeProfileFailure = (error) => ({ type: CHANGE_PROFILE_FAILURE, payload: error });
export const saveAvatarSuccess = () => ({ type: SAVE_AVATAR_SUCCESS });
export const saveAvatarFailure = (error) => ({ type: SAVE_AVATAR_FAILURE, payload: error });
export const deleteAvatarSuccess = () => ({ type: DELETE_AVATAR_SUCCESS });
export const deleteAvatarFailure = (error) => ({ type: DELETE_AVATAR_FAILURE, payload: error });

export const getUserBySlug = (slug) => async (dispatch) => {
  try {
    const result = await ProfileService.getUserBySlug(slug);
    console.log(result)
    if (result.success) {
      dispatch(getProfileUserSuccess(result.user));
      return !!result.user
    }
    else {
      dispatch(getProfileUserFailure(result.message));
      return false
    }
  } catch (e) {
    dispatch(getProfileUserFailure(e.toString()));
    return false
  }
}

export const changeProfile = (param, value) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProfileService.changeProfile({ param, value }, token);
    console.log(result)
    if (result.success) {
      dispatch(changeProfileSuccess());
    }
    else {
      dispatch(changeProfileFailure(result.message));
    }
  } catch (e) {
    dispatch(changeProfileFailure(e.toString()));
  }
}

export const saveAvatar = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProfileService.saveAvatar(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(saveAvatarSuccess());
      dispatch(getUser());
    }
    else {
      dispatch(saveAvatarFailure(result.message));
    }
  } catch (e) {
    dispatch(saveAvatarFailure(e.toString()));
  }
}

export const deleteAvatar = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProfileService.deleteAvatar(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(deleteAvatarSuccess());
      dispatch(getUser());
    }
    else {
      dispatch(deleteAvatarFailure(result.message));
    }
  } catch (e) {
    dispatch(deleteAvatarFailure(e.toString()));
  }
}
