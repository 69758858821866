import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import io from 'socket.io-client';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries, getRegions, getMessages } from '../../actions/chat';
import { API_URL } from '../../constants/api';
import Breadcrumbs from '../Common/Breadcrumbs';
import chevronDown from '../../assets/icons/chevron-down.svg';
import ChatMessages from './ChatMessages';
import ChatForm from './ChatForm';

const socket = io(API_URL, {
  auth: { token: localStorage.getItem('token') },
  withCredentials: true
});

const ChatSelectItem = ({ label, items = [], selectedItem = null, onSelected }) => {
	const [menuOpen, setMenuOpen] = useState(false);

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	}
	const selectItem = (item) => {
		setMenuOpen(false);
		onSelected(item);
	}

	return (
        <div className="chat-select-item d-flex">
        	<div className="chat-select-item-label">{label}</div>
        	<div className="chat-select-item-dropdown h-100 relative">
        		<div
        			className="chat-select-item-dropdown-select w-100 h-100 d-flex justify-content-between align-items-center"
        			onClick={toggleMenu}
        		>
        			<span>{selectedItem ? selectedItem.name : ''}</span>
        			<img src={chevronDown} alt="" />
        		</div>
        		{items.length > 1 && menuOpen && 
        			<div className="chat-select-item-dropdown-menu w-100 d-flex flex-column absolute">
        				{items.map((item, index) => {
        					return (
        						<div
        							className={`chat-select-item-dropdown-menu-item ${item.slug === selectedItem.slug && 'chat-select-item-dropdown-menu-item-selected'}`}
        							key={index}
        							onClick={() => selectItem(item)}
        						>
        							{item.name}
        						</div>
        					)
        				})}
	        		</div>
	        	}
        	</div>
        </div>
	)
}

const Chat = () => {
    const location = useLocation();
    const pathArray = location.pathname.split('/');
    const slug = pathArray.length === 3 ? pathArray[2] : null;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const countries = useSelector((state) => state.chat.countries);
    const regions = useSelector((state) => state.chat.regions);
    const messages = useSelector((state) => state.chat.messages);

    const defaultCountry = localStorage.getItem('chat-country') || 'ua';
    const defaultRegion = localStorage.getItem('chat-region') || 'lviv';

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);

    useEffect(() => {
        (async () => {
            await dispatch(getCountries());
            await dispatch(getRegions(defaultCountry));
        })();
    }, []);

    useEffect(() => {
    	if (countries && countries.length) {
    		const country = countries.find(item => item.slug === defaultCountry)
    		if (country) {
	    		setSelectedCountry(country);
    		} else {
    			navigate('/404');
    		}
    	}
    }, [countries])

    useEffect(() => {
        if (isAuthenticated) {
	    	if (!slug) {
	    		navigate(`/chat/${defaultRegion}`);
	    	} else {
	    		if (regions && regions.length) {
		    		const region = regions.find(item => item.slug === slug);
		    		if (region) {
		    			setSelectedRegion(region);
		    		} else {
		    			navigate('/404');
		    		}
	    		}
	    	}
        } else if (!userLoading) {
            navigate('/');
        }
    }, [slug, regions, userLoading, isAuthenticated, authUser]);

    const onCountrySelected = (country) => {
    	setSelectedCountry(country);
    }

    const onRegionSelected = (region) => {
	    setSelectedRegion(region);
    	navigate(`/chat/${region.slug}`);
    }

    const [roomMessages, setRoomMessages] = useState([]);

	useEffect(() => {
		if (selectedCountry && selectedRegion) {
			localStorage.setItem('chat-country', selectedCountry.slug);
			localStorage.setItem('chat-region', selectedRegion.slug);

			socket.emit('joinRoom', {
				country: selectedCountry.slug,
				region: selectedRegion.slug
			});

			const joinedRoomHandler = async (data) => {
				await dispatch(getMessages(data.room));
			}

			const receiveMessageHandler = (data) => {
				const { message } = data;
				setRoomMessages((prevMessages) => [...prevMessages, message]);
			}

			socket.on('joinedRoom', joinedRoomHandler);
			socket.on('receiveMessage', receiveMessageHandler);

			return () => {
				socket.off('joinedRoom', joinedRoomHandler);
				socket.off('receiveMessage', receiveMessageHandler);
			}
		}
	}, [selectedCountry, selectedRegion]);

	useEffect(() => {
		setRoomMessages(messages.sort((a, b) => a.createdAt > b.createdAt));
	}, [messages]);

	const sendMessage = (message) => {
	    const messageObj = {};
  		const filePromises = [];

		for (let [key, value] of message.entries()) {
			if (value instanceof File) {
				const filePromise = new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = () => {
						messageObj[key] = {
							name: value.name,
							type: value.type,
							size: value.size,
							data: reader.result,
						};
						resolve();
					};
					reader.onerror = reject;
					reader.readAsDataURL(value);
				});
				filePromises.push(filePromise);
			} else {
				messageObj[key] = value;
			}
		}

		Promise.all(filePromises).then(() => {
			socket.emit('sendMessage', {
				country: selectedCountry.slug,
				region: selectedRegion.slug,
				messageObj,
			});
		}).catch((error) => {
			console.error('Error reading files:', error);
		});
	}

	return (
		<div className="chat w-100">
	        <div className="chat-top container d-flex flex-column">
	            <Breadcrumbs />
	            <div className="chat-select d-flex">
	            	<ChatSelectItem
	            		label="Країна"
	            		items={countries}
	            		selectedItem={selectedCountry}
	            		onSelected={onCountrySelected}
	            	/>
	            	<ChatSelectItem
	            		label="Область"
	            		items={regions}
	            		selectedItem={selectedRegion}
	            		onSelected={onRegionSelected}
	            	/>	            	
	            </div>
	        </div>
	        <div className="chat-body w-100 d-flex flex-column">
	        	<div className="chat-title w-100 d-flex justify-content-center align-items-center">Чат декораторів</div>
	        	<ChatMessages messages={roomMessages} authUser={authUser} />
	        	<ChatForm messageSent={sendMessage} />
	        </div>
        </div>
    )
}

export default Chat;
