import React, { useEffect } from 'react';
import './styles.scss';
import ProductCard from './ProductCard';

const ProductsGrid = ({ products = [], place = 'catalog' }) => {
    const defaultProductCards = Array.from({ length: 8 }, (_, index) => <ProductCard key={index} />);

    return (
        <div className="products-grid d-flex flex-wrap">
            {products.length !== 0 && products.map((product, index) => {
                return <ProductCard product={product} key={index} favIcon={place !== 'favorites'} />
            })}
            {/*{!products.length && defaultProductCards}*/}
        </div>
    )
}

export default ProductsGrid;
