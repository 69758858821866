import React from 'react';
import Buttons from './Buttons';
import Actions from './Actions';

const Infobar = () => {
    return (
        <div className="header-infobar d-flex justify-content-between align-items-end">
            <Buttons />
            <Actions />
        </div>
    )
}

export default Infobar;
