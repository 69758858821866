import * as yup from 'yup';
import { ITEM_TITLE, ITEM_AMOUNT, ITEM_PRICE } from '../constants/order';
import schema from './schema';

export const titleSchema = schema({
  title: { type: 'string', label: ITEM_TITLE, rules: { required: true } }
})
export const amountSchema = schema({
  amount: { type: 'number', label: ITEM_AMOUNT, rules: { required: true } }
})
export const priceSchema = schema({
  price: { type: 'number', label: ITEM_PRICE, rules: { required: true } }
})
