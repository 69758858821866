import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMyDialogs } from '../../actions/dialog';
import Breadcrumbs from '../Common/Breadcrumbs';
import { API_URL } from '../../constants/api';
import noPhoto from '../../assets/images/profile-no-photo.png';
import { orderConfirmStatuses } from '../../constants/order';

const Dialogs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const myDialogs = useSelector((state) => state.dialog.dialogs);

    const [dialogs, setDialogs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (authUser) {
                await dispatch(getMyDialogs())
            } else if (!userLoading) {
                navigate('/');
            }
        }

        fetchData();
    }, [authUser, userLoading]);

    useEffect(() => {
        if (myDialogs) {
            setDialogs(myDialogs);
        }
    }, [myDialogs]);

    const getAvatarUrl = (avatars) => {
    	if (avatars.length) {
    		const { path } = avatars[0];
        	if (path.includes('https://')) return path;
        	else return `${API_URL}/${path}`;
        }
        return noPhoto;
    }

    const showLastMessage = (message) => {
        const { text, attachments } = message;
        let prefix = authUser.id === message.senderId ? 'Ви: ' : '';
        let result = null;
        if (text) result = text;
        else if (attachments.length) result = attachments[0].name;
        if (result) return prefix + result;
        return null;
    }

    return (
        <div className="dialogs container">
            <Breadcrumbs link="/" />
            <div className="dialogs-body w-100 d-flex flex-column align-items-center">
            	<div className="dialogs-list d-flex flex-column">
            		{dialogs.map((dialog, index) => {
            			let user, mode;
            			if (authUser.id === dialog.customerId) {
            				user = dialog.owner;
            				mode = 'owner';
            			}
            			if (authUser.id === dialog.ownerId) {
            				user = dialog.customer;
            				mode = 'customer';
            			}
            			const { firstname, lastname } = user.profile;
            			const status = orderConfirmStatuses[dialog.order[`${mode}Confirmation`]];
                        const lastMessage = dialog.messages.length ? dialog.messages[0] : null;
            			return (
            				<Link to={`/dialog/${dialog.id}`} className="dialogs-list-item w-100 d-flex" key={index}>
            					<div className="dialogs-list-item-left d-flex flex-column align-items-center">
            						<div className="dialogs-list-item-avatar relative">
            							<div className="dialogs-list-item-avatar-image w-100 h-100">
            								<img src={getAvatarUrl(user.avatars)} />
            							</div>
            							<div className="dialogs-list-item-online-marker absolute"></div>
            						</div>
            						<div className="dialogs-list-item-online-text text-green">Online</div>
            					</div>
            					<div className="dialogs-list-item-main w-100 flex-1 d-flex justify-content-between align-items-center">
	            					<div className="dialogs-list-item-main-left d-flex flex-column justify-content-center">
	            						<div className="dialogs-list-item-collocutor">{`${firstname} ${lastname}`}</div>
	            						<div className="dialogs-list-item-project d-flex flex-wrap align-items-center">
	            							<div>Проект</div>
	            							<div>{
	            								new Intl.DateTimeFormat('uk-UA', {
								                    day: "numeric",
								                    month: "numeric",
								                    year: "numeric",
								                    timeZone: 'Europe/Kyiv',
								                }).format(new Date(dialog.project.startDatetime))
	            							}</div>
	            							<div className={`text-${status.color}`}>{status.name}</div>
	            						</div>
	            						<div className="dialogs-list-item-lastmessage-text">
                                            {lastMessage && <span>{showLastMessage(lastMessage)}</span>}                     
                                        </div>
	            					</div>
	            					<div className="dialogs-list-item-main-right h-100 d-flex justify-content-end align-items-end">
	            						{lastMessage && <div className="dialogs-list-item-lastmessage-datetime d-flex align-items-center">
		            						<div>{
                                                new Intl.DateTimeFormat('uk-UA', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    timeZone: 'Europe/Kyiv',
                                                }).format(new Date(lastMessage.createdAt))
                                            }</div>
		            						<div>{
                                                new Intl.DateTimeFormat('uk-UA', {
                                                    day: "numeric",
                                                    month: "numeric",
                                                    year: "numeric",
                                                    timeZone: 'Europe/Kyiv',
                                                }).format(new Date(lastMessage.createdAt))
                                            }</div>
		            					</div>}
	            					</div>
            						
            					</div>
            				</Link>
            			)
            		})}
            	</div>
            	<div className="dialogs-none">Більше діалогів немає</div>
            </div>
        </div>
    )
}

export default Dialogs;
