import ProjectService from '../services/project.service';
import { deselectOrderInfo } from './order';

export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';
export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';
export const SELECT_PROJECT_SUCCESS = 'SELECT_PROJECT_SUCCESS';
export const SELECT_PROJECT_FAILURE = 'SELECT_PROJECT_FAILURE';
export const DESELECT_PROJECT = 'DESELECT_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'SELECT_PROJECT_FAILURE';
export const DELETE_PROJECT_FAILURE = 'SELECT_PROJECT_FAILURE';

export const getProjectsSuccess = (data) => ({ type: GET_PROJECTS_SUCCESS, payload: data });
export const getProjectsFailure = (error) => ({ type: GET_PROJECTS_FAILURE, payload: error });
export const createProjectSuccess = (data) => ({ type: CREATE_PROJECT_SUCCESS, payload: data });
export const createProjectFailure = (error) => ({ type: CREATE_PROJECT_FAILURE, payload: error });
export const getProjectRequest = () => ({ type: GET_PROJECT_REQUEST });
export const getProjectSuccess = (data) => ({ type: GET_PROJECT_SUCCESS, payload: data });
export const getProjectFailure = (error) => ({ type: GET_PROJECT_SUCCESS, payload: error });
export const updateProjectSuccess = () => ({ type: UPDATE_PROJECT_SUCCESS });
export const updateProjectFailure = (error) => ({ type: UPDATE_PROJECT_FAILURE, payload: error });
export const selectProjectSuccess = (data) => ({ type: SELECT_PROJECT_SUCCESS, payload: data });
export const selectProjectFailure = (error) => ({ type: SELECT_PROJECT_FAILURE, payload: error });
export const deselectProject = () => ({ type: DESELECT_PROJECT });
export const deleteProjectSuccess = () => ({ type: DELETE_PROJECT_SUCCESS, });
export const deleteProjectFailure = (error) => ({ type: DELETE_PROJECT_FAILURE, payload: error });

export const getMyProjects = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProjectService.getMyProjects(token);
    console.log(result)
    if (result.success) {
      dispatch(getProjectsSuccess(result.projects));
      return result.projects;
    }
    else {
      dispatch(getProjectsFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(getProjectsFailure(e.toString()));
    return false;
  }
}

export const createProject = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProjectService.createProject(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(createProjectSuccess(result.project));
      return result.project;
    }
    else {
      dispatch(createProjectFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(createProjectFailure(e.toString()));
    return null;
  }
}

export const getProjectBySlug = (slug) => async (dispatch) => {
  try {
    dispatch(getProjectRequest());
    const result = await ProjectService.getProjectBySlug(slug);
    console.log(result)
    if (result.success) {
      dispatch(getProjectSuccess(result.project));
      return result.project;
    }
    else {
      dispatch(getProjectFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(getProjectFailure(e.toString()));
    return null;
  }
}

export const updateProject = (id, data) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProjectService.updateProject({ id, data }, token);
    console.log(result)
    if (result.success) {
      dispatch(updateProjectSuccess());
    }
    else {
      dispatch(updateProjectFailure(result.message));
    }
  } catch (e) {
    dispatch(updateProjectFailure(e.toString()));
  }
}

export const setProjectSelected = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProjectService.setProjectSelected({ id }, token);
    console.log(result)
    if (result.success) {
      dispatch(deselectOrderInfo());
      dispatch(selectProjectSuccess(result.project));
      return result.project;
    }
    else {
      dispatch(selectProjectFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(selectProjectFailure(e.toString()));
    return null;
  }
}

export const deleteProject = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await ProjectService.deleteProject({ id }, token);
    console.log(result)
    if (result.success) {
      dispatch(deleteProjectSuccess());
    }
    else {
      dispatch(deleteProjectFailure(result.message));
    }
  } catch (e) {
    dispatch(deleteProjectFailure(e.toString()));
  }
}
