import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createFavoritesList } from '../../actions/auth';
import { titleSchema } from '../../validations/product';
import Popup from './Popup';

const CreateFavoritesList = ({ isOpen, onConfirm, onCancel }) => {
    const dispatch = useDispatch();

    const [title, setTitle] = useState(null);
    const [titleError, setTitleError] = useState(false);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
        setTitleError(false);
    }

    const handleConfirmClick = async () => {
        try {
            await titleSchema.validate({ title });
            await dispatch(createFavoritesList({ title }));
            onConfirm(title);
        } catch (errors) {
            setTitleError(true);
        }
    }

    const popupForm = (
        <div className={`create-favorites-list-field ${titleError ? 'create-favorites-list-field-error' : ''}`}>
            <input className="w-100 h-100" type="text" name="title" placeholder="Назва списку" onChange={(e) => handleTitleChange(e)} />
        </div>
    );

    return (
        <Popup
            className="create-favorites-list"
            isOpen={isOpen}
            onConfirm={handleConfirmClick}
            onCancel={onCancel}
            popupTitle="Створення списку збережених"
            popupForm={popupForm}
            buttonClass="btn-small"
            buttonText="Зберегти"
        />
    )
}

export default CreateFavoritesList;
