import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPopularProducts, getLastViewedProducts } from '../../actions/product';
import BannerRegistered from './BannerRegistered';
import CatalogRegistered from '../Catalog/CatalogRegistered';
import ProductsPopular from '../Product/ProductsPopular';
import ProductsViewed from '../Product/ProductsViewed';

const HomeRegistered = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const popularProducts = useSelector((state) => state.product.popularProducts);
    const lastViewedProducts = useSelector((state) => state.product.lastViewedProducts);

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                await dispatch(getPopularProducts())
                await dispatch(getLastViewedProducts())
            } else if (!userLoading) {
                navigate('/');
            }
        })();
    }, [userLoading, isAuthenticated]);

    return (
        <div className="home-registered">
            <BannerRegistered />
            <div className="container d-flex flex-column align-items-center">
                <CatalogRegistered />
                <div className="home-delimiter"></div>
                <div className="home-registered-products d-flex flex-column align-items-center">
                    {popularProducts.length !== 0 && <ProductsPopular products={popularProducts} />}
                    {lastViewedProducts.length !== 0 && <ProductsViewed products={lastViewedProducts} />}
                </div>
            </div>
        </div>
    )
}

export default HomeRegistered;
