import CatalogCategory from '../components/Catalog/CatalogCategory';

const Category = () => {
    return (
		<>
			<CatalogCategory />
		</>
	)
}

export default Category;
