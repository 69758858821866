import './styles.scss'

const PrivacyPolicy = () => {
	return (
		<div className="custom-page container">
		<h1>Політика конфіденційності</h1>
		<p><strong>Дата останнього оновлення:</strong> 04.08.2024р.</p>

		<h2>Загальна інформація</h2>
		<p>1.1. Піклуючись про безпеку особистих даних, ми дуже серйозно ставимось до конфіденційності всіх користувачів. Політика конфіденційності поширюється на персональні дані, які можуть оброблятися у зв'язку з функціонуванням Сервісу, а також на персональні дані, що обробляються в інших випадках, прямо зазначених у ній. Політика конфіденційності не поширюється на обробку персональних даних адміністраторами інших Сервісів, зокрема тих, на які ведуть посилання на Сервісі, які в силу сучасних інтернет-технологій Адміністратор не може контролювати.</p>
		<p>1.2. Визначаємо цілі та засоби обробки персональних даних користувачів і несемо відповідальність за забезпечення дотримання GDPR і будь-яких застосовних національних правил захисту даних.</p>
		<p>1.3. Ця Політика регулює збір, використання та розкриття особистої інформації Користувачів на веб-сайті «eventworkzone.com» для допомоги організаторів івентів, декораторів, орендарів та ін.(далі - "Сайт").</p>
		<p>1.4. Сайт дозволяє реєстрацію та розміщення оголошень про здачу в оренду декору, техніки, конструкцій та т.п..</p>
		<p>1.4 Метою цієї Політики конфіденційності є інформування Користувачів про дії, вжиті у сфері захисту оброблюваних персональних даних, зокрема зібраних через Сервіс і пов'язані з ним сервіси та інструменти, які використовуються Користувачами для виконання таких дій, як реєстрація, публікація та перегляд оголошень.</p>
		<p>1.5 Використання сервісу можливе тільки після ознайомлення з положеннями цієї політики конфіденційності.</p>

		<h2>ВИЗНАЧЕННЯ</h2>
		<p><strong>Персональні дані</strong> - означає ідентифіковану або можливу до ідентифікації фізичної особи інформацію, зокрема: ім'я, прізвище, ідентифікаційний номер, дані про місцезнаходження, IP-адресу, номер телефону та e-mail.</p>
		<p><strong>Обробка</strong> - будь-яка операція або сукупність операцій, що здійснюються з персональними даними або наборами персональних даних автоматизованим або не автоматизованим способом, зокрема збирання, записування, зберігання, скачування, перегляд, використання, розповсюдження або інше надання та видалення даних.</p>
		<p><strong>GDPR</strong> - Регламент (ЄС) 2016/679 Європейського парламенту і Ради від 27 квітня 2016 р. про захист фізичних осіб щодо обробки персональних даних і про вільне переміщення таких даних, а також про скасування Директиви 95/46/ЄС.</p>

		<h2>Збір інформації</h2>
		<p>2.1. Створення Користувачем Облікового запису та використання Сервісу може зажадати надання інформації, що ідентифікує Користувача, а саме: ім'я та прізвище Користувача, податковий номер, адреса електронної пошти, місце та адреса проживання, компетентна податкова інспекція, номер телефону, банківський рахунок.</p>
		<p>2.2. Сайт автоматично збирає технічну інформацію, таку як IP-адреси та тип браузера.</p>

		<h2>Використання інформації</h2>
		<p>3.1. Особиста інформація використовується для реєстрації та управління обліковим записом.</p>
		<p>3.2. Сайт не несе відповідальності за фінансові та організаційні аспекти транзакцій між Користувачами.</p>
		<p>3.3. Інформація про користувачів може використовуватися для покращення обслуговування та вирішення спорів.</p>
		<p>3.4. Особиста інформація, яку ми збираємо, використовується виключно для забезпечення вам доступу до функцій сайту та для покращення якості обслуговування.</p>

		<h2>Розкриття інформації</h2>
		<p>4.1. Ми розкриваємо особисту інформацію лише відповідно до чинного законодавства і тільки за умови виконання юридичних вимог.</p>
		<p>4.2. Сайт може розкривати агреговану інформацію без особистих даних.</p>

		<h2>Конфіденційність та безпека</h2>
		<p>5.1. Наш сайт вживає ряд заходів для захисту вашої особистої інформації, включаючи, але не обмежуючись, шифрування та безпечне зберігання даних.</p>

		<h2>Підписка та оплата</h2>
		<p>6.1. Оплата за користування нашими послугами включає доступ до розширених функцій сайту та інших переваг. Ця оплата не пов'язана з транзакціями між користувачами сайту.</p>
		<p>6.2. Користувачі можуть скасувати підписку в будь-який момент через налаштування облікового запису.</p>

		<h2>Зміни у політиці конфіденційності</h2>
		<p>7.1. Сайт може оновлювати цю політику, ми повідомлятимемо вас про будь-які зміни у політиці конфіденційності шляхом публікації оновленої версії на нашому сайті та/або відправлення повідомлення на вашу електронну адресу.</p>
		<p>Ця Політика є частиною Умов користування Сайтом.</p>

		<h2>2. ПРАВОВА ПІДСТАВА ОБРОБКИ</h2>
		<p>2.1 Виходячи із законних інтересів Сайту з метою забезпечення функціонування та безпеки Сервісу та надання якісних послуг, Сайт може обробляти персональні дані Користувачів у таких цілях:</p>
		<p>2.1.1. Реєстрація облікового запису Користувача.</p>
		<p>2.1.2 Реалізація Послуг і надання Користувачам можливості повноцінно і безпечно використовувати Сервіс.</p>
		<p>2.1.3 Забезпечення безпеки послуг, що надаються в електронному вигляді, в тому числі забезпечення дотримання Регламенту, протидія шахрайству та зловживанням.</p>
		<p>2.1.4 Розгляд скарг.</p>
		<p>2.1.5 Ведення судових і медіаційних розглядів.</p>
		<p>2.1.6 Зберігання фінансової та податкової документації, пов'язаної з розрахунком винагороди, а також пов'язані з ними розрахунки з компетентними державними органами за публічно-правовими зборами.</p>
		<p>2.1.7 Маркетинг власних послуг і продуктів Сайту.</p>
		<p>2.2 Сайт має право обробляти персональні дані з метою відправлення повідомлень, новин або іншої інформації, яка стосується діяльності Сервісу.</p>
		<p>2.3 Обробка персональних даних здійснюється для надання послуг відповідно до Умов надання послуг, укладених між Користувачем та Сайтом.</p>
		<p>2.4. У випадку, коли це потрібно відповідно до законодавства, Сайт може обробляти персональні дані на підставі згоди Користувача, наданої при реєстрації або використанні окремих функцій сервісу. Згода на обробку персональних даних може бути відкликана в будь-який час, що не вплине на правомірність обробки, яка була здійснена на підставі згоди до її відкликання.</p>
		<p>2.5. Якщо інше не випливає з контексту, персональні дані, які обробляються на основі згоди, стосуються виконання договору на надання послуг або забезпечення відповідності правовим зобов'язанням, які поширюються на Сайт.</p>

		<h2>3. ПРАВОВА ПІДСТАВА ДЛЯ ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ</h2>
		<p>3.1 Правовою основою для обробки ваших персональних даних є:</p>
		<p>3.1.1. Стаття 6.1.а GDPR, тобто ваша згода на обробку персональних даних, виражена шляхом реєстрації на Сервісі у формі встановлення відповідного "прапорця" - для цілей реєстрації Облікового запису.</p>
		<p>3.1.2 Законний інтерес Сайту у вигляді необхідності забезпечення можливості демонстрації певних фактів і проведення претензійного провадження - ст. 6.1.f GDPR - у разі обробки даних з метою переслідування, визначення або захисту від претензій, пов'язаних із реєстрацією та використанням Сервісу, або з метою проведення процедури подачі скарги, а також із метою надання Послуги та функціональних можливостей, що пропонуються на Сервісі на підставі Регламенту, маркетингу, власних послуг і продуктів Сайту.</p>
		<p>3.1.3 Зобов'язання, що випливає з положень закону - ст. 6.1.c GDPR - у разі обробки даних з метою зберігання фінансової та податкової документації, пов'язаної з розрахунком винагороди за Послуги та Угоду, а також розрахунком податку в цьому відношенні.</p>

		<h2>4. ПРАВА</h2>
		<p>4.1 Суб'єкт даних має такі права:</p>
		<p>4.1.1 Право запитувати у Сайту доступ до персональних даних, виправлення, видалення або обмеження обробки.</p>
		<p>4.1.2 У тій мірі, в якій підставою для обробки є законний інтерес Сайту, право заперечувати проти обробки.</p>
		<p>4.1.3 Тією мірою, якою підставою для обробки є згода, право на передачу даних.</p>
		<p>4.1.4 В тій мірі, в якій підставою для обробки є згода, право відкликати згоду в будь-який час, проте відкликання згоди не впливає на законність обробки, що здійснювалася на підставі згоди до її відкликання.</p>
		<p>4.1.5 Право подати скаргу до наглядового органу, яким є голова Управління із захисту персональних даних.</p>
		<p>4.2 Щоб скористатися вищевказаними правами, зв'яжіться з нами електронною поштою за такою адресою: delinkevich123@gmail.com або письмово за такою адресою: —-</p>
		<p>4.3 Користувач має право в будь-який час відкликати свою згоду на обробку персональних даних. Відкликання згоди не впливає на правомірність обробки персональних даних до відкликання згоди.</p>

		<h2>5. ДАНІ, ЩО ЗБИРАЮТЬСЯ АВТОМАТИЧНО У ЗВ'ЯЗКУ З ВИКОРИСТАННЯМ СЕРВІСУ</h2>
		<p>5.1 Сайт автоматично збирає дані, пов'язані з відвідуванням Користувачем Сервісу, в тому числі:</p>
		<p>5.1.1. Дані, що містяться в журналах сервера, такі як IP-адреси, доменні імена, тип браузера, тип операційної системи.</p>
		<p>5.1.2 Дані, зібрані за допомогою таких технологій, як файли cookie, API, віджети, локальне сховище і сховище сеансів.</p>
		<p>5.2 У більшості випадків Сайт не може ідентифікувати Користувача за допомогою цих даних або пов'язати його з ідентифікованим Користувачем, але дані все одно будуть захищені. Однак, якщо Сайт може пов'язати таку інформацію з конкретним Користувачем, вона стає персональними даними для Сайту, що може, зокрема, статися, коли Сайт має додаткові дані з іншого джерела, наприклад, вони будуть зібрані з використанням контактних форм, та дає змогу комбінувати його з цими автоматично зібраними даними.</p>
		<p>5.3 Ці дані будуть оброблятися:</p>
		<p>5.3.1 У зв'язку з тим, що вони необхідні для цілей, переслідуваних нами законних інтересів, зокрема:</p>
		<p>5.3.2 Налаштування способу відображення Сервісу та його персоналізація.</p>
		<p>5.3.3 Збереження даних з форм для збереження сесії та полегшення використання Сервісу, а також для можливості користування тією частиною Сервісу, що вимагає реєстрації та авторизації.</p>
		<p>5.3.4 Складання статистики наданих послуг.</p>
		<p>5.3.5 Аналіз поведінки на Сервісі для поліпшення його функціонування і захисту від зловживань.</p>
		<p>5.3.6 На підставі згоди Користувача - у разі, якщо ці дані не є необхідними для функціонування Сервісу, наприклад, використовуються в маркетингових цілях або коли вони збираються з метою їх передачі третім особам.</p>

		<h2>6. НАДАННЯ ПЕРСОНАЛЬНИХ ДАНИХ</h2>
		<p>6.1 Сайт може співпрацювати з третіми особами для надання послуг Користувачам. У цьому випадку ці суб'єкти не уповноважені використовувати персональні дані Користувачів у своїх цілях (дані завжди будуть оброблятися від імені та для потреб Сайту), і їхня діяльність регулюється положеннями чинного законодавства та цим документом Політики конфіденційності. Треті особи зобов'язані зберігати конфіденційність інформації та обробляти її тільки під контролем Сайту. Постачальники послуг, які можуть обробляти персональні дані Користувачів, є довіреними партнерами Сайту, надаючи серверні, хостингові, ICT, поштові послуги, послуги маркетингу та обслуговування клієнтів, бухгалтерський облік і кадри.</p>
		<p>6.2 Дані користувачів також можуть бути надані податковій інспекції для забезпечення дотримання податкових зобов'язань відповідно до законодавства України. Одержувачами персональних даних можуть бути й інші державні органи (наприклад, правоохоронні органи, суди) у разі недотримання Користувачем умов використання або якщо права і законні інтереси Сайту або третіх осіб можуть бути поставлені під загрозу (наприклад, власність, безпека, репутація тощо).</p>

		<h2>7. ЗБЕРІГАННЯ ТА БЕЗПЕКА</h2>
		<p>7.1 Персональні дані Користувачів зберігаються протягом періоду їхньої реєстрації на Сервісі, в цьому випадку дані зберігаються протягом 5 років після розірвання договору відповідно до національного законодавства про податки та бухгалтерський облік України.</p>
		<p>7.2 Всі дані, зібрані Адміністратором, зберігаються на безпечних серверах і захищаються з використанням розумних і заснованих на ризику технічних і організаційних заходів і процедур безпеки для захисту їх від несанкціонованого доступу або несанкціонованого використання. Суб'єкти, пов'язані з Сайтом, довіреними партнерами та зовнішніми постачальниками послуг, керують даними відповідно до вимог безпеки та захисту конфіденційності.</p>
		</div>
	)
}

export default PrivacyPolicy
