import api from './api';

class PaymentService
{
	async getEasypayAppId(token) {
		try {
			const response = await api.get(`/payment/easypay/app-id`, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[PaymentService][getEasypayAppId][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getEasypayPageId(payload, token) {
		try {
			const response = await api.post(`/payment/easypay/page-id`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[PaymentService][getEasypayPageId][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async createPayment(payload, token) {
		try {
			const response = await api.post(`/payment/create`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[PaymentService][createPayment][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new PaymentService();
