import React from 'react';
import img from '../../assets/images/main-block-1.png';
import icon1 from '../../assets/icons/ok-black.svg';
import icon2 from '../../assets/icons/solar_global-linear.svg';
import icon3 from '../../assets/icons/mdi_calendar.svg';
import icon4 from '../../assets/icons/bx_chat.svg';
import icon5 from '../../assets/icons/akar-icons_statistic-up.svg';
import icon6 from '../../assets/icons/mdi_like-outline.svg';

const MainBlock1 = () => {
    const items = [
        { icon: icon1, text: 'Всі івенти в одному місці', dw: 42, mw: 31 },
        { icon: icon2, text: 'Глобальний чат декораторів', dw: 42, mw: 31 },
        { icon: icon3, text: 'Календар оренди', dw: 52, mw: 37 },
        { icon: icon4, text: 'Онлайн узгодження деталей', dw: 44, mw: 31 },
        { icon: icon5, text: 'Статистика івентів', dw: 44, mw: 32 },
        { icon: icon6, text: 'Та багато іншого', dw: 46, mw: 33 },
    ];

    return (
        <div className="main-block main-block-1 container d-flex justify-content-between">
            <div className="main-block-1-left">
                <img src={img} className="w-100" alt="" />
            </div>
            <div className="main-block-1-right d-flex flex-column justify-content-between">
                <div className="main-block-1-right-top d-flex flex-column">
                    <div className="main-block-1-right-title">Global Event Makers</div>
                    <div className="main-block-1-right-subtitle">
                        Наша платформа створена для оптимізації та <span className="text-yellow">пришвидшення</span> процесів створення івентів.
                    </div>
                </div>
                <div className="main-block-1-right-items d-flex flex-wrap">
                    {items.map((item, index) => {
                        const { icon, text, dw, mw } = item;
                        return (
                          <div className="main-block-1-right-item d-flex flex-column justify-content-between align-items-center" key={index}>
                            <img
                                src={icon}
                                className={`main-block-1-right-item-icon main-block-1-right-item-icon-${dw} main-block-1-right-item-icon-${mw}`}
                                alt=""
                            />
                            <div className="main-block-1-right-item-text">{text}</div>
                          </div>
                        )
                    })}
                </div>
                <div className="main-block-1-right-bottom">
                    Платформа стане вашим незамінним <span className="text-yellow">помічником</span> на всіх стадіях івенту.
                </div>
                
            </div>
        </div>
    )
}

export default MainBlock1;
