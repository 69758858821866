import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStore } from '../../actions/auth';
import Breadcrumbs from '../Common/Breadcrumbs';
import arrowRight from '../../assets/icons/arrow-white-right.svg';
import plus from '../../assets/icons/plus-white.svg';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';
import { API_URL } from '../../constants/api';

const Store = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const store = useSelector((state) => state.auth.store);

    const [active, setActive] = useState(true);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (authUser) {
                await dispatch(getUserStore())
            } else if (!userLoading) {
                navigate('/');
            }
        }

        fetchData();
    }, [authUser]);

    useEffect(() => {
        if (store) {
            if (active) {
                setProducts(store.activeProducts);
                setPage(0);
            }
            else {
                setProducts(store.inactiveProducts);
                setPage(0);
            }
        }
    }, [store, active]);

    const switchProducts = async () => {
        setActive(!active)
    }

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from}–${to} з ${count !== -1 ? count : `більше ніж ${to}`}`;
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const getImageUrl = (path) => {
        if (path.includes('https://')) return path;
        else return `${API_URL}/${path}`;
    }

    return (
        <div className="store container">
            <Breadcrumbs link="/" />
            <div className="store-body w-100 d-flex flex-column align-items-center">
            	<div className="store-title">Активовані товари</div>
            	<div className="store-buttons w-100 d-flex justify-content-end">
            		<button className="btn-medium btn-black" onClick={switchProducts}>
            			{active ? <span>Перейти до деактивованих</span> : <span>Перейти до активованих</span>}
                    	<img className="btn-icon-arrow" src={arrowRight} alt="" />
            		</button>
            		<Link to="/product/create" className="btn btn-medium btn-black">
                    	<img className="btn-icon-plus" src={plus} alt="" />
            			<span>Додати товар вручну</span>
            		</Link>
            	</div>
                {products.length !== 0 && <>
                    <Table className="store-table w-100">
                        <TableHead>
                            <TableRow className="store-table-row">
                                <TableCell className="store-table-cell store-table-cell-num">№</TableCell>
                                <TableCell className="store-table-cell store-table-cell-image">Фото</TableCell>
                                <TableCell className="store-table-cell store-table-cell-title">Назва</TableCell>
                                <TableCell className="store-table-cell store-table-cell-category">Категорія</TableCell>
                                <TableCell className="store-table-cell store-table-cell-amount">Кількість на складі</TableCell>
                                <TableCell className="store-table-cell store-table-cell-contracts">Завершених угод</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, index) => {
                                return (
                                    <TableRow className="store-table-row" key={index} onClick={() => navigate(`/product/${product.slug}`)}>
                                        <TableCell className="store-table-cell store-table-cell-num">{product.num}</TableCell>
                                        <TableCell className="store-table-cell store-table-cell-image">
                                            {product.images.length ? <img src={getImageUrl(product.images[0].path)} alt="" /> : null}
                                        </TableCell>
                                        <TableCell className="store-table-cell store-table-cell-title">{product.features.title}</TableCell>
                                        <TableCell className="store-table-cell store-table-cell-category">
                                            {product.categories.map((category, index) => (
                                                <div key={index}>{category.title}</div>
                                            ))}
                                        </TableCell>
                                        <TableCell className="store-table-cell store-table-cell-amount">{product.features.totalAmount}</TableCell>
                                        <TableCell className="store-table-cell store-table-cell-contracts">0</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="Показувати на сторінці"
                        rowsPerPageOptions={[5, 10, 25, { label: 'Всі', value: -1 }]}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        component="div"
                        count={products.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
            </div>
        </div>
    )
}

export default Store;
