import { useSelector } from 'react-redux';
import HomeMain from '../components/Home/HomeMain';
import HomeRegistered from '../components/Home/HomeRegistered';

const Home = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    if (isAuthenticated) return <HomeRegistered />

    return <HomeMain />
}

export default Home;
