import api from './api';

class ProfileService
{
	async getUserBySlug(slug) {
		try {
			const response = await api.get(`/profile/get/${slug}`);
			return response.data;
		} catch (e) {
			console.log('[ProfileService][getUserBySlug][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async changeProfile(payload, token) {
		try {
			const response = await api.post('/profile/change', payload, {
	            headers: { Authorization: `Bearer ${token}` },
	        });
			return response.data;
		} catch (e) {
			console.log('[ProfileService][changeProfile][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async saveAvatar(payload, token) {
		try {
			const response = await api.post('/profile/avatar/save', payload, {
	            headers: { 
	            	Authorization: `Bearer ${token}`,
	            	'Content-Type': 'multipart/form-data'
	            },
	        });
			return response.data;
		} catch (e) {
			console.log('[ProfileService][saveAvatar][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async deleteAvatar(payload, token) {
		try {
			const response = await api.post('/profile/avatar/delete', payload, {
	            headers: { 
	            	Authorization: `Bearer ${token}`
	            },
	        });
			return response.data;
		} catch (e) {
			console.log('[ProfileService][deleteAvatar][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new ProfileService();
