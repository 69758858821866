import React from 'react';
import angry from '../../assets/icons/angry.svg';
import ok from '../../assets/icons/ok-circle.svg';
import no from '../../assets/icons/no-outline.svg';

const MainBlock2 = () => {
    const problems = [
        { text: 'Різні месенджери для комунікації з кожним іншим орендарем чи організатором' },
        { text: 'Десятки груп в месенджерах' },
        { text: 'Видалені повідомлення недобросовісними особами' },
        { text: 'Безліч годин пошуку декорацій або потрібних контактів' },
        { text: 'Безліч годин спілкування для узгодження елементарних питань' },
        { text: 'Безліч годин підрахунку проектів' },
    ];

    const advantages = [
        { text: 'Ведення багатьох івентів у одному акаунті' },
        { text: 'Календар оренди товару - відкине всі питання типу "Чи вільний оцей товар на оцю дату"' },
        { text: 'Обговорювати та змінювати терміни, ціни, можливість доставки, монтажу та всі інші нюанси' },
        { text: 'Ви знайдете потрібні товари в декілька кліків' },
        { text: 'Зможете самі виставляти товари в каталог' },
        { text: 'Бачити статистику та інше' },
    ];

    return (
        <div className="main-block main-block-2 d-flex justify-content-between">
            <div className="main-block-2-left h-100 d-flex flex-column justify-content-between">
                <div className="main-block-2-left-text">Тут ви забудете про <span className="text-yellow">десятки</span><br /><span className="text-yellow">проблем</span>, які забирали ваш особистий час та простір</div>
                <div className="main-block-2-left-problems d-flex flex-column">
                    <div className="main-block-2-left-problems-icons d-flex justify-content-center align-items-center">
                        <img src={angry} className="main-block-2-left-problems-icon" alt="" />
                        <img src={angry} className="main-block-2-left-problems-icon" alt="" />
                        <img src={angry} className="main-block-2-left-problems-icon" alt="" />
                    </div>
                    {problems.map((item, index) => {
                        const { text } = item;
                        return (
                          <div className="main-block-2-left-problem d-flex justify-content-between align-items-center" key={index}>
                            <div className="main-block-2-left-problem-text">{text}</div>
                            <img src={no} className="main-block-2-left-problem-icon" alt="" />
                          </div>
                        )
                    })}
                </div>
            </div>
            <div className="main-block-2-right h-100 d-flex justify-content-center align-items-center">
                <div className="main-block-2-right-advantages d-flex flex-column justify-content-center">
                    {advantages.map((item, index) => {
                        const { text } = item;
                        return (
                            <div key={index}>
                              <div className="main-block-2-right-advantage d-flex justify-content-between align-items-center">
                                <div className="main-block-2-right-advantage-text">{text}</div>
                                <img src={ok} className="main-block-2-right-advantage-icon" alt="" />
                              </div>
                              <div className="main-block-2-right-advantage-delimiter"></div>
                            </div>
                        )
                    })}                    
                </div>
            </div>
        </div>
    )
}

export default MainBlock2;
