import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import io from 'socket.io-client';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogMessages } from '../../actions/dialog';
import { API_URL } from '../../constants/api';
import ChatMessages from '../Chat/ChatMessages';
import ChatForm from '../Chat/ChatForm';

const socket = io(API_URL, {
  auth: { token: localStorage.getItem('token') },
  withCredentials: true
});

const DialogChat = ({ dialogId, authUser }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const messages = useSelector((state) => state.dialog.messages);

    const [dialogRoom, setDialogRoom] = useState(null);
    const [roomMessages, setRoomMessages] = useState([]);

	useEffect(() => {
		socket.emit('joinDialog', { dialogId });

		const joinedDialogHandler = async (data) => {
			setDialogRoom(data.room);
			await dispatch(getDialogMessages(data.room));
		}

		const receiveMessageHandler = (data) => {
			const { message } = data;
			setRoomMessages((prevMessages) => [...prevMessages, message]);
		}

		socket.on('joinedDialog', joinedDialogHandler);
		socket.on('receiveMessage', receiveMessageHandler);

		return () => {
			socket.off('joinedDialog', joinedDialogHandler);
			socket.off('receiveMessage', receiveMessageHandler);
		}
	}, []);

	useEffect(() => {
		setRoomMessages(messages.sort((a, b) => a.createdAt > b.createdAt));
	}, [messages]);

	const sendMessage = (message) => {
		if (dialogRoom) {
		    const messageObj = {};
	  		const filePromises = [];

			for (let [key, value] of message.entries()) {
				if (value instanceof File) {
					const filePromise = new Promise((resolve, reject) => {
						const reader = new FileReader();
						reader.onload = () => {
							messageObj[key] = {
								name: value.name,
								type: value.type,
								size: value.size,
								data: reader.result,
							};
							resolve();
						};
						reader.onerror = reject;
						reader.readAsDataURL(value);
					});
					filePromises.push(filePromise);
				} else {
					messageObj[key] = value;
				}
			}

			Promise.all(filePromises).then(() => {
				socket.emit('sendDialogMessage', {
					room: dialogRoom,
					messageObj: { ...messageObj, dialogId },
				});
			}).catch((error) => {
				console.error('Error reading files:', error);
			});
		}
	}

	return (
		<div className="chat dialog-chat w-100">
	        <div className="chat-body w-100 d-flex flex-column">
	        	<ChatMessages messages={roomMessages} authUser={authUser} />
	        	<ChatForm messageSent={sendMessage} />
	        </div>
        </div>
    )
}

export default DialogChat;
