import React, { useState, useEffect } from 'react';
import Popup from './Popup';
import radio_active from '../../assets/icons/radio-active.svg';
import radio_inactive from '../../assets/icons/radio-inactive.svg';

const SelectSubscription = ({ isOpen, periods, planName, className, onConfirm, onCancel }) => {
	const [selectedPeriod, setSelectedPeriod] = useState(null);

	useEffect(() => setSelectedPeriod(periods[0].value), [periods]);

    const handleInputChange = (e) => {
        const { value } = e.target;
        const period = periods.find(item => item.value == value);
        if (period) {
        	setSelectedPeriod(value);
        }
    }

    const popupTitle = <>Оберіть термін підписки "<span className={className}>{planName}</span>"</>;

    const popupForm = (
    	<div className="select-subscription-form d-flex flex-column">
    		{periods.map((period, index) => {
    			const { label, value } = period
    			return (
    				<label className="d-flex align-items-center" htmlFor={`period-${value}`} key={index}>
	                    <input
	                        id={`period-${value}`}
	                        type="radio"
	                        name="period"
	                        value={value}
	                        checked={selectedPeriod == value}
	                        onChange={handleInputChange}
	                    />
	                    <img src={selectedPeriod == value ? radio_active : radio_inactive} alt="" />
	                    <span>{label}</span>
	                </label>
    			)
    		})}
    	</div>
    );

    const handleConfirmClick = () => {
    	onConfirm(selectedPeriod);
    }

    return (
        <Popup
            className="select-subscription"
            isOpen={isOpen}
            onConfirm={handleConfirmClick}
            onCancel={onCancel}
            popupTitle={popupTitle}
            popupForm={popupForm}
            buttonClass="btn-small"
            buttonText="Далі"
        />
    )
}

export default SelectSubscription;
