import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlans, createSubscription } from '../../actions/subscription';
import './styles.scss';
import crossLine from '../../assets/icons/price-cross-line.svg';
import SelectSubscription from '../Popup/SelectSubscription';

const SubscriptionPlansTable = ({ periods, onCreate, position = 'home' }) => {
    const dispatch = useDispatch();

    const plans = useSelector(state => state.subscription.plans);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getPlans())
        }

        fetchData();
    }, [position]);

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedPlanName, setSelectedPlanName] = useState(null);
    const [selectedPlanClass, setSelectedPlanClass] = useState('');
    const [selectPeriod, setSelectPeriod] = useState(false);

    const selectPlan = (plan) => {
        if (position === 'subscriptions') {
            setSelectedPlan(plan);
            const { name, code } = plan;
            setSelectedPlanName(name);
            if (code === 'unlimited') {
                setSelectedPlanClass('text-red');
            }
            setSelectPeriod(true);
        }
    }

    const onConfirm = async (period) => {
        if (selectedPlan) {
            const { id: planId, pricings } = selectedPlan;
            const pricing = pricings.find(item => item.period == period);
            if (pricing) {
                const payload = { planId, pricingId: pricing.id };
                const result = await dispatch(createSubscription(payload));
                if (result) {
                    setSelectPeriod(false);
                    onCreate();
                }
            }            
        }
    }

    const onCancel = () => {
        setSelectedPlanName(null);
        setSelectedPlanClass('');
        setSelectPeriod(false);
    }

    return (
        <>
        <div className={`subscriptions-plans-table subscriptions-plans-table-${position} d-flex justify-content-center`}>
            <div className="subscriptions-plans-table-captions d-flex flex-column">
                <div className="subscriptions-plans-table-cell-head subscriptions-plans-table-cell-head-empty"></div>
                <div className="subscriptions-plans-table-cell-pricing d-flex align-items-center">
                    Ціна
                </div>
                <div className="subscriptions-plans-table-cell-products d-flex align-items-center">
                    Кількість товарів
                </div>
                <div className="subscriptions-plans-table-cell-projects d-flex align-items-center">
                    Кількість проектів
                </div>
                <div className="subscriptions-plans-table-cell-description d-flex align-items-center">
                    Опис
                </div>
            </div>
            {plans.map((plan, planIndex) => {
                const { name, code, pricings, description } = plan;
                const products = plan.products ? `${plan.products} товарів` : 'Безліч';
                const projects = plan.projects ? `${plan.projects} проектів` : 'Безліч';
                return (
                  <div className={`subscriptions-plans-table-column subscriptions-plans-table-column-${code} d-flex flex-column`} key={planIndex} onClick={() => selectPlan(plan)}>
                    <div className="subscriptions-plans-table-cell-head d-flex justify-content-center align-items-center"><span className={code === 'unlimited' ? 'text-red' : ''}>{name}</span>&nbsp;Subscription</div>
                    <div className="subscriptions-plans-table-cell-pricing d-flex flex-column justify-content-center align-items-center">
                        {pricings.map((item, priceIndex) => {
                            const { period, oldPrice, price } = item;
                            const periodLabel = periods.find(item => item.value === period).label
                            return (
                                <div className="subscriptions-plans-table-cell-price d-flex align-items-center" key={priceIndex}>
                                    {oldPrice 
                                        ? <>
                                            <div className="subscriptions-plans-table-cell-price-old relative">
                                                {oldPrice} ₴
                                                <img className="absolute" src={crossLine} />
                                            </div>
                                            <div className="subscriptions-plans-table-cell-price-new text-red">{price} ₴</div>
                                        </>
                                        : <div className="subscriptions-plans-table-cell-price-new">{price} ₴</div>
                                    }
                                    <div className="subscriptions-plans-table-cell-price-period">за {periodLabel}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="subscriptions-plans-table-cell-products d-flex flex-column justify-content-center align-items-center">
                        {products}
                    </div>
                    <div className="subscriptions-plans-table-cell-projects d-flex flex-column justify-content-center align-items-center">
                        {projects}
                    </div>
                    <div className="subscriptions-plans-table-cell-description d-flex flex-column justify-content-start align-items-center text-center">
                        {description}
                    </div>
                  </div>
                )
            })}
        </div>
        <SelectSubscription
            isOpen={selectPeriod}
            periods={periods}
            planName={selectedPlanName}
            className={selectedPlanClass}
            onConfirm={onConfirm}
            onCancel={onCancel}
        />
        </>
    )
}

export default SubscriptionPlansTable;
