import { Link, useLocation, useNavigate } from 'react-router-dom';
import { API_URL } from '../../constants/api';
import userDefault from '../../assets/images/user-default.png';
import attachmentIcon from '../../assets/icons/attachment.svg';

const ChatMessage = ({ message, isOwn }) => {
	const { senderId, sender, text, createdAt, attachments } = message;
	const { firstname, lastname } = sender.profile;

	const messageTypeClass = `chat-message-${isOwn ? 'outbox' : 'inbox'}`;
	const messageJustifyClass = `justify-content-${isOwn ? 'end' : 'start'}`

	const isImage = (type) => {
		return type.includes('image/');
	}

	const getFileUrl = (path) => {
		if (path.includes('https://')) return path;
		else return `${API_URL}/${path}`;
	}

	const avatar = sender.avatars[0] ? getFileUrl(sender.avatars[0].path) : userDefault;

	const sortAttachments = (attachments) => {
		const imageAttachments = attachments
			.filter(attachment => attachment.type.startsWith('image/'))
			.sort((a, b) => a.order - b.order);

		const otherAttachments = attachments
			.filter(attachment => !attachment.type.startsWith('image/'))
			.sort((a, b) => a.order - b.order);

		return [...imageAttachments, ...otherAttachments];
	}

	const formatFileSize = (bytes) => {
	    if (bytes === 0) return '0 Б';
	    const k = 1024;
	    const sizes = ['Б', 'кБ', 'МБ', 'ГБ', 'ТБ'];
	    const i = Math.floor(Math.log(bytes) / Math.log(k));
	    const size = bytes / Math.pow(k, i);
	    return `${size.toFixed(1)} ${sizes[i]}`;
	}

	const formatText = (text) => {
		const textParts = text.split('</br>');
		return textParts.map((part, index) => <div key={index}>{part}</div>);
	}

	return (
		<div className={`chat-message-wrap ${messageJustifyClass}`}>
			<div className={`chat-message ${messageTypeClass} d-flex align-items-end`}>
				<div className="chat-message-avatar">
					<img className="w-100 h-100" src={avatar} alt="" />
				</div>
				<div className="chat-message-content d-flex relative">
					<div className="chat-message-content-main w-100 flex-1 d-flex flex-column relative">
						<div className="chat-message-content-main-top absolute"></div>
						<div className="chat-message-content-main-middle absolute"></div>
						<div className="chat-message-content-main-bottom absolute"></div>
						{!isOwn && <div className="chat-message-content-main-sender">{`${firstname} ${lastname}`}</div>}
						{attachments.length !== 0 && 
							<div className="chat-message-content-main-attachments d-flex flex-column">
								{sortAttachments(attachments).map((attachment, index) => {
									const { name, type, size, path } = attachment;
									return (
										<div
											className="chat-message-content-main-attachments-item d-flex align-items-center"
											key={index}
										>
											{isImage(type) 
												? <img className="w-100" src={getFileUrl(path)} alt="" />
												: <>
													<div className="chat-message-content-main-attachments-item-icon">
														<img className="w-100" src={attachmentIcon} alt="" />
													</div>
													<Link to={getFileUrl(path)} target="_blank" className="chat-message-content-main-attachments-item-name w-100 flex-1">
														{name}
													</Link>
													<div className="chat-message-content-main-attachments-item-size">
														{formatFileSize(size)}
													</div>
												</>
											}
										</div>
									)
								})}
							</div>
						}
						{text && <div className="chat-message-content-main-text">{formatText(text)}</div>}
					</div>
					<div className="chat-message-content-tail"></div>
				</div>
				<div className="chat-message-date">{
					new Intl.DateTimeFormat('uk-UA', {
	                    hour: "numeric",
	                    minute: "numeric",
	                    timeZone: 'Europe/Kyiv',
	                }).format(new Date(createdAt))
				}</div>
			</div>
			
		</div>
	)
}

export default ChatMessage;
