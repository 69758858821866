import CatalogService from '../services/catalog.service';

export const GET_CATALOG_REQUEST = 'GET_CATALOG_REQUEST';
export const GET_CATALOG_SUCCESS = 'GET_CATALOG_SUCCESS';
export const GET_CATALOG_FAILURE = 'GET_CATALOG_FAILURE';
export const GET_CATEGORIES_FOR_REGISTERED_REQUEST = 'GET_CATEGORIES_FOR_REGISTERED_REQUEST';
export const GET_CATEGORIES_FOR_REGISTERED_SUCCESS = 'GET_CATEGORIES_FOR_REGISTERED_SUCCESS';
export const GET_CATEGORIES_FOR_REGISTERED_FAILURE = 'GET_CATEGORIES_FOR_REGISTERED_FAILURE';
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';
export const GET_CATALOG_TREE_REQUEST = 'GET_CATALOG_TREE_REQUEST';
export const GET_CATALOG_TREE_SUCCESS = 'GET_CATALOG_TREE_SUCCESS';
export const GET_CATALOG_TREE_FAILURE = 'GET_CATALOG_TREE_FAILURE';

export const getCatalogRequest = () => ({ type: GET_CATALOG_REQUEST });
export const getCatalogSuccess = (data) => ({ type: GET_CATALOG_SUCCESS, payload: data });
export const getCatalogFailure = (error) => ({ type: GET_CATALOG_FAILURE, payload: error });

export const getCategoriesForRegisteredRequest = () => ({ type: GET_CATEGORIES_FOR_REGISTERED_REQUEST });
export const getCategoriesForRegisteredSuccess = (data) => ({ type: GET_CATEGORIES_FOR_REGISTERED_SUCCESS, payload: data });
export const getCategoriesForRegisteredFailure = (error) => ({ type: GET_CATEGORIES_FOR_REGISTERED_FAILURE, payload: error });

export const getCategoryRequest = () => ({ type: GET_CATEGORY_REQUEST });
export const getCategorySuccess = (data) => ({ type: GET_CATEGORY_SUCCESS, payload: data });
export const getCategoryFailure = (error) => ({ type: GET_CATEGORY_FAILURE, payload: error });

export const getCatalogTreeRequest = () => ({ type: GET_CATALOG_TREE_REQUEST });
export const getCatalogTreeSuccess = (data) => ({ type: GET_CATALOG_TREE_SUCCESS, payload: data });
export const getCatalogTreeFailure = (error) => ({ type: GET_CATALOG_TREE_FAILURE, payload: error });

export const getCatalog = (catalogType) => {
	return async (dispatch) => {
		dispatch(getCatalogRequest());
		const catalog = await CatalogService.getCatalog(catalogType);
		dispatch(getCatalogSuccess(catalog));
		return catalog;
	}
}

export const getCategoriesForRegistered = () => async (dispatch) => {
  try {
    dispatch(getCategoriesForRegisteredRequest());
    const categories = await CatalogService.getCategoriesForRegistered();
    console.log(categories)
    dispatch(getCategoriesForRegisteredSuccess(categories));
    return categories;
  } catch (e) {
    dispatch(getCategoriesForRegisteredFailure(e.toString()));
    return []
  }
}

export const getCategory = (path) => async (dispatch) => {
  try {
    dispatch(getCategoryRequest());
    const result = await CatalogService.getCategory(path);
    console.log(result)
    if (result.success) {
      dispatch(getCategorySuccess(result.category));
      return result.category
    }
    else {
      dispatch(getCategoryFailure(result.message));
      return false
    }
  } catch (e) {
    dispatch(getCategoryFailure(e.toString()));
    return false
  }
}

export const getCatalogTree = () => async (dispatch) => {
  try {
    dispatch(getCatalogTreeRequest());
    const result = await CatalogService.getCatalogTree();
    console.log(result)
    if (result.success) {
      dispatch(getCatalogTreeSuccess(result.catalogTree));
      return result.catalogTree
    }
    else {
      dispatch(getCatalogTreeFailure(result.message));
      return null
    }
  } catch (e) {
    dispatch(getCatalogTreeFailure(e.toString()));
    return null
  }
}
