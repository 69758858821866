import PaymentService from '../services/payment.service';

export const GET_EASYPAY_APP_ID_SUCCESS = 'GET_EASYPAY_APP_ID_SUCCESS';
export const GET_EASYPAY_APP_ID_FAILURE = 'GET_EASYPAY_APP_ID_FAILURE';
export const GET_EASYPAY_PAGE_ID_SUCCESS = 'GET_EASYPAY_PAGE_ID_SUCCESS';
export const GET_EASYPAY_PAGE_ID_FAILURE = 'GET_EASYPAY_PAGE_ID_FAILURE';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE';

export const getEasypayAppIdSuccess = (data) => ({ type: GET_EASYPAY_APP_ID_SUCCESS, payload: data });
export const getEasypayAppIdFailure = (error) => ({ type: GET_EASYPAY_APP_ID_FAILURE, payload: error });
export const getEasypayPageIdSuccess = (data) => ({ type: GET_EASYPAY_PAGE_ID_SUCCESS, payload: data });
export const getEasypayPageIdFailure = (error) => ({ type: GET_EASYPAY_PAGE_ID_FAILURE, payload: error });
export const createPaymentSuccess = (data) => ({ type: CREATE_PAYMENT_SUCCESS, payload: data });
export const createPaymentFailure = (error) => ({ type: CREATE_PAYMENT_FAILURE, payload: error });

export const getEasypayAppId = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const easypayAppId = localStorage.getItem('easypay-app-id');
    if (!easypayAppId) {
      const result = await PaymentService.getEasypayAppId(token);
      console.log(result)
      if (result.success) {
        localStorage.setItem('easypay-app-id', result.appId);
        dispatch(getEasypayAppIdSuccess(result.appId));
        return result.appId;
      }
      else {
        dispatch(getEasypayAppIdFailure(result.message));
        return null;
      }
    }
    dispatch(getEasypayAppIdSuccess(easypayAppId));
    return easypayAppId;
  } catch (e) {
    dispatch(getEasypayAppIdFailure(e.toString()));
    return null;
  }
}

export const getEasypayPageId = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const easypayAppId = localStorage.getItem('easypay-app-id');
    if (easypayAppId) {
      const result = await PaymentService.getEasypayPageId({ appId: easypayAppId }, token);
      console.log(result)
      if (result.success) {
        dispatch(getEasypayPageIdSuccess(result.pageId));
        return result.pageId;
      }
      else {
        dispatch(getEasypayPageIdFailure(result.message));
        return null;
      }
    }
    dispatch(getEasypayPageIdFailure('No AppId provided'));
    return null;
  } catch (e) {
    dispatch(getEasypayPageIdFailure(e.toString()));
    return null;
  }
}

export const createPayment = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    // const easypayAppId = localStorage.getItem('easypay-app-id');
    // if (easypayAppId) {
      // const result = await PaymentService.createPayment({ ...payload, appId: easypayAppId }, token);
      const result = await PaymentService.createPayment(payload, token);
      console.log(result)
      if (result.success) {
        dispatch(createPaymentSuccess(result.payment));
        return result.payment;
      }
      else {
        dispatch(createPaymentFailure(result.message));
        return null;
      }
    // }
    // dispatch(createPaymentFailure('No AppId provided'));
    // return null;
  } catch (e) {
    dispatch(createPaymentFailure(e.toString()));
    return null;
  }
}
