import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomPromotion from './BottomPromotion';
import SubscriptionPlansTable from '../Subscription/SubscriptionPlansTable';
import logo from '../../assets/images/logo-simple.png';
import arrowRight from '../../assets/icons/arrow-right.svg';
import NeedRegister from '../Popup/NeedRegister';

const MainBlock5 = () => {
    const navigate = useNavigate();

    const periods = [
        { label: '1 місяць', value: 1 },
        { label: '3 місяці', value: 3 },
        { label: '6 місяців', value: 6 },
        { label: '1 рік', value: 12 },
    ];

    const onCreate = () => {

    }

    const [registerPopupOpen, setRegisterPopupOpen] = useState(false);

    const openRegisterPopup = () => {
        setRegisterPopupOpen(true);
    }

    const handleCancelRegister = () => {
        setRegisterPopupOpen(false);
    }

    return (
        <div className="main-block main-block-5 d-flex flex-column align-items-center">
            <div className="main-block-5-wrap container d-flex flex-column align-items-center">
                <div className="main-block-5-top d-flex flex-column align-items-center">
                    <img className="main-block-5-top-logo" src={logo} alt="" />
                    <BottomPromotion />
                </div>
                <div className="main-block-5-bottom d-flex flex-column">
                    <SubscriptionPlansTable periods={periods} onCreate={onCreate} />
                    <div className="main-block-5-bottom-button d-flex justify-content-end">
                        <button className="btn-medium btn-white" onClick={openRegisterPopup}>
                            <span>Перейти до оформлення</span>
                            <img className="btn-icon-arrow" src={arrowRight} alt="" />
                        </button>
                    </div>
                </div>
            </div>
            <NeedRegister
                isOpen={registerPopupOpen}
                onCancel={handleCancelRegister}
            />
        </div>
    )
}

export default MainBlock5;
