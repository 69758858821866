import React, { useEffect } from 'react';
import './styles.scss';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCatalog } from '../../actions/catalog';
import CatalogTop from './CatalogTop';
import technics from '../../assets/images/technics-heading.png';
import decor from '../../assets/images/decor-heading.png';

const CatalogPart = ({ catalog, getCatalog }) => {
    const location = useLocation();
    const catalogType = location.pathname.replace('/catalog/', '');

    useEffect(() => {
        getCatalog(catalogType);
    }, [getCatalog, catalogType]);

    let bgStyle;
    if (catalogType === 'technics') bgStyle = { background: `url(${technics})` };
    if (catalogType === 'decor') bgStyle = { background: `url(${decor})` };

    return (
        <>
            <CatalogTop back="/catalog" sort={false} />
            <div className="catalog catalog-part">
                <div style={bgStyle} className="catalog-part-title w-100 d-flex justify-content-center align-items-center">
                    {catalog.title}
                </div>
                {catalog.categories && <div className={`catalog-part-categories catalog-part-categories-${catalogType} container d-flex flex-wrap`}>
                    {catalog.categories.map((category, index) => {
                        const { title, link, bg, cols, mcols } = category;
                        const image = require(`../../assets/images/${bg}`);
                        const catStyle = { background: `url(${image})`, backgroundSize: 'cover' };
                        return (
                            <Link to={link} style={catStyle} className={`catalog-part-category catalog-part-category-cols-${cols} catalog-part-category-mcols-${mcols} d-flex justify-content-center align-items-center`} key={index}>
                                {title}
                            </Link>
                        )
                    })}                    
                </div>}
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
  catalog: state.catalog.data,
  loading: state.catalog.loading,
  error: state.catalog.error,
});

export default connect(mapStateToProps, { getCatalog })(CatalogPart);
