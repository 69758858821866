import * as yup from 'yup';
import * as C from '../constants/project';
import schema from './schema';

export const clientSchema = schema({
  client: { type: 'string', label: C.CLIENT, rules: { required: true } }
})
export const eventTypeSchema = schema({
  eventType: { type: 'string', label: C.EVENT_TYPE, rules: { required: true } }
})
export const descriptionSchema = schema({
  description: { type: 'string', label: C.DESCRIPTION, rules: { required: true } }
})
export const budgetSchema = schema({
  budget: { type: 'string', label: C.BUDGET, rules: { required: true } }
})
export const guestsSchema = schema({
  guests: { type: 'string', label: C.GUESTS, rules: { required: true } }
})
export const startDatetimeSchema = schema({
  startDatetime: { type: 'string', label: C.START_DATETIME, rules: { required: true } }
})
export const receiveDateSchema = schema({
  receiveDate: { type: 'string', label: C.RECEIVE_DATE, rules: { required: true } }
})
export const returnDateSchema = schema({
  returnDate: { type: 'string', label: C.RETURN_DATE, rules: { required: true } }
})
export const endDatetimeSchema = schema({
  endDatetime: { type: 'string', label: C.END_DATETIME, rules: { required: true } }
})
export const locationSchema = schema({
  location: { type: 'string', label: C.LOCATION, rules: { required: true } }
})
