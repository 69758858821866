import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptions } from '../../actions/subscription';
import { createPayment } from '../../actions/payment';
import './styles.scss';
import Breadcrumbs from '../Common/Breadcrumbs';
import SubscriptionPlansTable from './SubscriptionPlansTable';
import SubscriptionsFeatures from './SubscriptionsFeatures';
import SubscriptionsTable from './SubscriptionsTable';
import arrowRight from '../../assets/icons/arrow-white-right.svg';

const Subscriptions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const subscriptionsLoading = useSelector(state => state.subscription.subscriptionsLoading);
    const subscriptions = useSelector(state => state.subscription.subscriptions);

    const [hasActualSubscriptions, setHasActualSubscriptions] = useState(false);

    const periods = [
        { label: '1 місяць', value: 1 },
        { label: '3 місяці', value: 3 },
        { label: '6 місяців', value: 6 },
        { label: '1 рік', value: 12 },
    ];

    useEffect(() => {
        const fetchData = async () => {
            if (authUser) {
                dispatch(getSubscriptions());
            } else if (!userLoading) {
                navigate('/');
            }
        }

        fetchData();
    }, [authUser, userLoading, dispatch]);

    useEffect(() => {
        if (!subscriptionsLoading) {
            console.log('subscriptions', subscriptions);
            const actualSubscriptions = subscriptions.filter(
                item => item.status == 'new' || item.status == 'active'
            );
            setHasActualSubscriptions(actualSubscriptions.length !== 0);
        }
    }, [subscriptionsLoading, subscriptions]);

    const onCreate = () => {
        dispatch(getSubscriptions());
    }

    const onDelete = () => {
        dispatch(getSubscriptions());
    }

    const handlePayClick = async () => {
        const payment = await dispatch(createPayment({ service: 'easypay' }));
        if (payment && payment.paymentUrl) {
            window.location.replace(payment.paymentUrl);
        }
    }

    return (
        <div className="subscriptions container">
            <Breadcrumbs link="/" />
            {!hasActualSubscriptions && <div className="subscriptions-body w-100 d-flex flex-column align-items-center">
                <div className="subscriptions-body-first d-flex flex-column align-items-end">
                    <SubscriptionPlansTable periods={periods} onCreate={onCreate} position="subscriptions" />
                    {/*<button className="btn-medium btn-black">
                        <span>Перейти до оформлення</span>
                        <img className="btn-icon-arrow" src={arrowRight} alt="" />
                    </button>*/}
                </div>
                <div className="subscriptions-body-second d-flex flex-column align-items-center">
                    <SubscriptionsFeatures />
                    {/*<button className="btn-medium btn-black">
                        <span>Дивитись тарифи</span>
                        <img className="btn-icon-arrow" src={arrowRight} alt="" />
                    </button>*/}
                </div>
            </div>}
            {hasActualSubscriptions && <div className="subscriptions-body w-100 d-flex flex-column align-items-center">
                <div className="subscriptions-body-first d-flex flex-column align-items-end">
                    <SubscriptionsTable subscriptions={subscriptions} periods={periods} onDelete={onDelete} />
                    <button className="btn-small btn-black" onClick={handlePayClick}>
                        <span>Оплатити</span>
                    </button>
                </div>
            </div>}
        </div>
    )
}

export default Subscriptions;
