import React from 'react';
import { Link } from 'react-router-dom';
import arrowRight from '../../assets/icons/arrow-right.svg';

const BannerBottom = () => {
    return (
        <div className="banner-bottom w-100">
            <div className="banner-bottom-wrap h-100 container d-flex flex-column justify-content-center align-items-start">
                <div className="banner-bottom-title text-white">
                    Можливо, ваші конкуренти вже тут? Вам дуже сильно пощастить, якщо їх немає у нашому каталозі, тому що він стає все більшим і більшим
                </div>
                <div className="banner-bottom-subtitle text-white">
                    Тому ми закликаємо вас зареєструватись вже сьогодні, щоб бути на декілька кроків вперед від конкурентів.
                </div>
                <Link to="/catalog" className="btn btn-medium btn-white">
                    <span>Перейти до каталогу</span>
                    <img className="btn-icon-arrow" src={arrowRight} alt="" />
                </Link>
            </div>
        </div>
    )
}

export default BannerBottom;
