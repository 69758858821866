import React from 'react';
import './styles.scss';
import Viber from '../../assets/icons/Viber.svg';
import Instagram from '../../assets/icons/Instagram.svg';
import Whatsapp from '../../assets/icons/Whatsapp.svg';
import Telegram from '../../assets/icons/Telegram.svg';

const ProfileSocials = ({ profileData }) => {
	return (
        <div className="profile-data-socials d-flex align-items-center">
            {profileData.viber && 
                <a
                    href={`viber://chat?number=%2B${profileData.viber}`}
                    target="_blank"
                    className="profile-data-socials-item"
                >
                    <img className="w-100" src={Viber} alt="" />
                </a>
            }
            {profileData.instagram &&
                <a
                    href={`https://instagram.com/${profileData.instagram.replace('@', '')}`}
                    target="_blank"
                    className="profile-data-socials-item"
                >
                    <img className="w-100" src={Instagram} alt="" />
                </a>
            }
            {profileData.whatsapp && 
                <a
                    href={`https://wa.me/${profileData.whatsapp}`}
                    target="_blank"
                    className="profile-data-socials-item"
                >
                    <img className="w-100" src={Whatsapp} alt="" />
                </a>
            }
            {profileData.telegram &&
                <a
                    href={`https://t.me/${profileData.telegram.replace('@', '')}`}
                    target="_blank"
                    className="profile-data-socials-item"
                >
                    <img className="w-100" src={Telegram} alt="" />
                </a>
            }
        </div>
    )
}

export default ProfileSocials;
