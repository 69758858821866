export const months = [
	'Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'
]

const daysOfWeek = [
	{ name: 'Пн', num: 1 },
	{ name: 'Вт', num: 2 },
	{ name: 'Ср', num: 3 },
	{ name: 'Чт', num: 4 },
	{ name: 'Пт', num: 5 },
	{ name: 'Сб', num: 6 },
	{ name: 'Нд', num: 0 },
]

const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate()
}

export const getCalendar = (monthNum, year) => {
	let daysCount = daysInMonth(monthNum + 1, year)
	let days = []
	for (let d = 1; d <= daysCount; d++) {
		let date = new Date(year, monthNum, d)
		let dayOfWeek = daysOfWeek.find(item => item.num == date.getDay())
		days.push({ num: date.getDate(), day: dayOfWeek })
	}
	let dayNum = 1
	let calendar = []
	let week = []
	while (dayNum <= daysCount) {
		let dayItem = days.find(item => item.num == dayNum)
		for (let dayOfWeek of daysOfWeek) {
			if (dayItem && dayItem.day.name == dayOfWeek.name) {
				let monthNumString = (monthNum + 1) < 10 ? `0${monthNum + 1}` : `${monthNum + 1}`
				let dayNumString = dayItem.num < 10 ? `0${dayItem.num}` : `${dayItem.num}`
				week.push({ day: dayItem.num, date: `${year}-${monthNumString}-${dayNumString}` })
				dayNum++
				dayItem = days.find(item => item.num == dayNum)
			} else {
				week.push({ day: '', date: null })
			}
		}
		calendar.push(week)
		week = []
	}
	// console.log(calendar)
	return calendar
}

export const getDatesInRange = (startDate, endDate) => {
    const dateArray = []
    let currentDate = new Date(startDate)

    while (currentDate <= new Date(endDate)) {
        dateArray.push(new Date(currentDate))
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return dateArray
}
