import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMyOrders } from '../../actions/order';
import { orderStatuses } from '../../constants/order';
import Breadcrumbs from '../Common/Breadcrumbs';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';
import arrow from '../../assets/icons/chevron-down.svg';
import plus from '../../assets/icons/plus-white.svg';
import search from '../../assets/icons/search.svg';

const Orders = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const myOrders = useSelector((state) => state.order.orders);

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (authUser) {
                await dispatch(getMyOrders())
            } else if (!userLoading) {
                navigate('/');
            }
        }

        fetchData();
    }, [authUser, userLoading]);

    const showOrders = (orders) => {
        setOrders(orders.filter(order => order.status !== 'new'));
        setPage(0);
    }

    useEffect(() => {
        if (myOrders) {
            showOrders(myOrders);
        }
    }, [myOrders]);

    const [sortMenuOpen, setSortMenuOpen] = useState(false);
    const sortMenuRef = useRef();

    const [sortText, setSortText] = useState('Сортувати за');

    const sortOptions = [
        { menuText: 'Ціною (спадання)', fieldText: 'За ціною (↑)', param: 'rentalPrice', desc: true },
        { menuText: 'Ціною (зростання)', fieldText: 'За ціною (↓)', param: 'rentalPrice', desc: false },
    ];

    useEffect(() => {
        setSortMenuOpen(false);
        selectSortOption(null)
    }, [location.pathname]);

    const handleClickOutsideMenu = (event) => {
        if (sortMenuRef.current && !sortMenuRef.current.contains(event.target)) {
            setSortMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideMenu);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideMenu);
        }
    }, []);

    const selectSortOption = (option) => {
        if (option) {
            const { fieldText, param, desc } = option;
            if (myOrders) {
                setSortText(fieldText)
                const sortedOrders = myOrders.sort((a, b) => {
                    if (a.product.features[param] === b.product.features[param]) return 0;
                    if (desc) {
                        return a.product.features[param] > b.product.features[param] ? -1 : 1;
                    } else {
                        return a.product.features[param] < b.product.features[param] ? -1 : 1;
                    }
                });
                showOrders(sortedOrders);
            }
        } else {
            setSortText('Сортувати за')
        }
    }

    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchKeyPress = (e) => {
        if (e.key === 'Enter') {
            goSearch()
        }
    }

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    }

    const goSearch = () => {
        if (searchQuery && myOrders) {
            const foundOrders = myOrders.filter(order => {
                const { firstname, lastname } = order.customer.profile;
                return firstname.toLowerCase().includes(searchQuery.toLowerCase()) || lastname.toLowerCase().includes(searchQuery.toLowerCase());
            });
            showOrders(foundOrders);
        }
    }

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from}–${to} з ${count !== -1 ? count : `більше ніж ${to}`}`;
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return (
        <div className="orders container">
            <Breadcrumbs link="/" />
            <div className="orders-body w-100 d-flex flex-column align-items-center">
                <div className="catalog-top-actions w-100 m-0 d-flex justify-content-end align-items-center">
                    <div className="catalog-top-actions-sort catalog-sort m-0 d-flex justify-content-center align-items-center">
                        <div className="catalog-sort-dropdown d-flex align-items-center relative" onClick={() => setSortMenuOpen(!sortMenuOpen)}>
                            <span className="catalog-sort-dropdown-text">{sortText}</span>
                            <img src={arrow} className="catalog-sort-dropdown-icon" alt="" />
                            {sortMenuOpen && <div ref={sortMenuRef} className="catalog-sort-dropdown-menu d-flex flex-column absolute">
                                {sortOptions.map((option, index) => {
                                    return (
                                        <div
                                            className="catalog-sort-dropdown-menu-item d-flex justify-content-center align-items-center"
                                            key={index}
                                            onClick={() => selectSortOption(option)}
                                        >{option.menuText}</div>
                                    )
                                })}
                            </div>}
                        </div>
                    </div>
                    <div className="catalog-top-actions-search">
                        <input
                            className="catalog-top-actions-search-field"
                            placeholder="Пошук по імені замовника"
                            onChange={handleSearchChange}
                            onKeyPress={handleSearchKeyPress}
                        />
                        <img src={search} className="catalog-top-actions-search-icon" alt="" onClick={goSearch} />                
                    </div>
                </div>
                <div className="orders-buttons w-100 d-flex justify-content-end align-items-center">
                    <Link to={`/order/create`} className="btn btn-medium btn-black">
                        <img className="btn-icon-plus" src={plus} alt="" />
                        <span>Додати замовлення вручну</span>
                    </Link>
                </div>
                {orders.length !== 0 && <>
                    <Table className="projects-table w-100">
                        <TableHead>
                            <TableRow className="projects-table-row">
                                <TableCell className="projects-table-cell">Дата проекту</TableCell>
                                <TableCell className="projects-table-cell">Замовник</TableCell>
                                <TableCell className="projects-table-cell">Опис замовлення</TableCell>
                                <TableCell className="projects-table-cell">Номер замовлення</TableCell>
                                <TableCell className="projects-table-cell">Статус</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index) => {
                            	const { firstname, lastname } = order.customer ? order.customer.profile : order.info;
                            	const { eventType, location } = order.project || order.info;
                                const startDatetime = order.project ? order.project.startDatetime : order.receiveDate;
                                return (
                                    <TableRow className="projects-table-row" key={index} onClick={() => navigate(`/order/${order.slug}`)}>
                                        <TableCell className="projects-table-cell">{
                                        	new Intl.DateTimeFormat('uk-UA', {
                                                day: "numeric",
                                                month: "numeric",
                                                year: "numeric",
                                                timeZone: 'Europe/Kyiv',
                                            }).format(new Date(startDatetime))
                                        }</TableCell>
                                        <TableCell className="projects-table-cell">{`${firstname} ${lastname}`}</TableCell>
                                        <TableCell className="projects-table-cell">{`${eventType} ${location}`}</TableCell>
                                        <TableCell className="projects-table-cell">{
                                        	order.receiveDate ? new Intl.DateTimeFormat('uk-UA', {
                                                day: "numeric",
                                                month: "numeric",
                                                year: "numeric",
                                                timeZone: 'Europe/Kyiv',
                                            }).format(new Date(order.receiveDate)) : ''
                                        }</TableCell>
                                		<TableCell className="projects-table-cell">{orderStatuses[order.status]}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="Показувати на сторінці"
                        rowsPerPageOptions={[5, 10, 25, { label: 'Всі', value: -1 }]}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        component="div"
                        count={orders.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
            </div>
        </div>
    )
}

export default Orders;
