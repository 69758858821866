import SubscriptionService from '../services/subscription.service';

export const GET_PLANS_REQUEST = 'GET_PLANS_REQUEST';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_FAILURE = 'GET_PLANS_FAILURE';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';
export const GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_FAILURE = 'GET_SUBSCRIPTIONS_FAILURE';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_FAILURE = 'DELETE_SUBSCRIPTION_FAILURE';

export const getPlansRequest = () => ({ type: GET_PLANS_REQUEST });
export const getPlansSuccess = (data) => ({ type: GET_PLANS_SUCCESS, payload: data });
export const getPlansFailure = (error) => ({ type: GET_PLANS_FAILURE, payload: error });
export const createSubscriptionSuccess = () => ({ type: CREATE_SUBSCRIPTION_SUCCESS });
export const createSubscriptionFailure = (error) => ({ type: CREATE_SUBSCRIPTION_FAILURE, payload: error });
export const getSubscriptionsRequest = () => ({ type: GET_SUBSCRIPTIONS_REQUEST });
export const getSubscriptionsSuccess = (data) => ({ type: GET_SUBSCRIPTIONS_SUCCESS, payload: data });
export const getSubscriptionsFailure = (error) => ({ type: GET_SUBSCRIPTIONS_FAILURE, payload: error });
export const updateSubscriptionSuccess = () => ({ type: UPDATE_SUBSCRIPTION_SUCCESS });
export const updateSubscriptionFailure = (error) => ({ type: UPDATE_SUBSCRIPTION_FAILURE, payload: error });
export const deleteSubscriptionSuccess = () => ({ type: DELETE_SUBSCRIPTION_SUCCESS });
export const deleteSubscriptionFailure = (error) => ({ type: DELETE_SUBSCRIPTION_FAILURE, payload: error });

export const getPlans = () => async (dispatch) => {
  try {
    dispatch(getPlansRequest());
    const result = await SubscriptionService.getPlans();
    console.log(result)
    if (result.success) {
      dispatch(getPlansSuccess(result.plans));
      return result.plans;
    }
    else {
      dispatch(getPlansFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(getPlansFailure(e.toString()));
    return null;
  }
}

export const createSubscription = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await SubscriptionService.createSubscription(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(createSubscriptionSuccess());
      return result.subscription;
    }
    else {
      dispatch(createSubscriptionFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(createSubscriptionFailure(e.toString()));
    return null;
  }
}

export const getSubscriptions = () => async (dispatch) => {
  try {
    dispatch(getSubscriptionsRequest());
    const token = localStorage.getItem('token');
    const result = await SubscriptionService.getSubscriptions(token);
    console.log(result)
    if (result.success) {
      dispatch(getSubscriptionsSuccess(result.subscriptions));
    }
    else {
      dispatch(getSubscriptionsFailure(result.message));
    }
  } catch (e) {
    dispatch(getSubscriptionsFailure(e.toString()));
  }
}

export const updateSubscription = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await SubscriptionService.updateSubscription(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(updateSubscriptionSuccess());
    }
    else {
      dispatch(updateSubscriptionFailure(result.message));
    }
  } catch (e) {
    dispatch(updateSubscriptionFailure(e.toString()));
  }
}

export const deleteSubscription = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await SubscriptionService.deleteSubscription(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(deleteSubscriptionSuccess());
    }
    else {
      dispatch(deleteSubscriptionFailure(result.message));
    }
  } catch (e) {
    dispatch(deleteSubscriptionFailure(e.toString()));
  }
}
