import AccountService from '../services/account.service';
import { logoutSuccess } from './auth';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PHONE_SUCCESS = 'CHANGE_PHONE_SUCCESS';
export const CHANGE_PHONE_FAILURE = 'CHANGE_PHONE_FAILURE';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAILURE = 'CHANGE_EMAIL_FAILURE';
export const DISCARD_CHANGED = 'DISCARD_CHANGED';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const changePasswordSuccess = (data) => ({ type: CHANGE_PASSWORD_SUCCESS, payload: data });
export const changePasswordFailure = (error) => ({ type: CHANGE_PASSWORD_FAILURE, payload: error });
export const changePhoneSuccess = (data) => ({ type: CHANGE_PHONE_SUCCESS, payload: data });
export const changePhoneFailure = (error) => ({ type: CHANGE_PHONE_FAILURE, payload: error });
export const changeEmailSuccess = (data) => ({ type: CHANGE_EMAIL_SUCCESS, payload: data });
export const changeEmailFailure = (error) => ({ type: CHANGE_EMAIL_FAILURE, payload: error });
export const discardChanged = () => ({ type: DISCARD_CHANGED });
export const deleteAccountSuccess = () => ({ type: DELETE_ACCOUNT_SUCCESS });
export const deleteAccountFailure = (error) => ({ type: DELETE_ACCOUNT_FAILURE, payload: error });

export const changePassword = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await AccountService.changePassword(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(changePasswordSuccess(result));
    }
    else {
      dispatch(changePasswordFailure(result.message));
    }
  } catch (e) {
    dispatch(changePasswordFailure(e.toString()));
  }
}

export const changePhone = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await AccountService.changePhone(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(changePhoneSuccess(result));
    }
    else {
      dispatch(changePhoneFailure(result.message));
    }
  } catch (e) {
    dispatch(changePhoneFailure(e.toString()));
  }
}

export const changeEmail = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await AccountService.changeEmail(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(changeEmailSuccess(result));
    }
    else {
      dispatch(changeEmailFailure(result.message));
    }
  } catch (e) {
    dispatch(changeEmailFailure(e.toString()));
  }
}

export const deleteAccount = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await AccountService.deleteAccount(token);
    console.log(result)
    if (result.success) {
      dispatch(deleteAccountSuccess());
      localStorage.removeItem('token');
      dispatch(logoutSuccess());
    }
    else {
      dispatch(deleteAccountFailure(result.message));
    }
  } catch (e) {
    dispatch(deleteAccountFailure(e.toString()));
  }
}
