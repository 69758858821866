import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { parsePath } from '../../utils/catalog';
import { getCategory } from '../../actions/catalog';
import CatalogTop from './CatalogTop';
import CatalogSort from './CatalogSort';
import CatalogFilters from './CatalogFilters';
import ProductsGrid from '../Product/ProductsGrid';
import technics from '../../assets/images/technics-heading.png';
import decor from '../../assets/images/decor-heading.png';
import { Pagination } from '@mui/material';

const CatalogCategory = ({ category, getCategory }) => {
    const location = useLocation();
    const { path, back } = parsePath(location.pathname, category.catalogType);

    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [sort, setSort] = useState(null);
    const [page, setPage] = useState(1);
    const productsPerPage = 8;
    const topRef = useRef(null);
    
    useEffect(() => {
        getCategory(path);
    }, [getCategory, path]);

    useEffect(() => {
        if (category.products) {
            setProducts([...category.products]);
            setPage(1);
        }
    }, [category.products]);

    useEffect(() => {
        if (sort && products.length > 0) {
            const { param, desc } = sort;
            const sortedProducts = [...products].sort((a, b) => {
                if (a.features[param] === b.features[param]) return 0;
                if (desc) {
                    return a.features[param] > b.features[param] ? -1 : 1;
                } else {
                    return a.features[param] < b.features[param] ? -1 : 1;
                }
            });
            setProducts(sortedProducts);
            setPage(1);
        }
    }, [sort]);

    let bgStyle;
    if (category.catalogType === 'technics') bgStyle = { background: `url(${technics})` };
    if (category.catalogType === 'decor') bgStyle = { background: `url(${decor})` };

    const onFilterSelected = (path) => {
        navigate(path);
    }

    const handleChangePage = (event, value) => {
        setPage(value);
        scrollToTop();
    }

    const scrollToTop = () => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const onSortOptionSelected = (option) => {
        setSort(option);
    }

    return (
        <>
            <CatalogTop back={back} sort={true} onSortOptionSelected={onSortOptionSelected} />
            <div className="catalog catalog-category" ref={topRef}>
                <div style={bgStyle} className="catalog-part-title w-100 d-flex justify-content-center align-items-center">
                    {category.catalogTitle}
                </div>
                <CatalogSort className="catalog-part-mobile-sort" onSortOptionSelected={onSortOptionSelected} />
            	<div className="catalog-category-title container d-flex justify-content-center align-items-center">
            		{category.title}
            	</div>
            	{products && <>
                    <div className="catalog-category-content container d-flex justify-content-between align-items-start">
                		<CatalogFilters category={category} path={path} onSelected={onFilterSelected} />
                        <div className="catalog-category-products d-flex flex-column">
                            <ProductsGrid products={products.slice((page - 1) * productsPerPage, (page - 1) * productsPerPage + productsPerPage)} />
                            <Pagination
                                className="catalog-category-pagination d-flex justify-content-center"
                                count={Math.ceil(products.length / productsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                                color="primary"
                            />
                        </div>
                	</div>
                </>}
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
  category: state.catalog.data,
  loading: state.catalog.loading,
  error: state.catalog.error,
});

export default connect(mapStateToProps, { getCategory })(CatalogCategory);
