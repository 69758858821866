import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectSelected } from '../../actions/project';
import { setOrderInfoSelected } from '../../actions/order';
import pencilWhite from '../../assets/icons/pencil-white.svg';
import plus from '../../assets/icons/plus-white.svg';
import NeedRegister from '../Popup/NeedRegister';

const Buttons = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authUser = useSelector((state) => state.auth.user);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const selectedOrderInfo = useSelector((state) => state.order.selectedOrderInfo);

    const [list, setList] = useState([]);
    const [activeProject, setActiveProject] = useState(null);
    const [activeOrderInfo, setActiveOrderInfo] = useState(null);

    useEffect(() => {
        if (authUser) {
            const projects = Object.values(authUser.projects);
            const orders = Object.values(authUser.ownerOrders);
            setList([
                ...projects.map(project => { return { ...project, type: 'project' } }),
                ...orders
                    .filter(order => order.info)
                    .map(order => {
                        return { ...order.info, type: 'orderInfo' }
                    })
            ]);

            if (selectedProject || selectedOrderInfo) {
                if (selectedProject) {
                    setActiveProject(selectedProject);
                    setActiveOrderInfo(null);
                }
                if (selectedOrderInfo) {
                    setActiveOrderInfo(selectedOrderInfo);
                    setActiveProject(null);
                }
            }
            else {
                if (projects.length !== 0) {
                    setActiveProject(projects.find(project => project.selected));
                }
                if (orders.length !== 0) {
                    setActiveOrderInfo(orders
                                        .filter(order => order.info)
                                        .map(order => order.info)
                                        .find(info => info.selected));
                }
            }
        }
    }, [authUser, selectedProject, location.pathname]);

    const [listMenuOpen, setListMenuOpen] = useState(false);
    const listMenuRef = useRef();

    useEffect(() => {
        setListMenuOpen(false);
    }, [location.pathname]);    

    const handleClickOutsideMenu = (event) => {
        if (listMenuRef.current && !listMenuRef.current.contains(event.target)) {
            setListMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutsideMenu);
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideMenu);
        }
    }, []);

    const handleSelectListMenuClick = () => {
        setListMenuOpen(!listMenuOpen);
    }

    const selectListItem = async (item) => {
        if (item.type == 'project') {
            const selectedProject = await dispatch(setProjectSelected(item.id));
            setActiveOrderInfo(null);
            setActiveProject(selectedProject);
        } else if (item.type == 'orderInfo') {
            const selectedOrderInfo = await dispatch(setOrderInfoSelected(item.order.id));
            setActiveProject(null);
            setActiveOrderInfo(selectedOrderInfo);
        }
        setListMenuOpen(false);
    }

    const [registerPopupOpen, setRegisterPopupOpen] = useState(false);

    const goToCreateProject = () => {
        if (authUser) {
            navigate('/project/create');
        } else {
            setRegisterPopupOpen(true);
        }
    }

    const goToFavorites = () => {
        if (authUser) {
            navigate('/favorites');
        } else {
            setRegisterPopupOpen(true);
        }
    }

    const handleCancelRegister = () => {
        setRegisterPopupOpen(false);
    }

    return (
        <div className="header-buttons d-flex">
            {list.length !== 0 && 
                <div className="header-buttons-project d-flex justify-content-end align-items-center relative">
                    {activeProject && 
                        <Link to={`/project/${activeProject.slug}`} className="header-buttons-project-selected">
                            {`${activeProject.client} - ${activeProject.eventType} - ${new Intl.DateTimeFormat('uk-UA', {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                timeZone: 'Europe/Kyiv',
                            }).format(new Date(activeProject.startDatetime))}`}
                        </Link>
                    }
                    {activeOrderInfo && 
                        <Link to={`/order/${activeOrderInfo.order.slug}`} className="header-buttons-project-selected">
                            {`${activeOrderInfo.firstname} ${activeOrderInfo.lastname} - ${activeOrderInfo.eventType} - ${new Intl.DateTimeFormat('uk-UA', {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                timeZone: 'Europe/Kyiv',
                            }).format(new Date(activeOrderInfo.order.receiveDate))}`}
                        </Link>
                    }
                    {!activeProject && !activeOrderInfo && 
                        <div className="header-buttons-project-none">Виберіть проект...</div>
                    }
                    <button className="btn-xs btn-black" onClick={handleSelectListMenuClick}>
                        <img className="btn-icon-pencil" src={pencilWhite} alt="" />
                    </button>
                    {listMenuOpen && 
                        <div
                            ref={listMenuRef}
                            className="header-buttons-project-menu d-flex flex-column align-items-center absolute"
                        >
                            {list.map((item, index) => {
                                const { id, eventType, type } = item;
                                const client = type == 'project' ? item.client : `${item.firstname} ${item.lastname}`;
                                const startDatetime = type == 'project' ? item.startDatetime : item.order.receiveDate;
                                return (
                                    <div
                                        className="header-buttons-project-menu-item text-center"
                                        key={index}
                                        onClick={() => selectListItem(item)}
                                    >
                                        {`${client} - ${eventType} - ${new Intl.DateTimeFormat('uk-UA', {
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                            timeZone: 'Europe/Kyiv',
                                        }).format(new Date(startDatetime))}`}
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            }
            {list.length === 0 && <button className="btn-small btn-black" onClick={goToCreateProject}>
                <img className="btn-icon-plus" src={plus} alt="" />
                <span>Створити проект</span>
            </button>}
            <button className="btn-small btn-black" onClick={goToFavorites}>
                <span>Збережене</span>
            </button>
            <NeedRegister
                isOpen={registerPopupOpen}
                onCancel={handleCancelRegister}
            />
        </div>
    )
}

export default Buttons;
