import CatalogPart from '../components/Catalog/CatalogPart';

const Categories = () => {
    return (
		<>
			<CatalogPart />
		</>
	)
}

export default Categories;
