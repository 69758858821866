import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-simple.png';
import img1 from '../../assets/images/main-block-3-1.png';
import img2 from '../../assets/images/main-block-3-2.png';
import img3 from '../../assets/images/main-block-3-3.png';

const MainBlock3 = () => {
    return (
        <div className="main-block main-block-3 container relative">
            <div className="main-block-3-info d-flex flex-column">
                <div className="main-block-3-info-title">
                    Орендарі, організатори, декоратори, флористи та всі з івент-сфери!
                </div>
                <div className="main-block-3-info-image w-100">
                    <img src={img3} className="w-100 h-100" alt="" />
                </div>
                <div className="main-block-3-info-subtitles d-flex flex-column">
                    <div className="main-block-3-info-subtitle">
                        Більше 11 років ми вчилися у наймасштабніших івент-компаній України, щоб ви могли спати спокійно!
                    </div>
                    <div className="main-block-3-info-subtitles-delimiter"></div>
                    <div className="main-block-3-info-subtitle">
                        Це ідеальне місце - <span className="text-bold">створене для вас!</span>
                        <br />
                        Де ще ви зможете забронювати потрібну вам річ в 3 кліки?
                    </div>                    
                </div>
                <div className="main-block-3-info-bottom d-flex align-items-center">
                    <Link to="/register" className="btn btn-small btn-black">
                        Зареєструватись
                    </Link>
                    <img src={logo} className="main-block-3-info-bottom-logo" />
                </div>
            </div>
            <img src={img1} className="main-block-3-image main-block-3-image-1 absolute" alt="" />
            <img src={img2} className="main-block-3-image main-block-3-image-2 absolute" alt="" />
            <img src={img3} className="main-block-3-image main-block-3-image-3 absolute" alt="" />
        </div>
    )
}

export default MainBlock3;
