import {
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  SET_PRODUCT_VIEW_SUCCESS,
  SET_PRODUCT_VIEW_FAILURE,
  CHANGE_PRODUCT_SUCCESS,
  CHANGE_PRODUCT_FAILURE,
  SAVE_IMAGE_SUCCESS,
  SAVE_IMAGE_FAILURE,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_FAILURE,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAILURE,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_POPULAR_PRODUCTS_SUCCESS,
  GET_POPULAR_PRODUCTS_FAILURE,
  GET_LAST_VIEWED_PRODUCTS_SUCCESS,
  GET_LAST_VIEWED_PRODUCTS_FAILURE,
  GET_USER_POPULAR_PRODUCTS_SUCCESS,
  GET_USER_POPULAR_PRODUCTS_FAILURE,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
} from '../actions/product';

const initialState = {
  productLoading: true,
  products: [],
  popularProducts: [],
  lastViewedProducts: [],
  userPopularProducts: [],
  product: null,
  productError: null,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        productError: null
      };
    case CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        productError: action.payload
      };
    case GET_PRODUCT_REQUEST:
      return {
        ...state,
        productLoading: true
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        productLoading: false,
        product: action.payload,
        productError: null
      };
    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        productLoading: false,
        product: null,
        productError: action.payload
      };
    case SET_PRODUCT_VIEW_SUCCESS:
      return {
        ...state,
        productError: null
      };
    case SET_PRODUCT_VIEW_FAILURE:
      return {
        ...state,
        productError: action.payload
      };
    case CHANGE_PRODUCT_SUCCESS:
      return {
        ...state,
        productError: null
      };
    case CHANGE_PRODUCT_FAILURE:
      return {
        ...state,
        productError: action.payload
      };
    case SAVE_IMAGE_SUCCESS:
      return {
        ...state,
        productError: null
      };
    case SAVE_IMAGE_FAILURE:
      return {
        ...state,
        productError: action.payload
      };
    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        productError: null
      };
    case DELETE_IMAGE_FAILURE:
      return {
        ...state,
        productError: action.payload
      };
    case ADD_COMMENT_SUCCESS:
      return {
        ...state,
        productError: null
      };
    case ADD_COMMENT_FAILURE:
      return {
        ...state,
        productError: action.payload
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        productError: null
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        products: [],
        productError: action.payload
      };
    case GET_POPULAR_PRODUCTS_SUCCESS:
      return {
        ...state,
        popularProducts: action.payload,
        productError: null
      };
    case GET_POPULAR_PRODUCTS_FAILURE:
      return {
        ...state,
        popularProducts: [],
        productError: action.payload
      };
    case GET_LAST_VIEWED_PRODUCTS_SUCCESS:
      return {
        ...state,
        lastViewedProducts: action.payload,
        productError: null
      };
    case GET_LAST_VIEWED_PRODUCTS_FAILURE:
      return {
        ...state,
        lastViewedProducts: [],
        productError: action.payload
      };
    case GET_USER_POPULAR_PRODUCTS_SUCCESS:
      return {
        ...state,
        userPopularProducts: action.payload,
        productError: null
      };
    case GET_USER_POPULAR_PRODUCTS_FAILURE:
      return {
        ...state,
        userPopularProducts: [],
        productError: action.payload
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        product: null,
        productError: null
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        productError: action.payload
      };
    default:
      return state;
  }
};

export default productReducer;
