export const parsePath = (pathname, catalogType) => {    
    const pathArray = pathname.split('/')
    const slug = pathArray[pathArray.length - 1]
    pathArray.splice(0, 3);
    const path = pathArray.join('/')
    let back = `/catalog/${catalogType}`
    if (pathArray.length > 1) back += `/${path.replace(`/${slug}`, '')}`
    return { slug, path, back }
}

export const hasChildren = (category) => {
    return category.children && category.children.length > 0
}
