import React from 'react';
import { Link } from 'react-router-dom';
import { navItems } from '../../constants/header';

const Navbar = () => {
    return (
        <div className="header-navbar d-flex justify-content-between align-items-center">
            {navItems.map((item, index) => {
                const { title, link } = item;
                return (
                  <Link to={link} className="header-navbar-item" key={index}>{title}</Link>
                )
            })}
        </div>
    )
}

export default Navbar;
