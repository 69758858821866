import React from 'react';
import './styles.scss';
import Breadcrumbs from '../Common/Breadcrumbs';
import CatalogActions from './CatalogActions';

const CatalogTop = ({ back, sort, onSortOptionSelected }) => {
    return (
        <div className="catalog-top container">
            <Breadcrumbs link={back} />
            <CatalogActions sort={sort} onSortOptionSelected={onSortOptionSelected} />
        </div>
    )
}

export default CatalogTop;
