import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLoginButton from './GoogleLoginButton';

const GoogleLoginWrap = ({ className, buttonText, onLoginSuccess, onLoginFailure }) => {
    return (
        <GoogleOAuthProvider
            clientId="400358242871-2lvs56gpiirqerk9oh4bv78ih28qjh7a.apps.googleusercontent.com"                
        >
        	<GoogleLoginButton
        		className={className}
        		buttonText={buttonText}
        		onLoginSuccess={onLoginSuccess}
        		onLoginFailure={onLoginFailure}
        	/>
        </GoogleOAuthProvider>
    )
}

export default GoogleLoginWrap;
