import CatalogMain from '../components/Catalog/CatalogMain';

const Catalog = () => {
    return (
		<>
			<CatalogMain />
		</>
	)
}

export default Catalog;
