import React from 'react';
import Popup from './Popup';

const DeleteFavorite = ({ isOpen, onConfirm, onCancel }) => {
    const popupText = <>Ви впевнені що хочете видалити цей товар<br />зі списку збережених?</>;

    return (
        <Popup
            className="delete-favorite"
            isOpen={isOpen}
            onConfirm={onConfirm}
            onCancel={onCancel}
            popupText={popupText}
            buttonClass="btn-small"
            buttonText="Так"
        />
    )
}

export default DeleteFavorite;
