import React from 'react';
import Popup from './Popup';

const ConfirmRegister = ({ isOpen, onConfirm, onCancel }) => {
    return (
        <Popup
            className="confirm-register"
            isOpen={isOpen}
            onConfirm={onConfirm}
            onCancel={onCancel}
            popupText="На ваш E-mail було надіслано повідомлення для підтвердження електроної пошти, будь ласка перевірте пошту або вкладку “Спам” та підтвердіть реєстрацію"
            buttonClass="btn-small"
            buttonText="Далі"
        />
    )
}

export default ConfirmRegister;
