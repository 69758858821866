import React, { useState } from 'react';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { mainCategories } from '../../constants/catalog';
import { getCatalog, getCategory } from '../../actions/catalog';

import CatalogFilters from './CatalogFilters';
import popupClose from '../../assets/icons/popup-close.svg';
import arrow from '../../assets/icons/arrow-left.svg';
import arrowLeft from '../../assets/icons/arrow-white-left.svg';
import arrowRight from '../../assets/icons/arrow-white-right.svg';

const CategorySelection = ({ isOpen, onClose, onSelected }) => {
    const dispatch = useDispatch();

	const [step, setStep] = useState(0);
	const [catalogType, setCatalogType] = useState(null);
	const [catalog, setCatalog] = useState(null);
	const [topCategory, setTopCategory] = useState(null);
	const [filter, setFilter] = useState(false);

	const closeSelection = () => {
		onClose();
	}

	const goBack = () => {
		if (step === 1) setStep(0);
		else if (step === 0) closeSelection();
	}

	const selectCatalogType = async (link) => {
		setCatalogType(link);
		const catalog = await dispatch(getCatalog(link));
		setCatalog(catalog);
		setStep(1);
	}

	const selectCategory = async (link) => {
		const topCategory = await dispatch(getCategory(link));
		setTopCategory(topCategory);
		setFilter(true);
	}

	const onCategorySelected = async (path) => {
		const category = await dispatch(getCategory(path));
		const categories = getCategories(category);
		const result = [...[topCategory], ...categories.reverse()];
		onSelected(result);
		setFilter(false);
		closeSelection();
	}

	const getCategories = (category, categories = []) => {
		const { id, title, parents } = category;
		categories.push({ id, title });
		if (parents && parents.length) {
			for (const parent of parents) {
				return getCategories(parent, categories);
			}
		}
		return categories;
	}

    if (!isOpen) {
        return null;
    }

	return (
		<div className="category-selection-shadow d-flex justify-content-center align-items-center">
			<div className="category-selection d-flex flex-column relative">
				<img className="category-selection-close absolute" src={popupClose} onClick={closeSelection} alt="" />
		        <div className="category-selection-back d-flex align-items-center" onClick={goBack}>
		            <img className="category-selection-back-arrow" src={arrow} alt="" />
		            <span className="category-selection-back-link">Повернутись назад</span>
		        </div>
		        <div className="category-selection-title text-center">Оберіть категорію</div>

                {step === 0 && <div className="catalog-main-categories d-flex justify-content-between">
                    {mainCategories.map((item, index) => {
                        const { title, bg, link } = item;
                        const categoryStyle = { background: `url(${bg})` };
                        return (
                            <div style={categoryStyle} className="catalog-main-category" key={index}>
                                <div className="catalog-main-category-wrap h-100 d-flex flex-column justify-content-end align-items-center">
                                    <div className="catalog-main-category-title">{title}</div>
                                    {index === 0 && <Link className="btn btn-medium btn-black" onClick={() => selectCatalogType(link)}>
                                            <img className="btn-icon-arrow" src={arrowLeft} alt="" />
                                            <span>Обрати категорію</span>
                                        </Link>
                                    }
                                    {index === 1 && <Link className="btn btn-medium btn-black" onClick={() => selectCatalogType(link)}>
                                            <span>Обрати категорію</span>
                                            <img className="btn-icon-arrow" src={arrowRight} alt="" />
                                        </Link>
                                    }
                                </div>
                            </div>
                        )
                    })}                    
                </div>}
                {step === 1 && catalog && <>
	                {catalog.categories && <div className={`category-selection-categories category-selection-categories-${catalogType} w-100 d-flex flex-wrap`}>
	                    {catalog.categories.map((category, index) => {
	                        const { title, link, bg, cols, mcols } = category;
	                        const image = require(`../../assets/images/${bg}`);
	                        const catStyle = { background: `url(${image})`, backgroundSize: 'cover' };
	                        return (
	                            <Link style={catStyle} className={`category-selection-category category-selection-category-cols-${cols} category-selection-category-mcols-${mcols} d-flex justify-content-center align-items-center`} key={index} onClick={() => selectCategory(link)}>
	                                {title}
	                            </Link>
	                        )
	                    })}                    
	                </div>}
                </>}
                {step === 1 && catalog && <>
                	{filter && <div className="category-selection-filter-shadow d-flex justify-content-center align-items-center">
                		<div className="category-selection-filter relative">
							<img className="category-selection-filter-close absolute" src={popupClose} onClick={() => setFilter(false)} alt="" />
                			<CatalogFilters category={topCategory} path={topCategory.slug} onSelected={onCategorySelected} />
                		</div>
                	</div>}
                </>}
			</div>
		</div>
	)
}

export default CategorySelection;
