import * as yup from 'yup';
import * as C from '../constants/auth';

const minPasswordLength = 6;
const maxPasswordLength = 32;

const registerSchema = yup.object().shape({
  firstname: yup.string()
    .required(C.ERROR_FIELD_REQUIRED.replace(':field', C.FIRSTNAME)),
  lastname: yup.string()
    .required(C.ERROR_FIELD_REQUIRED.replace(':field', C.LASTNAME)),
  phone: yup.string()
    .test('phone', C.ERROR_FIELD_INCORRECT.replace(':field', C.PHONE), value => {
      if (value) {
        const unfilledChars = value.split('_').length - 1;
        return unfilledChars === 0;
      }
      return false;
    })
    .required(C.ERROR_FIELD_REQUIRED.replace(':field', C.PHONE)),
  email: yup.string()
    .email(C.ERROR_FIELD_INCORRECT.replace(':field', C.EMAIL))
    .required(C.ERROR_FIELD_REQUIRED.replace(':field', C.EMAIL)),
  password: yup.string()
    .min(minPasswordLength, C.ERROR_MIN_PASSWORD_LENGTH.replace(':min', minPasswordLength))
    .max(maxPasswordLength, C.ERROR_MIN_PASSWORD_LENGTH.replace(':max', maxPasswordLength))
    .required(C.ERROR_FIELD_REQUIRED.replace(':field', C.PASSWORD)),
  password_confirmation: yup.string()
    .oneOf([yup.ref('password'), null], C.ERROR_PASSWORD_COMPARE),
  accept: yup.boolean()
    .oneOf([true], C.ERROR_ACCEPT),
});

export default registerSchema;
