import './styles.scss'

const Contacts = () => {
	return (
		<div className="custom-page container">
	    <h1>ФОП Пліш Василь Васильович</h1>
	    <p><strong>Повне фірмове найменування:</strong> Фізична особа-підприємець Пліш Василь Васильович</p>
	    <p><strong>Країна:</strong> Україна</p>
	    <p><strong>Адреса розташування та для кореспонденції:</strong> Львівська область, Львівський район, село Старий Яричів, вул. Заводська, 8Є</p>
	    <p><strong>Технічна підтримка:</strong></p>
	    <p>Email: <a href="mailto:delinkevich123@gmail.com">delinkevich123@gmail.com</a></p>
	    <p><strong>Телефон:</strong> <a href="tel:+380938198554">+380 93 819 85 54</a></p>
		</div>
	)
	
}

export default Contacts
