import React from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from './Popup';

const NeedRegister = ({ isOpen, onCancel }) => {
    const navigate = useNavigate();

    const popupText = <>Перш ніж користуватися цією кнопкою, необхідно<br />зареструватися</>;

    const onConfirm = () => {
    	navigate('/register');
    	onCancel();
    }

    return (
        <Popup
            className="need-register"
            isOpen={isOpen}
            onConfirm={onConfirm}
            onCancel={onCancel}
            popupText={popupText}
            buttonClass="btn-medium"
            buttonText="Перейти до реєстрації"
        />
    )
}

export default NeedRegister;
