import {
  GET_GEO_REQUEST,
  GET_GEO_SUCCESS,
  GET_GEO_FAILURE,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_FAILURE,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
} from '../actions/chat';

const initialState = {
  geoLoading: true,
  countries: [],
  regions: [],
  messagesLoading: true,
  messages: [],
  error: null,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GEO_REQUEST:
      return {
        ...state,
        geoLoading: true
      };
    case GET_GEO_SUCCESS:
      return {
        ...state,
        geoLoading: false,
        countries: action.payload.countries,
        regions: action.payload.regions,
        error: null
      };
    case GET_GEO_FAILURE:
      return {
        ...state,
        geoLoading: false,
        countries: [],
        regions: [],
        error: action.payload
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        geoLoading: false,
        countries: action.payload,
        error: null
      };
    case GET_COUNTRIES_FAILURE:
      return {
        ...state,
        geoLoading: false,
        countries: [],
        error: action.payload
      };
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        geoLoading: false,
        regions: action.payload,
        error: null
      };
    case GET_REGIONS_FAILURE:
      return {
        ...state,
        geoLoading: false,
        regions: [],
        error: action.payload
      };
    case GET_MESSAGES_REQUEST:
      return {
        ...state,
        messagesLoading: true
      };
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messagesLoading: false,
        messages: action.payload,
        error: null
      };
    case GET_MESSAGES_FAILURE:
      return {
        ...state,
        messagesLoading: false,
        messages: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default chatReducer;
