import ChatService from '../services/chat.service';

export const GET_GEO_REQUEST = 'GET_GEO_REQUEST';
export const GET_GEO_SUCCESS = 'GET_GEO_SUCCESS';
export const GET_GEO_FAILURE = 'GET_GEO_FAILURE';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_FAILURE = 'GET_REGIONS_FAILURE';
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';
export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE';

export const getGeoRequest = () => ({ type: GET_GEO_REQUEST });
export const getGeoSuccess = (data) => ({ type: GET_GEO_SUCCESS, payload: data });
export const getGeoFailure = (error) => ({ type: GET_GEO_FAILURE, payload: error });
export const getCountriesSuccess = (data) => ({ type: GET_COUNTRIES_SUCCESS, payload: data });
export const getCountriesFailure = (error) => ({ type: GET_COUNTRIES_FAILURE, payload: error });
export const getRegionsSuccess = (data) => ({ type: GET_REGIONS_SUCCESS, payload: data });
export const getRegionsFailure = (error) => ({ type: GET_REGIONS_FAILURE, payload: error });
export const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
export const sendMessageSuccess = (data) => ({ type: SEND_MESSAGE_SUCCESS, payload: data });
export const sendMessageFailure = (error) => ({ type: SEND_MESSAGE_FAILURE, payload: error });
export const getMessagesRequest = () => ({ type: GET_MESSAGES_REQUEST });
export const getMessagesSuccess = (data) => ({ type: GET_MESSAGES_SUCCESS, payload: data });
export const getMessagesFailure = (error) => ({ type: GET_MESSAGES_FAILURE, payload: error });

export const getGeoData = () => async (dispatch) => {
  try {
    dispatch(getGeoRequest());
    const result = await ChatService.getGeoData();
    console.log(result)
    if (result.success) {
      dispatch(getGeoSuccess(result.countries));
    }
    else {
      dispatch(getGeoFailure(result.message));
    }
  } catch (e) {
    dispatch(getGeoFailure(e.toString()));
  }
}

export const getCountries = () => async (dispatch) => {
  try {
    const result = await ChatService.getCountries();
    console.log(result)
    if (result.success) {
      dispatch(getCountriesSuccess(result.countries));
    }
    else {
      dispatch(getCountriesFailure(result.message));
    }
  } catch (e) {
    dispatch(getCountriesFailure(e.toString()));
  }
}

export const getRegions = (country) => async (dispatch) => {
  try {
    const result = await ChatService.getRegions(country);
    console.log(result)
    if (result.success) {
      dispatch(getRegionsSuccess(result.regions));
    }
    else {
      dispatch(getRegionsFailure(result.message));
    }
  } catch (e) {
    dispatch(getRegionsFailure(e.toString()));
  }
}

export const getMessages = (room) => async (dispatch) => {
  try {
    dispatch(getMessagesRequest());
    const token = localStorage.getItem('token');
    const result = await ChatService.getMessages(room, token);
    console.log(result)
    if (result.success) {
      dispatch(getMessagesSuccess(result.messages));
    }
    else {
      dispatch(getMessagesFailure(result.message));
    }
  } catch (e) {
    dispatch(getMessagesFailure(e.toString()));
  }  
}
