import {
  GET_PLANS_REQUEST,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAILURE,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILURE,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAILURE,
} from '../actions/subscription';

const initialState = {
  loading: false,
  plans: [],
  subscriptionsLoading: true,
  subscriptions: [],
  error: null,
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_PLANS_SUCCESS:
      return { ...state, loading: false, plans: action.payload, error: null };
    case GET_PLANS_FAILURE:
      return { ...state, loading: false, plans: [], error: action.payload };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return { ...state, error: null };
    case CREATE_SUBSCRIPTION_FAILURE:
      return { ...state, error: action.payload };
    case GET_SUBSCRIPTIONS_REQUEST:
      return { ...state, subscriptionsLoading: true, error: null };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return { ...state, subscriptionsLoading: false, subscriptions: action.payload, error: null };
    case GET_SUBSCRIPTIONS_FAILURE:
      return { ...state, subscriptionsLoading: false, subscriptions: [], error: action.payload };
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return { ...state, error: null };
    case UPDATE_SUBSCRIPTION_FAILURE:
      return { ...state, error: action.payload };
    case DELETE_SUBSCRIPTION_SUCCESS:
      return { ...state, error: null };
    case DELETE_SUBSCRIPTION_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default subscriptionReducer;
