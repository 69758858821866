import React, { useState, useEffect } from 'react';

const Counter = ({ className, caption = false }) => {
    const initialTime = {
        days: 0,
        hours: 11,
        minutes: 23,
        seconds: 17
    };

    const [time, setTime] = useState(initialTime);
    const [isCounting, setIsCounting] = useState(true);

    useEffect(() => {
        const counterTime = localStorage.getItem('counter_time');
        if (counterTime) {
            const parsedTime = JSON.parse(counterTime);
            if (parsedTime.days === 0 && parsedTime.hours === 0 && parsedTime.minutes === 0 && parsedTime.seconds === 0) {
                localStorage.setItem('counter_time', JSON.stringify(initialTime));
                setTime(initialTime);
            } else {
                setTime(parsedTime);
            }
        }
        else {
            localStorage.setItem('counter_time', JSON.stringify(initialTime));
            setTime(initialTime);
        }
    }, [])

    useEffect(() => {
        if (!isCounting) return;

        const interval = setInterval(() => {
            setTime((prevTime) => {
                let { days, hours, minutes, seconds } = prevTime;

                if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
                    setIsCounting(false);
                    return prevTime;
                }

                if (seconds > 0) {
                    seconds--;
                } else {
                    seconds = 59;
                    if (minutes > 0) {
                        minutes--;
                    } else {
                        minutes = 59;
                        if (hours > 0) {
                            hours--;
                        } else {
                            hours = 23;
                            if (days > 0) {
                                days--;
                            } else {
                                days = 0;
                            }
                        }
                    }
                }

                const newTime = { days, hours, minutes, seconds };
                localStorage.setItem('counter_time', JSON.stringify(newTime));
                return newTime;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTime = (value) => String(value).padStart(2, '0');

    return (
        <div className={`${className} d-flex justify-content-center align-items-center`}>
            <div className="d-flex flex-column align-items-center">
                <div className="counter-item d-flex justify-content-center align-items-end">
                    {time.days}
                </div>
                {caption && <div className="counter-caption text-white">днів</div>}
            </div>
            <div className="d-flex flex-column align-items-center">
                <div className="counter-item d-flex justify-content-center align-items-end">
                    {formatTime(time.hours)}
                </div>
                {caption && <div className="counter-caption text-white">годин</div>}
            </div>
            <div className="d-flex flex-column align-items-center">
                <div className="counter-item d-flex justify-content-center align-items-end">
                    {formatTime(time.minutes)}
                </div>
                {caption && <div className="counter-caption text-white">хвилин</div>}
            </div>
            <div className="d-flex flex-column align-items-center">
                <div className="counter-item d-flex justify-content-center align-items-end">
                    {formatTime(time.seconds)}
                </div>
                {caption && <div className="counter-caption text-white">секунд</div>}
            </div>
        </div>
    )
}

export default Counter;
