import api from './api';

class DialogService
{
	async getMyDialogs(token) {
		try {
			const response = await api.get(`/dialog/my`, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[DialogService][getMyDialogs][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async createDialog(payload, token) {
		try {
			const response = await api.post('/dialog/create', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[DialogService][createDialog][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getDialog(id) {
		try {
			const response = await api.get(`/dialog/get/${id}`);
			return response.data;
		} catch (e) {
			console.log('[DialogService][getDialog][error]', e);
			return { success: false, message: e.toString() };
		}
	}

	async getMessages(room, token) {
		try {
			const response = await api.get(`/dialog/messages/get/${room}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        });
			return response.data;
		} catch (e) {
			console.log('[DialogService][getMessages][error]', e);
			return { success: false, message: e.toString() };
		}
	}
}

export default new DialogService();
