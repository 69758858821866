import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GOOGLE_AUTH_FAILURE,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_STORE_SUCCESS,
  GET_STORE_FAILURE,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  CREATE_FAVORITES_LIST_SUCCESS,
  CREATE_FAVORITES_LIST_FAILURE,
  GET_FAVORITES_LISTS_SUCCESS,
  GET_FAVORITES_LISTS_FAILURE,
  DELETE_FAVORITES_LIST_SUCCESS,
  DELETE_FAVORITES_LIST_FAILURE,
  ADD_FAVORITE_SUCCESS,
  ADD_FAVORITE_FAILURE,
  GET_FAVORITES_SUCCESS,
  GET_FAVORITES_FAILURE,
  DELETE_FAVORITE_SUCCESS,
  DELETE_FAVORITE_FAILURE,
  LOGOUT
} from '../actions/auth';

const initialState = {
  userLoading: true,
  isAuthenticated: false,
  user: null,
  store: null,
  favoritesLists: [],
  favorites: [],
  token: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        // isAuthenticated: true,
        // user: action.payload.user,
        // token: action.payload.token,
        error: null
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        // isAuthenticated: false,
        // user: null,
        // token: null,
        error: action.payload
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        error: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        error: action.payload
      };
    case GOOGLE_AUTH_FAILURE:
      return {
        ...state,
        // isAuthenticated: false,
        // user: null,
        // token: null,
        error: action.payload
      };
    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        error: null
      };
    case VERIFY_USER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case GET_USER_REQUEST:
      return {
        ...state,
        userLoading: true
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        isAuthenticated: true,
        user: action.payload,
        error: null
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        userLoading: false,
        isAuthenticated: false,
        user: null,
        // error: action.payload
      };
    case GET_STORE_SUCCESS:
      return {
        ...state,
        store: action.payload,
        error: null
      };
    case GET_STORE_FAILURE:
      return {
        ...state,
        store: null,
        error: action.payload
      };
    case CREATE_FAVORITES_LIST_SUCCESS:
      return {
        ...state,
        favoritesLists: action.payload,
        error: null
      };
    case CREATE_FAVORITES_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case GET_FAVORITES_LISTS_SUCCESS:
      return {
        ...state,
        favoritesLists: action.payload,
        error: null
      };
    case GET_FAVORITES_LISTS_FAILURE:
      return {
        ...state,
        favoritesLists: [],
        error: action.payload
      };
    case DELETE_FAVORITES_LIST_SUCCESS:
      return {
        ...state,
        error: null
      };
    case DELETE_FAVORITES_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case ADD_FAVORITE_SUCCESS:
      return {
        ...state,
        error: null
      };
    case ADD_FAVORITE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case GET_FAVORITES_SUCCESS:
      return {
        ...state,
        favorites: action.payload,
        error: null
      };
    case GET_FAVORITES_FAILURE:
      return {
        ...state,
        favorites: [],
        error: action.payload
      };
    case DELETE_FAVORITE_SUCCESS:
      return {
        ...state,
        error: null
      };
    case DELETE_FAVORITE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        error: null
      };
    default:
      return state;
  }
};

export default authReducer;
