import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoritesLists, deleteFavoritesList, getFavorites, searchFavorites } from '../../actions/auth';
import Breadcrumbs from '../Common/Breadcrumbs';
import CreateFavoritesList from '../Popup/CreateFavoritesList';
import DeleteFavoriteList from '../Popup/DeleteFavoriteList';
import CatalogActions from '../Catalog/CatalogActions';
import ProductsGrid from '../Product/ProductsGrid';
import plusWhite from '../../assets/icons/plus-white.svg';
import chevronRight from '../../assets/icons/chevron-right.svg';
import trash from '../../assets/icons/trash-black.svg';
import { Pagination } from '@mui/material';

const Favorites = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get('q');

    const userLoading = useSelector((state) => state.auth.userLoading);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const favoritesLists = useSelector((state) => state.auth.favoritesLists);
    const favorites = useSelector((state) => state.auth.favorites);

    const [selectedList, setSelectedList] = useState(null);
    const [sort, setSort] = useState(null);

    useEffect(() => {
        (async () => {
	    	if (isAuthenticated) {
	    		await dispatch(getFavoritesLists())
                if (searchQuery) {
                    await dispatch(searchFavorites({ query: searchQuery, sort }))
                } else {
                    await dispatch(getFavorites({ sort }))
                }
	    	} else if (!userLoading) {
	    		navigate('/');
	    	}
        })();
    }, [userLoading, isAuthenticated, searchQuery, sort]);

    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (favorites) {
            const products = favorites.map(item => item.product);
            setProducts(products);
        }
    }, [favorites]);

    const [createPopupOpen, setCreatePopupOpen] = useState(false);
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    const [deletingListId, setDeletingListId] = useState(null);

    const handleCreateClick = () => {
        setCreatePopupOpen(true);
    }

    const handleConfirmCreate = (title) => {
        setCreatePopupOpen(false);
    }

    const handleCancelCreate = () => {
        setCreatePopupOpen(false);
    }

    const handleSelectListClick = async (listId = null) => {
        setSelectedList(listId);
    	const payload = listId ? { listId, sort } : { sort }
	    await dispatch(getFavorites(payload));
    }

    const handleDeleteClick = (listId) => {
        setDeletingListId(listId);
        setDeletePopupOpen(true);
    }

    const handleConfirmDelete = async () => {
        await dispatch(deleteFavoritesList({ id: deletingListId }));
        await dispatch(getFavoritesLists());
        await dispatch(getFavorites({ sort }));
        setDeletePopupOpen(false);
    }

    const handleCancelDelete = () => {
        setDeletePopupOpen(false);
    }

    const [page, setPage] = useState(1);
    const productsPerPage = 8;
    const topRef = useRef(null);

    const handleChangePage = (event, value) => {
        setPage(value);
        scrollToTop();
    }

    const scrollToTop = () => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const onSortOptionSelected = (option) => {
        setSort(option);
    }

    return (
        <div className="favorites container">
            <Breadcrumbs />
            <div className="favorites-body d-flex flex-column" ref={topRef}>
            	<div className="favorites-top d-flex justify-content-between align-items-center">
            		<button className="favorites-create btn-small btn-black" onClick={handleCreateClick}>
                        <img className="btn-icon-plus" src={plusWhite} alt="" />
                        <span>Створити список</span>
            		</button>
                    <CatalogActions
                        className="m-0"
                        sort={true}
                        onSortOptionSelected={onSortOptionSelected}
                        searchIn={`favorites`}
                    />
            	</div>
            	<div className="favorites-content d-flex justify-content-between">
            		<div className="favorites-lists d-flex flex-column align-items-start">
                        <div
                            className={`favorites-list-all ${!selectedList && 'favorites-list-active'}`}
                            onClick={() => handleSelectListClick()}
                        >Всі збережені</div>
            			{favoritesLists.map((list) => {
            				const { id, title } = list
            				return (
            					<div
                                    className={`favorites-list ${selectedList === id && 'favorites-list-active'} w-100 d-flex justify-content-between align-items-center`}
                                    key={id}
                                >
            						<div
            							className="favorites-list-title d-flex align-items-center"
            							onClick={() => handleSelectListClick(id)}
            						>
					                    <span>{title}</span>
					                    <img src={chevronRight} alt="" />
					                </div>
            						<div className="favorites-list-remove" onClick={() => handleDeleteClick(id)}>
            							<img className="w-100" src={trash} alt="" />
            						</div>
            					</div>
            				)
            			})}
            		</div>
                    {selectedList && <div
                        className="favorites-list-delete d-flex justify-content-end"
                        onClick={() => handleDeleteClick(selectedList)}
                    >Видалити список</div>}
                    <div className="catalog-category-products d-flex flex-column">
                        <ProductsGrid
                            products={products.slice((page - 1) * productsPerPage, (page - 1) * productsPerPage + productsPerPage)}
                            place="favorites"
                        />
                        <Pagination
                            className="catalog-category-pagination d-flex justify-content-center"
                            count={Math.ceil(products.length / productsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </div>
            	</div>
            </div>
            <CreateFavoritesList
                isOpen={createPopupOpen}
                onConfirm={handleConfirmCreate}
                onCancel={handleCancelCreate}
            />
            <DeleteFavoriteList
                isOpen={deletePopupOpen}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        </div>
    )
}

export default Favorites;
