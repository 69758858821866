import {
  GET_CATALOG_REQUEST,
  GET_CATALOG_SUCCESS,
  GET_CATALOG_FAILURE,
  GET_CATEGORIES_FOR_REGISTERED_REQUEST,
  GET_CATEGORIES_FOR_REGISTERED_SUCCESS,
  GET_CATEGORIES_FOR_REGISTERED_FAILURE,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILURE,
  GET_CATALOG_TREE_REQUEST,
  GET_CATALOG_TREE_SUCCESS,
  GET_CATALOG_TREE_FAILURE
} from '../actions/catalog';

const initialState = {
  data: {},
  tree: null,
  loading: false,
  error: null,
};

const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATALOG_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_CATALOG_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_CATALOG_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_CATEGORIES_FOR_REGISTERED_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_CATEGORIES_FOR_REGISTERED_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_CATEGORIES_FOR_REGISTERED_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_CATEGORY_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_CATEGORY_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case GET_CATEGORY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_CATALOG_TREE_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_CATALOG_TREE_SUCCESS:
      return { ...state, loading: false, tree: action.payload, error: null };
    case GET_CATALOG_TREE_FAILURE:
      return { ...state, loading: false, tree: null, error: action.payload };
    default:
      return state;
  }
};

export default catalogReducer;
