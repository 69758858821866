import React from 'react';
import no from '../../assets/icons/no-outline-big.svg';

const MainBlock4 = () => {
    const problems = [
        { text: 'Створений сайт не допомагає вам отримувати бажаного результату' },
        { text: 'Заявлені робочі години не спасають вас від нічних термінових дзвінків...' },
        { text: 'Проблем настільки багато, що про масштабування бізнесу говорити дуже важко.' },
        { text: 'Списки у смартфоні, мільйони контактів, підрахунок деталей проекту, постійні дзвінки...' },
    ];

    return (
        <div className="main-block main-block-4 container d-flex justify-content-between align-items-center">
            <div className="main-block-4-left">
                <div className="main-block-4-left-wrap d-flex flex-column justify-content-between">
                    <div className="main-block-4-left-top">
                        Ми допоможемо вам працювати швидше і якісніше.
                    </div>
                    <div className="main-block-4-left-bottom d-flex flex-column">
                        <div className="main-block-4-left-bottom-border w-100"></div>
                        <div className="main-block-4-left-bottom-text">
                            Ви зможете керувати вашим бізнесом сидячи біля вікна з філіжанкою запашної кави.
                        </div>                        
                    </div>                    
                </div>
            </div>
            <div className="main-block-4-right d-flex flex-column">
                <div className="main-block-4-right-problems d-flex flex-wrap">
                    {problems.map((item, index) => {
                        const { text } = item;
                        return (
                          <div className="main-block-4-right-problem d-flex flex-column align-items-center" key={index}>
                            <img src={no} className="main-block-4-right-problem-icon" alt="" />
                            <div className="main-block-4-right-problem-text">{text}</div>
                          </div>
                        )
                    })}
                </div>
                <div className="main-block-4-right-texts d-flex flex-column">
                    <div className="main-block-4-right-texts-top text-bold">
                        Всі з цим пробують боротись.
                    </div>
                    <div className="main-block-4-right-texts-bottom">
                        Ми <span className="text-bold">допомагаємо</span> орендарям знаходити своїх клієнтів, а організаторам знаходити орендарів.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainBlock4;
