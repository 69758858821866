import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMyProjects } from '../../actions/project';
import Breadcrumbs from '../Common/Breadcrumbs';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';
import plus from '../../assets/icons/plus-white.svg';

const Projects = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userLoading = useSelector((state) => state.auth.userLoading);
    const authUser = useSelector((state) => state.auth.user);
    const myProjects = useSelector((state) => state.project.projects);

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (authUser) {
                await dispatch(getMyProjects())
            } else if (!userLoading) {
                navigate('/');
            }
        }

        fetchData();
    }, [authUser, userLoading]);

    useEffect(() => {
        if (myProjects) {
            setProjects(myProjects);
            setPage(0);
        }
    }, [myProjects]);

    const defaultLabelDisplayedRows = ({ from, to, count }) => {
        return `${from}–${to} з ${count !== -1 ? count : `більше ніж ${to}`}`;
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return (
        <div className="projects container">
            <Breadcrumbs link="/" />
            <div className="projects-body w-100 d-flex flex-column align-items-center">
                {projects.length !== 0 && <>
                    <Table className="projects-table w-100">
                        <TableHead>
                            <TableRow className="projects-table-row">
                                <TableCell className="projects-table-cell projects-table-cell-date">Дата</TableCell>
                                <TableCell className="projects-table-cell">Локація</TableCell>
                                <TableCell className="projects-table-cell">Замовник</TableCell>
                                <TableCell className="projects-table-cell projects-table-cell-price">Ціна, грн.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) => {
                                return (
                                    <TableRow className="projects-table-row" key={index} onClick={() => navigate(`/project/${project.slug}`)}>
                                        <TableCell className="projects-table-cell projects-table-cell-date">
                                            {new Intl.DateTimeFormat('uk-UA', {
                                                day: "numeric",
                                                month: "numeric",
                                                year: "numeric",
                                                timeZone: 'Europe/Kyiv',
                                            }).format(new Date(project.startDatetime))}
                                        </TableCell>
                                        <TableCell className="projects-table-cell">{project.location}</TableCell>
                                        <TableCell className="projects-table-cell">{project.client}</TableCell>
                                        <TableCell className="projects-table-cell projects-table-cell-price">{project.budget}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="Показувати на сторінці"
                        rowsPerPageOptions={[5, 10, 25, { label: 'Всі', value: -1 }]}
                        labelDisplayedRows={defaultLabelDisplayedRows}
                        component="div"
                        count={projects.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>}
            	<div className="projects-buttons w-100 d-flex justify-content-end">
            		<Link to="/project/create" className="btn btn-medium btn-black">
                    	<img className="btn-icon-plus" src={plus} alt="" />
            			<span>Створити проект</span>
            		</Link>
            	</div>
           	</div>
        </div>
    )
}

export default Projects;
