import {
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  SELECT_ORDER_INFO_SUCCESS,
  SELECT_ORDER_INFO_FAILURE,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  DESELECT_ORDER_INFO,
} from '../actions/order';

const initialState = {
  orderLoading: true,
  orders: null,
  order: null,
  selectedOrderInfo: null,
  error: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        error: null
      };
    case GET_ORDERS_FAILURE:
      return {
        ...state,
        orders: null,
        error: action.payload
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        error: null
      };
    case CREATE_ORDER_FAILURE:
      return {
        ...state,
        order: null,
        error: action.payload
      };
    case GET_ORDER_REQUEST:
      return {
        ...state,
        projectLoading: true
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        projectLoading: false,
        order: action.payload,
        error: null
      };
    case GET_ORDER_FAILURE:
      return {
        ...state,
        projectLoading: false,
        order: null,
        error: action.payload
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        error: null
      };
    case ADD_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        error: null
      };
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        error: null
      };
    case UPDATE_ORDER_FAILURE:
      return {
        ...state,
        order: null,
        error: action.payload
      };
    case SELECT_ORDER_INFO_SUCCESS:
      return {
        ...state,
        selectedOrderInfo: action.payload,
        error: null
      };
    case SELECT_ORDER_INFO_FAILURE:
      return {
        ...state,
        selectedOrderInfo: null,
        error: action.payload
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        error: null
      };
    case DELETE_ORDER_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case DESELECT_ORDER_INFO:
      return {
        ...state,
        selectedOrderInfo: null,
        error: null
      };
    default:
      return state;
  }
};

export default orderReducer;
