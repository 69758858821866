import React, { useState } from 'react';
import Counter from './Counter';
import NeedRegister from '../Popup/NeedRegister';

const TopPromotion = () => {
    const [registerPopupOpen, setRegisterPopupOpen] = useState(false);

    const openRegisterPopup = () => {
        setRegisterPopupOpen(true);
    }

    const handleCancelRegister = () => {
        setRegisterPopupOpen(false);
    }

    return (
        <div className="top-promotion w-100 absolute">
            <div className="top-promotion-wrap h-100 container d-flex justify-content-between align-items-center">
                <div className="top-promotion-text">
                    Акційні пропозиції з найменшими можливими цінами за підписку!
                </div>
                <Counter className="top-promotion-counter" />
                <button className="btn-small btn-white" onClick={openRegisterPopup}>
                    <span>Оформлення</span>
                </button>
            </div>
            <NeedRegister
                isOpen={registerPopupOpen}
                onCancel={handleCancelRegister}
            />
        </div>
    )
}

export default TopPromotion;
