import React, { useState } from 'react';
import './styles.scss';
import { hasChildren } from '../../utils/catalog';
import chevronRight from '../../assets/icons/chevron-right.svg';
import arrowRight from '../../assets/icons/arrow-right.svg';

const CatalogMenuItem = ({ category, path, level = 1, openStates, setOpenStates, onSelected, maxLevel = 3 }) => {
    const isOpen = openStates[level] === category.id;

    const handleMenuClick = (e, category) => {
        if (hasChildren(category) && level < maxLevel) {
            setOpenStates((prevOpenStates) => {
                const newOpenStates = [...prevOpenStates.slice(0, level), isOpen ? null : category.id];
                return newOpenStates;
            });
        } else {
            onSelected(path);
        }
    };

    const handleNavigateClick = () => {
        console.log('navigate path', path)
        onSelected(path);
    }

    return (
        <div className={`catalog-filters ${isOpen && 'catalog-filters-opened'} d-flex flex-wrap catalog-filters-level-${level}`}>
            <div className="d-flex align-items-center" onClick={(e) => handleMenuClick(e, category)}>
                <span>{category.title}</span>
                {(hasChildren(category) && level < maxLevel) && <>
                    <img className={`catalog-filters-chevron ${isOpen ? 'catalog-filters-chevron-opened' : ''}`} src={chevronRight} alt="" />
                    <div className="d-flex" onClick={handleNavigateClick}>
                        <img className={`catalog-filters-arrow`} src={arrowRight} alt="" />
                    </div>
                </>}
            </div>
            
            {(hasChildren(category) && level < maxLevel) && 
                <ul className={`${isOpen ? 'd-flex' : 'd-none'} flex-column`}>
                    {category.children.map((child, index) => (
                        <li key={index}>
                            <CatalogMenuItem
                                category={child}
                                path={`${path}/${child.slug}`}
                                level={level + 1}
                                openStates={openStates}
                                setOpenStates={setOpenStates}
                                onSelected={onSelected}
                                maxLevel={maxLevel}
                            />
                        </li>
                    ))}
                </ul>
            }
        </div>
    )
}

export default CatalogMenuItem;
