import {
  GET_DIALOGS_SUCCESS,
  GET_DIALOGS_FAILURE,
  CREATE_DIALOG_SUCCESS,
  CREATE_DIALOG_FAILURE,
  GET_DIALOG_REQUEST,
  GET_DIALOG_SUCCESS,
  GET_DIALOG_FAILURE,
  GET_DIALOG_MESSAGES_REQUEST,
  GET_DIALOG_MESSAGES_SUCCESS,
  GET_DIALOG_MESSAGES_FAILURE,
} from '../actions/dialog';

const initialState = {
  dialogLoading: true,
  dialogs: null,
  dialog: null,
  messagesLoading: true,
  messages: [],
  error: null,
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIALOGS_SUCCESS:
      return {
        ...state,
        dialogs: action.payload,
        error: null
      };
    case GET_DIALOGS_FAILURE:
      return {
        ...state,
        dialogs: null,
        error: action.payload
      };
    case CREATE_DIALOG_SUCCESS:
      return {
        ...state,
        dialog: action.payload,
        error: null
      };
    case CREATE_DIALOG_FAILURE:
      return {
        ...state,
        dialog: null,
        error: action.payload
      };
    case GET_DIALOG_REQUEST:
      return {
        ...state,
        dialogLoading: true
      };
    case GET_DIALOG_SUCCESS:
      return {
        ...state,
        dialogLoading: false,
        dialog: action.payload,
        error: null
      };
    case GET_DIALOG_FAILURE:
      return {
        ...state,
        dialogLoading: false,
        dialog: null,
        error: action.payload
      };
    case GET_DIALOG_MESSAGES_REQUEST:
      return {
        ...state,
        messagesLoading: true
      };
    case GET_DIALOG_MESSAGES_SUCCESS:
      return {
        ...state,
        messagesLoading: false,
        messages: action.payload,
        error: null
      };
    case GET_DIALOG_MESSAGES_FAILURE:
      return {
        ...state,
        messagesLoading: false,
        messages: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default dialogReducer;
