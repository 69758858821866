import React from 'react';
import './styles.scss';
import CatalogTop from './CatalogTop';
import { Link } from 'react-router-dom';

import { mainCategories } from '../../constants/catalog';

import arrowLeft from '../../assets/icons/arrow-white-left.svg';
import arrowRight from '../../assets/icons/arrow-white-right.svg';

const CatalogMain = () => {
    return (
        <>
            <CatalogTop back="/" sort={false} />
            <div className="catalog catalog-main container">
                <div className="catalog-main-categories d-flex justify-content-between">
                    {mainCategories.map((item, index) => {
                        const { title, bg, link } = item;
                        const categoryStyle = { background: `url(${bg})` };
                        return (
                            <div style={categoryStyle} className="catalog-main-category" key={index}>
                                <div className="catalog-main-category-wrap h-100 d-flex flex-column justify-content-end align-items-center">
                                    <div className="catalog-main-category-title">{title}</div>
                                    {index === 0 && <Link to={link} className="btn btn-medium btn-black">
                                            <img className="btn-icon-arrow" src={arrowLeft} alt="" />
                                            <span>Перейти до каталогу</span>
                                        </Link>
                                    }
                                    {index === 1 && <Link to={link} className="btn btn-medium btn-black">
                                            <span>Перейти до каталогу</span>
                                            <img className="btn-icon-arrow" src={arrowRight} alt="" />
                                        </Link>
                                    }
                                </div>
                            </div>
                        )
                    })}                    
                </div>
            </div>
        </>
    )
}

export default CatalogMain;
