import './styles.scss'

const CookiePolicy = () => {
	return (
		<div className="custom-page container">
		<h1>Політика використання файлів куки</h1>

		<h2>Загальна інформація</h2>
		<p>1.1. Ця Політика регулює використання файлів куки на веб-сайті «eventworkzone.com» для допомоги організаторів івентів, декораторів, орендарів та ін. (далі - "Сайт").</p>
		<p>1.2. Сайт дозволяє клієнтам реєструватися та розміщувати оголошення про здачу в оренду декору, резервувати та обговорювати деталі івентів та інше.</p>

		<h2>Використання файлів куки</h2>
		<p>2.1. При вході на Сервіс сервер зберігає на диску комп'ютера Користувача короткий текстовий файл, званий "cookie", який Сайт може використовувати для підвищення зручності використання Сервісу або для створення базової статистики про відвідуваність Сервісу. Файли cookie також можуть відправлятися через сервери сервісів, на які посилається Сайт (наприклад, системи CMS тощо), але вони залишаються поза сферою впливу Сайт. Файли cookie не збирають жодних особистих даних. Файли cookie, збережені на комп'ютері Користувача, нами ніде не збираються і не обробляються. Кожен Користувач може змінити налаштування файлів cookie у своєму браузері, в тому числі повністю відключити можливість їх збереження. Якщо Користувач не відключає збереження файлів cookie у своєму браузері, це означає його згоду на збереження їх на своєму комп'ютері.</p>
		<p>2.2. Файли куки - це невеликі текстові файли, які зберігають інформацію на пристрої користувача для поліпшення роботи веб-сайту.</p>
		<p>2.3. Сайт використовує файли куки для забезпечення зручного користування та аналізу відвідувань.</p>

		<h2>Типи файлів куки, які ми використовуємо</h2>
		<p>3.1. Необхідні файли куки: Забезпечують основні функції сайту, такі як автентифікація та доступ до основних функцій.</p>
		<p>3.2. Файли куки для функціональності: Допомагають покращити функціональність сайту, запам'ятовуючи обрані користувачем налаштування.</p>
		<p>3.3. Аналітичні файли куки: Надають інформацію про взаємодію користувачів з сайтом для вдосконалення його ефективності.</p>

		<h2>Керування файлами куки</h2>
		<p>4.1. Користувачі можуть налаштовувати свої браузери для прийняття або відхилення файлів куки. Проте, відмова від файлів куки може обмежити доступ до деяких функцій сайту.</p>

		<h2>Оплата та файли куки</h2>
		<p>5.1. Єдина оплата на сайті - це плата за користування, вона не пов'язана зі здачею в оренду речей, доставки чи інших суміжних платежів і не впливає на оплату користувачами.</p>

		<h2>Зміни у Політиці файлів куки</h2>
		<p>6.1. Сайт може періодично оновлювати цю Політику. Користувачі повинні переглядати її для ознайомлення з останніми змінами.</p>
		<p>Ця Політика є частиною Умов користування Сайтом.</p>
		</div>
	)
}

export default CookiePolicy
