import DialogService from '../services/dialog.service';

export const GET_DIALOGS_SUCCESS = 'GET_DIALOGS_SUCCESS';
export const GET_DIALOGS_FAILURE = 'GET_DIALOGS_FAILURE';
export const CREATE_DIALOG_SUCCESS = 'CREATE_DIALOG_SUCCESS';
export const CREATE_DIALOG_FAILURE = 'CREATE_DIALOG_FAILURE';
export const GET_DIALOG_REQUEST = 'GET_DIALOG_REQUEST';
export const GET_DIALOG_SUCCESS = 'GET_DIALOG_SUCCESS';
export const GET_DIALOG_FAILURE = 'GET_DIALOG_FAILURE';
export const GET_DIALOG_MESSAGES_REQUEST = 'GET_DIALOG_MESSAGES_REQUEST';
export const GET_DIALOG_MESSAGES_SUCCESS = 'GET_DIALOG_MESSAGES_SUCCESS';
export const GET_DIALOG_MESSAGES_FAILURE = 'GET_DIALOG_MESSAGES_FAILURE';

export const getDialogsSuccess = (data) => ({ type: GET_DIALOGS_SUCCESS, payload: data });
export const getDialogsFailure = (error) => ({ type: GET_DIALOGS_FAILURE, payload: error });
export const createDialogSuccess = () => ({ type: CREATE_DIALOG_SUCCESS });
export const createDialogFailure = (error) => ({ type: CREATE_DIALOG_FAILURE, payload: error });
export const getDialogRequest = () => ({ type: GET_DIALOG_REQUEST });
export const getDialogSuccess = (data) => ({ type: GET_DIALOG_SUCCESS, payload: data });
export const getDialogFailure = (error) => ({ type: GET_DIALOG_FAILURE, payload: error });
export const geDialogMessagesRequest = () => ({ type: GET_DIALOG_MESSAGES_REQUEST });
export const getDialogMessagesSuccess = (data) => ({ type: GET_DIALOG_MESSAGES_SUCCESS, payload: data });
export const getDialogMessagesFailure = (error) => ({ type: GET_DIALOG_MESSAGES_FAILURE, payload: error });

export const getMyDialogs = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await DialogService.getMyDialogs(token);
    console.log(result)
    if (result.success) {
      dispatch(getDialogsSuccess(result.dialogs));
      return result.projects;
    }
    else {
      dispatch(getDialogsFailure(result.message));
      return false;
    }
  } catch (e) {
    dispatch(getDialogsFailure(e.toString()));
    return false;
  }
}

export const createDialog = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token');
    const result = await DialogService.createDialog(payload, token);
    console.log(result)
    if (result.success) {
      dispatch(createDialogSuccess());
      return result.dialog;
    }
    else {
      dispatch(createDialogFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(createDialogFailure(e.toString()));
    return null;
  }
}

export const getDialog = (id) => async (dispatch) => {
  try {
    dispatch(getDialogRequest());
    const result = await DialogService.getDialog(id);
    console.log(result)
    if (result.success) {
      dispatch(getDialogSuccess(result.dialog));
      return result.dialog;
    }
    else {
      dispatch(getDialogFailure(result.message));
      return null;
    }
  } catch (e) {
    dispatch(getDialogFailure(e.toString()));
    return null;
  }
}

export const getDialogMessages = (room) => async (dispatch) => {
  try {
    dispatch(geDialogMessagesRequest());
    const token = localStorage.getItem('token');
    const result = await DialogService.getMessages(room, token);
    console.log(result)
    if (result.success) {
      dispatch(getDialogMessagesSuccess(result.messages));
    }
    else {
      dispatch(getDialogMessagesFailure(result.message));
    }
  } catch (e) {
    dispatch(getDialogMessagesFailure(e.toString()));
  }  
}
